<template>
    <div class="checkbox-list">
        <div class="checkbox-container" v-bind:class="{ 'scrollable': !showAll && props.items.length > 8 }">
            <div v-for="(item, index) in displayedItems" :key="index" class="checkbox-item">
                <Checkbox :id="generateId(index)" :title="item" v-model="checkedItems[index]" />
            </div>
        </div>
        <div @click="toggleShowAll" v-if="props.items.length > 4"
            class="text-[14px] mt-[16px] !text-[#2894FF] font-[Inter] font-semi-bold underline cursor-pointer">
            {{ showAll ? 'Скрыть' : 'Еще' }}
        </div>
    </div>
</template>

<script setup>
import Checkbox from './Checkbox.vue';
import { ref, reactive, watch, computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
    items: {
        type: Array,
        required: true
    },
    modelValue: {
        type: Array,
        default: () => [],
    },
});

const showAll = ref(false);
const checkedItems = ref([...props.modelValue]);

const displayedItems = computed(() => {
    if (showAll.value === false) {
        return props.items.slice(0, 4);
    } else {
        return props.items;
    }
});

const emit = defineEmits(['update:modelValue']);

watch(checkedItems, (newValue) => {
    emit('update:modelValue', newValue);
}, { deep: true });

const toggleShowAll = () => {
    showAll.value = !showAll.value;
}

const generateId = (index) => {
    return `checkbox-${index}`;
}
</script>

<style scoped>
.checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.checkbox-container {
    max-height: 200px; /* Ограничение высоты контейнера */
    overflow-y: auto; /* Добавление прокрутки по вертикали */
}

.scrollable {
    max-height: 120px; /* Высота для прокрутки, если items больше 8 и showAll = false */
}

.checkbox-item {
    /* Стили для элементов чекбокса, если необходимо */
}

/* Добавьте стили для вашего toggle-button и других элементов, если это необходимо */
</style>
