<template>
  <Header @toggleScroll="handleToggleScroll"></Header>
  <div id="page" class="flex flex-col h-full overflow-auto">
    <div class="flex-grow mt-[80px]">
      <slot name="default">
<!--        Содержимое странички (дефаулт)-->
      </slot>
    </div>

    <Footer class="box-border"></Footer>
  </div>

</template>

<script setup>
import {ref} from "vue";
import Header from "@/components/ui/Header.vue";
import Footer from "@/components/ui/Footer.vue";

const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

</script>

<style >
html, body, #app {
  height: 100vh;
  overflow: auto;
  /*overflow: hidden;*/
}

</style>