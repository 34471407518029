<template>
        <Popup :title="title" @closePopup="closePopup" @resetFilters="resetFilters">
                <div class="flex flex-col">
                        <div class="text-[0.87rem] font-[Inter] font-semibold mb-[1.12rem]">Регион</div>
                        <div class="mb-[0.87rem]">
                                <CheckboxesList :items=cities v-model="citiesModel">
                                </CheckboxesList>
                        </div>

                        <div class="text-[0.87rem] font-[Inter] font-semibold mb-[1.12rem]">Тип занятости</div>
                        <div class="mb-[0.87rem]">
                                <CheckboxesList :items=jobTypes v-model="jobTypesModel">
                                </CheckboxesList>
                        </div>

                        <div class="text-[0.87rem] font-[Inter] font-semibold mb-[1.12rem]">График работы</div>
                        <div class="mb-[0.87rem]">
                                <CheckboxesList :items=jobShedules v-model="jobShedulesModel">
                                </CheckboxesList>
                        </div>

                        <div class="text-[0.87rem] font-[Inter] font-semibold mb-[1.12rem]">Опыт работы</div>
                        <div class="mb-[0.87rem]">
                                <CheckboxesList :items=jobExperiences v-model="jobExperiencesModel">
                                </CheckboxesList>
                        </div>



                </div>
        </Popup>
</template>

<script setup>
import Checkbox from '../ui/Checkbox.vue';
import CheckboxesList from './../../components/ui/CheckboxesList.vue';
import Popup from './../../components/ui/Popup.vue';
import { defineProps, defineEmits, computed, ref, watch, onMounted, onBeforeMount } from 'vue';
import { useFetch } from '@/composables/useFetch';

const { anonRequest, authRequest } = useFetch();

const props = defineProps({
        title: {
                type: String,
                default: 'Фильтры',
        },
        modelValue: {
                type: Object,
                default: () => { },
        },
});



const jobTypes = ref(['Полная занятость', 'Частичная занятость', 'Проектная работа', 'Стажировка'])
const jobShedules = ref(['Полный день', 'Сменный график', 'Удаленная работа', 'Гибкий график'])
const jobExperiences = ref(['Нет опыта', 'От 1 года до 3 лет', 'От 3 до 6 лет', 'Более 6 лет'])
const jobTypesModel = ref([])
const citiesModel = ref([])
const jobShedulesModel = ref([])
const jobExperiencesModel = ref([])
const filtersModel = ref({
        'jobTypes': [],
        'cities': [],
        'jobShedules': [],
        'jobExperiences': [],
})

onBeforeMount(() => {
        fetchCitiesData();
        // jobShedulesModel.value = props.modelValue.jobShedules
        // filtersModel.value.jobTypes = [true]
        jobTypesModel.value = props.modelValue.jobTypes
        citiesModel.value = props.modelValue.cities
        jobShedulesModel.value = props.modelValue.jobShedules
        jobExperiencesModel.value = props.modelValue.jobExperiences
});

onMounted(() => {
        fetchCitiesData();


        // filtersModel.value.jobTypes = [true]
        // jobTypesModel.value = [true]
});

const cities = ref([])
const loading = ref(false);
const error = ref(null);

const fetchCitiesData = async () => {
        console.log('Fetching cities data...');
        loading.value = true; // Устанавливаем статус загрузки
        error.value = null; // Сбрасываем предыдущую ошибку

        try {
                const url = `/regions/`;

                const response = await anonRequest(url, {
                        method: 'GET',
                });

                // Если запрос успешен, сохраняем данные
                if (response && !response.error) {
                        cities.value = response.results; // Предполагаем, что API возвращает массив данных
                } else {
                        // Если есть ошибка, устанавливаем ошибку
                        error.value = response;
                }
        } catch (err) {
                console.error('Запрос завершился ошибкой:', err);
                error.value = err; // Устанавливаем ошибку
        } finally {
                loading.value = false; // Сбрасываем статус загрузки
        }
};

const emit = defineEmits(['update:modelValue', 'closePopupFilterTest', 'resetPopupFilterTest']);

watch(filtersModel, (newValue) => {
        emit('update:modelValue', newValue);
}, { deep: true })

watch(jobTypesModel, (newValue) => {
        filtersModel.value.jobTypes = newValue
}, { deep: true })

watch(citiesModel, (newValue) => {
        let resultArray = []
        if (newValue.length > 0) {
                const maxIndex = Math.max(...Object.keys(newValue)); // вернет 6
                resultArray = new Array(maxIndex + 1).fill(false);

                for (let key in newValue) {
                if (newValue[key]) {
                        resultArray[key] = cities.value[key];
                }
        }
        }


        
        
        // Создаем массив с длиной maxIndex + 1, заполненный значениями false
        

        // Превращаем объект в массив, заменяя значения на true там, где это указано в объекте
        
        // let citiesArray = []
        // console.log('newal!!!!!!!!!!!!!!', newValue)
        // newValue.forEach((item, index) => { 
        //         citiesArray.push(cities.value[index])
        // })
        filtersModel.value.cities = resultArray
        // console.log('afafafasfafaaffafafafaaf', newValue)
}, { deep: true })

watch(jobShedulesModel, (newValue) => {
        filtersModel.value.jobShedules = newValue
        // console.log('upddate jobShedulesModel')
}, { deep: true })

watch(jobExperiencesModel, (newValue) => {
        filtersModel.value.jobExperiences = newValue
        // console.log('upddate jobExperiencesModel')
}, { deep: true })

const additionalRegionsVisible = ref(false)
const togleRegion = () => {
        additionalRegionsVisible.value = !additionalRegionsVisible.value
};


// Передача события для закрытия всплывающего окна
const closePopup = () => {
        emit('closePopupFilterTest');
};
const resetFilters = () => {
        emit('resetPopupFilterTest');
};
</script>

<style scoped></style>