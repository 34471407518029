<template>
  <div class="flex justify-between mb-[0.37rem] h-[2.5rem] md:mb-0 md:h-[54px]" @keyup.enter="performSearch">
    <Input
      type="type3-findsmall"
      :placeholder="props.placeholder"
      v-model="inputValue"
      class="h-[3.31rem] md:h-[53px]"
    ></Input>
    <router-link :to="props.to" class="ml-[0.51rem]">
      <Button
        type="search-short"
        class="md:w-[230px] h-[3.31rem] md:h-[53px]"
        @click.prevent="performSearch"
      >
        Найти
      </Button>
    </router-link>
  </div>
</template>

<script setup>
import Sheet from './Sheet.vue';
import Button from './../../components/ui/Button.vue';
import Input from './../../components/ui/Input.vue';
import { defineProps, ref, watch, toRefs, defineEmits } from 'vue';

const props = defineProps({
  placeholder: {
    type: String,
    default: 'Текст',
  },
  to: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:modelValue', 'search']);
const inputValue = ref(props.modelValue);

watch(inputValue, (newValue) => {
  emit('update:modelValue', newValue);
});

const performSearch = () => {
  emit('search');
};
</script>

<style scoped>
/* Дополнительные стили, если нужны */
</style>
