<template>
    <Sheet class="pt-[1.75rem] pl-[2rem] pr-[2rem] pb-[1.75rem] md:pt-[28px] md:pl-[17px] md:pb-[28px] md:pr-[17px]  flex flex-col items-center">
        <div class="font-[Inter] font-medium text-[0.87rem] mb-[1.5rem] md:text-[14px] md:mb-[25px]">
            Вход в личный кабинет
        </div>
        <Input type="type4-login" placeholder="Электронная почта" v-model="login" class="mb-[0.5rem] md:mb-[0px]"></Input>
        <Input type="type4-login-pass" placeholder="Пароль" v-model="password" class="mb-[5px]"></Input>
        <div :class=allertClass>Неправильные данные для входа.</div>
        <Button @click="toggleAllert" type="login-logincard" class="mb-[0.71rem] md:mb-[10px] mt-[1rem] md:mt-[13px]">Войти</Button>    
        <div class="flex flex-row justify-between w-full leading-[16px]">
<!--            <Checkbox title="Запомнить" color="D9D9D9" labelClass="ml-[0.5rem] md:ml-[7px]"></Checkbox>-->
          <Checkbox  title="Запомнить" labelClass="ml-[0.5rem] md:ml-[7px]" color="D9D9D9"></Checkbox>
<!--            <router-link to="/recover" class="font-[Inter] font-light text-[0.85rem] md:text-[12px] text-[#2894FF] cursor-pointer">Восстановить пароль</router-link>-->
        </div>   
    </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Checkbox from './../../components/ui/Checkbox.vue';
import Button from './../../components/ui/Button.vue';
import Input from './../../components/ui/Input.vue';
import { defineProps, defineEmits, computed, ref } from 'vue';
import { auth } from '@/composables/auth';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { useFetch } from "@/composables/useFetch";
import {useAuthStore} from "@/stores/authStore";
const fetch = useFetch()
const authStore = useAuthStore()

const route = useRoute();
const router = useRouter();

const showAllert = ref(false)

const login = ref(null)
const password = ref(null)

const toggleAllert = async () => {
    
    console.log('pass', password.value)
    const result = await auth(login.value, password.value)
    console.log('request', result.response)
    if (result.response.status === 401) {
      showAllert.value = true
    } else {
      localStorage.setItem("store.auth.token", result.response.access);
      authStore.getMe(true).then(() => {
        router.push("/");
      })
    }
};

const allertClass = computed(() => {
  if (showAllert.value === true) {
    return 'font-[Inter] text-[0.73rem] md:text-[10px] text-[#FF0000] w-full text-left mt-[0.3rem] md:mt-[4px]'
  } else {
    return 'font-[Inter] text-[0.73rem] md:text-[10px] text-[#FFFFFF] w-full text-left mt-[0.3rem] md:mt-[4px]'
  }
})

</script>

<style >
.gray-checkbox {

}
</style>