import { ofetch } from "ofetch";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { ref } from "vue";

export const useFetch = () => {
  const route = useRoute();
  const router = useRouter();
  // const baseURL = "http://localhost:8000/api"; // Убедитесь, что указаны правильные адреса
  // const baseURL = "http://80.90.188.22:8003/api"; // Убедитесь, что указаны правильные адреса
  const domain = window.location.hostname;
  const baseURLs = {
    'waterjob.ru': "https://waterjob.ru/api",
    'waterjobs.katskov.tech': "https://waterjobs.katskov.tech/api",
    // 'localhost:8080': "http://localhost:8000/api",
    'localhost:8080': "http://80.90.188.22:8003/api",
    'localhost': "http://80.90.188.22:8003/api",
  }
  console.log(domain)
  // const baseURL = "http://waterjobs.katskov.tech/api"; // Убедитесь, что указаны правильные адреса
  const baseURL = baseURLs[domain]; // Убедитесь, что указаны правильные адреса

  async function __request(url, options, auth) {
    console.log('___REQUEST!!!', auth);
    const headers = {
      ...options?.headers,
    };
    if (auth === true) {
      const token = localStorage.getItem("store.auth.token");
      if (token === null) {
        router.push("/login");
      }
      headers.Authorization = `Bearer ${token}`;
    }


    return await ofetch(url, { baseURL, ...options, headers })
      .catch((err) => {
        const statusCode = err?.response?.status;
        console.log(statusCode);

        // Если код состояния 401 или 403, редиректим на страницу логина
        if (statusCode === 403 || statusCode === 401) {
          router.push("/login");
        }

        // Возвращаем ошибку
        return err;
      });
  }

  const authRequest = async (url, options) => {
    return await __request(url, options, true);
  };

  const anonRequest = async (url, options) => {
    return await __request(url, options, false);
  };

  return {
    authRequest,
    anonRequest,
    baseURL,
  };
};