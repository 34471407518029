<template>
  <div class="flex flex-row">
    <Header></Header>
    <TestPopup v-if="showPopup" @closePopup="togglePopup" title="Фильтры"></TestPopup>
    <TestSortPopup v-if="showPopupSortTest" @closePopupSortTest="togglePopupSortTest" title="Сортировка">
    </TestSortPopup>
    <PopupFilterTest v-if="showPopupFilterTest" @closePopupFilterTest="togglePopupFilterTest" title="Фильтация">
    </PopupFilterTest>
    <div class="flex flex-col w-screen">
      <!-- <TestPopup v-if="showPopup" @closePopup="togglePopup" title="Фильтры"></TestPopup> -->'

      <div style="margin-top: 40px;">
        <WelcomeCard></WelcomeCard>
        <CompanySignUpCardFull v-if="!$authStore.isAuthenticated"></CompanySignUpCardFull>
        <WelcomeGetCandidateCard></WelcomeGetCandidateCard>
      </div>
      <div class="bg-[#3D7CD1] pr-5 pl-5 pb-5">
        <LKCard buttonLabel="Создать резюме" fioString="Матаруев Илья Дмитриевич" email="79159893713@mail.ru">
          <template #menu-items>
            <div class="menu-items mb-[10px]">Резюме</div>
            <div class="menu-items mb-[10px]">Личные данные</div>
            <div class="menu-items">Отклики  и приглашения</div>
          </template>
        </LKCard>
      </div>
      <div class="bg-[#3D7CD1] pr-5 pl-5 pb-5">
        <LoginCard></LoginCard>
      </div>
      <div class="bg-[#F8F8F8] pl-4 pr-4 mt-15">
        <span>Карточки</span>
        <button @click="togglePopup">Показать/Скрыть Popup</button>
        <button @click="togglePopupSortTest">Показать/Скрыть Popup sort test</button>
        <button @click="togglePopupFilterTest">Показать/Скрыть PopupFilterTest</button>

        <div style="margin-top: 40px;">
          <ResumeNoLoginCard></ResumeNoLoginCard>
        </div>
        <div style="margin-top: 40px;">
          <Sheet class="mt-20">
            <h1 class="text-xl font-bold mb-[0.5rem]">Заголовок карточки</h1>
          </Sheet>
        </div>
        <div style="margin-top: 40px;">
          <VacancyCard vacancyName="Сварщик" price="от 50 тыс.руб" city="Рыбинск" publicationTime="Сегодня в 13.00"
            v-model="isChecked"></VacancyCard>
        </div>
        <div style="margin-top: 40px;">
          <!-- <CompanySignupCard title="Начните нанимать таланты уже сегодня"></CompanySignupCard> -->

        </div>
        <div class="bg-[#FFFFFF]" style="margin-top: 40px;">
          <ResumeSearch></ResumeSearch>
        </div>
        <div style="margin-top: 40px;">
          <ResumeCard publicationTime="Резюме добавлено сегодня в 13:00" resumeName="Инженер-геодезист" age="48 лет"
            price="125000Р" experience="22 года" lastJobPlace="ООО тепловые сети" lastJobTime="Январь 2018 – Май 2024"
            v-model="isChecked2"></ResumeCard>
        </div>
        <div style="margin-top: 40px;">
          <WelcomeGetJobCard></WelcomeGetJobCard>
        </div>
        <div style="margin-top: 40px;">
          <VacancyCardPartOpen vacancyName="Инженер-геодезист" price="от 50 тыс.руб" experience="3-6 лет"
            jobTimeType="Полная занятость, полный день" v-model="isChecked3"></VacancyCardPartOpen>
        </div>
        <div style="margin-top: 40px;">
          <SimularResumeCard publicationTime="Резюме добавлено сегодня в 13:00" vacancyName="Инженер-геодезист"
            price="от 50 тыс.руб" age="35 лет" experience="10 лет" v-model="isChecked3"></SimularResumeCard>
        </div>
        <div style="margin-top: 40px;" class="z-5">
          <CompanyCard companyName="Энергострой, Строительно-инжиниринговая компания" :grade="gradeCompany"
            reportsCount="87 отзывов"
            imageSrc="/home/ilia/PycharmProjects/water_vacancies/services/frontend/src/assets/images/test/Logo_Energostroy.png">
          </CompanyCard>
        </div>
        <div style="margin-top: 40px;">
        </div>

      </div>
      <div class="bg-[#3D7CD1] pr-5 pl-5 pb-5">
        <div style="margin-top: 40px;">
          <PreSignupCard title="Что необходимо, для начала работы с биржей?"
            subtitle="Зарегистрируйтесь как компания или сотрудник, чтобы пользоваться всеми возможностями">
          </PreSignupCard>
        </div>
      </div>
      <div class="bg-[#F8F8F8]">
        <ResumeFullCard
          imageSrc="/home/ilia/PycharmProjects/water_vacancies/services/frontend/src/assets/images/test/Logo_Energostroy.png"
          publicationTime="Резюме добавлено 20 мая в 12:34" employerName="Иван Петров"
          specialityName="Инженер-геодезист" jobTimeType="Полная занятость, гибкий график" city="Москва"
          readyToMove="не готов к переезду" experience="10 лет" age="48 лет" price="125 000₽"
          :experienceUnits=experiences about="Кандидат пока не заполнил этот раздел" :educationUnits=educations>
        </ResumeFullCard>
      </div>
      <Footer></Footer>
      <div class="bg-[#F8F8F8]">
        <Tabs :tabs="tabs" v-model="activeTab">
          <template #tab1>
            <div class="content">
              <h2 class="title">Content for Tab 1</h2>
              <p class="text">This is the content for the first tab.</p>
            </div>
          </template>
          <template #tab2>
            <div class="content">
              <h2 class="title">Content for Tab 2</h2>
              <p class="text">This is the content for the second tab.</p>
            </div>
          </template>
          <template #tab3>
            <div class="content">
              <h2 class="title">Content for Tab 3</h2>
              <p class="text">This is the content for the third tab.</p>
            </div>
          </template>
          <template #tab4>
            <div class="content">
              <h2 class="title">Content for Tab 4</h2>
              <p class="text">This is the content for the fourth tab.</p>
            </div>
          </template>
          <template #tab5>
            <div class="content">
              <h2 class="title">Content for Tab 5</h2>
              <p class="text">This is the content for the fifth tab.</p>
            </div>
          </template>
        </Tabs>
      </div>


      <div class="bg-[#F8F8F8] py-5 ">
        <h1>Cards slider</h1>
        <CardsSlider>
          <SimularResumeCard class="ml-5" publicationTime="Резюме добавлено сегодня в 13:00"
            vacancyName="Инженер-геодезист" price="от 50 тыс.руб" age="35 лет" experience="10 лет" v-model="isChecked3">
          </SimularResumeCard>
          <SimularResumeCard publicationTime="Резюме добавлено сегодня в 13:00" vacancyName="Инженер-геодезист"
            price="от 50 тыс.руб" age="35 лет" experience="10 лет" v-model="isChecked3"></SimularResumeCard>
          <SimularResumeCard publicationTime="Резюме добавлено сегодня в 13:00" vacancyName="Инженер-геодезист"
            price="от 50 тыс.руб" age="35 лет" experience="10 лет" v-model="isChecked3"></SimularResumeCard>
          <SimularResumeCard class="mr-5" publicationTime="Резюме добавлено сегодня в 13:00"
            vacancyName="Инженер-геодезист" price="от 50 тыс.руб" age="35 лет" experience="10 лет" v-model="isChecked3">
          </SimularResumeCard>

        </CardsSlider>


        <br><br>
        Текущая страница: {{ page }}
        <Pagination v-model="page"></Pagination>
      </div>




      <DoubleSideBlock></DoubleSideBlock>


      <div>
        {{ checkboxValue }}
        <Checkbox v-model="checkboxValue">123</Checkbox>
      </div>
      <div class="mb-20"></div>
    </div>

    <!-- <div class="flex flex-col">
      <div class="bg-[#FFFFFF]" style="width: 393px;">
        <div style="margin-top: 40px;">
          <WelcomeGetJobCard></WelcomeGetJobCard>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="bg-[#F8F8F8] pr-[1.5rem] pl-[1.5rem]" style="width: 393px;">
        <div style="margin-top: 40px;">
          <VacancyCardPartOpen vacancyName="Инженер-геодезист" price="от 50 тыс.руб" experience="3-6 лет"
            jobTimeType="Полная занятость, полный день" v-model="isChecked3"></VacancyCardPartOpen>
        </div>
        <div style="margin-top: 40px;">
          <SimularResumeCard publicationTime="Резюме добавлено сегодня в 13:00" vacancyName="Инженер-геодезист"
            price="от 50 тыс.руб" age="35 лет" experience="10 лет" v-model="isChecked3"></SimularResumeCard>
        </div>
        <div style="margin-top: 40px;" class="z-5">
          <CompanyCard companyName="Энергострой, Строительно-инжиниринговая компания" :grade="gradeCompany"
            reportsCount="87 отзывов"
            imageSrc="/home/ilia/PycharmProjects/water_vacancies/services/frontend/src/assets/images/test/Logo_Energostroy.png">
          </CompanyCard>
        </div>


      </div>
    </div>
    <div class="flex flex-col">
      <div class="bg-[#F8F8F8]" style="width: 393px;">
        <div style="margin-top: 40px;">
          <ResumeFullCard
            imageSrc="/home/ilia/PycharmProjects/water_vacancies/services/frontend/src/assets/images/test/Logo_Energostroy.png"
            publicationTime="Резюме добавлено 20 мая в 12:34" employerName="Иван Петров"
            specialityName="Инженер-геодезист" jobTimeType="Полная занятость, гибкий график" city="Москва"
            readyToMove="не готов к переезду" experience="10 лет" age="48 лет" price="125 000₽"
            :experienceUnits=experiences about="Кандидат пока не заполнил этот раздел" :educationUnits=educations>
          </ResumeFullCard>
        </div>
      </div>
    </div> -->

  </div>

</template>

<script setup>
import Sheet from './../components/cards/Sheet.vue';
import LKCard from './../components/cards/LKCard.vue';
import VacancyCard from './../components/cards/VacancyCard.vue';
import PreSignupCard from './../components/cards/PreSignupCard.vue'
import CompanySignupCard from './../components/cards/CompanySignupCard.vue'
import ResumeSearch from './../components/cards/ResumeSearch.vue'
import ResumeCard from './../components/cards/ResumeCard.vue'
import WelcomeGetJobCard from './../components/cards/WelcomeGetJobCard.vue'
import VacancyCardPartOpen from './../components/cards/VacancyCardPartOpen.vue'
import SimularResumeCard from './../components/cards/SimularResumeCard.vue'
import CompanyCard from './../components/cards/CompanyCard.vue'
import ResumeFullCard from './../components/cards/ResumeFullCard.vue'
import Popup from './../components/ui/Popup.vue'
import TestPopup from './../components/cards/TestPopup.vue'
import TestSortPopup from './../components/cards/TestSortPopup.vue'
import PopupFilterTest from './../components/cards/TestFilterPopup.vue'
import Header from './../components/ui/Header.vue'
import { ref, reactive } from 'vue';
import Tabs from "@/components/ui/Tabs";
import CardsSlider from "@/components/ui/CardsSlider";
import Footer from './../components/ui/Footer.vue'
import Pagination from "@/components/ui/Pagination";
import DoubleSideBlock from "@/components/cards/DoubleSideBlock";
import ResumeNoLoginCard from './../components/cards/ResumeNoLoginCard.vue'
import WelcomeCard from './../components/cards/WelcomeCard.vue'
import CompanySignUpCardFull from './../components/cards/CompanySignUpCardFull.vue';
import WelcomeGetCandidateCard from './../components/cards/WelcomeGetCandidateCard.vue'
import Checkbox from "@/components/ui/Checkbox";
import LoginCard from "@/components/cards/LoginCard";

const count = ref(0);
const isChecked = ref(false)
const isChecked2 = ref(false)
const isChecked3 = ref(false)
const gradeCompany = ref(3.9)
const experiences = ref([
  {
    time: '2018 - по н.в.',
    company: 'ООО Тепловые Сети',
    jobTitle: 'Старший инженер-геодезист',
    description: 'Производство работ по СНиП 111-4-80. Составление исполнительных схем, предоставление отчетов начальству. Работа в среде AutoCad, Word, Exсel. Работа с тахеометром, теодолитом, нивелиром.'
  },
  {
    time: '2016 - 2018',
    company: 'ООО МосСтрой',
    jobTitle: 'Главный инженер-геодезист',
    description: 'Работа в среде AutoCad, Word, Exсel. Работа с тахеометром, теодолитом, нивелиром.'
  },
  {
    time: '2002 - 2016',
    company: 'ООО МосСтрой',
    jobTitle: 'Инженер-геодезист',
    description: 'Производство работ по СНиП 111-4-80. Составление исполнительных схем, предоставление отчетов начальству. '
  },
]);
const educations = ref([
  {
    timeEnd: '2001',
    university: 'Российский Гидрометеорологический Университет',
    educationTitle: 'Инженер-эколог, магистр'
  },
  {
    timeEnd: '1998',
    university: 'Российский Государственный Авиационный Университет',
    educationTitle: 'Инженер-ппрограммист, бакалавр'
  },
]);

const showPopup = ref(false);

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
  showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest = () => {
  showPopupFilterTest.value = !showPopupFilterTest.value;
};

const tabs = [
  { name: "tab1", label: "Новые вакансии" },
  { name: "tab2", label: "Все вакансии" },
  { name: "tab3", label: "Вакансии 3" },
  { name: "tab4", label: "Вакансии 4" },
  { name: "tab5", label: "Вакансии 5" }
];

const activeTab = ref(0);


const page = ref(1)

const checkboxValue = ref(true)
</script>

<style>
/* Базовый размер шрифта для десктопов */
html {
  font-size: 18px;
  /* Размер шрифта по умолчанию */
}

/* Размер шрифта для планшетов */
@media (max-width: 1024px) {
  html {
    font-size: 16px;
    /* Уменьшаем размер шрифта для планшетов */
  }
}

/* Размер шрифта для мобильных устройств */
@media (max-width: 768px) {
  html {
    font-size: 3.84vw;
    /* Еще больше уменьшаем размер шрифта для мобильных устройств */
  }
}

.menu-items {

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
/* identical to box height */
text-align: left;

color: black;
}
</style>