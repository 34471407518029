<template>
  <DefaultLayout >
    <div v-if="$authStore.me"  class="w-full flex flex-col  md:flex-row justify-center md:mt-[50px] mb-[50px] ">
      <div class="p-5 md:p-0 md:mr-[30px]  ">
        <LKCard class="w-full">
          <template v-if="$authStore.me.role === 'employee'" #menu-items>
            <div :class="menuClass('/employee/lk/resumes')" @click="router.push('/employee/lk/resumes')">Резюме</div>
            <div :class="menuClass('/employee/lk/personal')" @click="router.push('/employee/lk/personal')">Личные
              данные
            </div>
            <div :class="menuClass('/employee/lk/feedbacks/invations')"
                 @click="router.push('/employee/lk/feedbacks/invations')">Приглашения
            </div>
            <div :class="menuClass('/employee/lk/feedbacks/responses')"
                 @click="router.push('/employee/lk/feedbacks/responses')">Отклики
            </div>
          </template>
          <template v-else #menu-items>
            <div :class="menuClass('/employer/lk/vacancies')" @click="router.push('/employer/lk/vacancies')">Вакансии
            </div>
            <div :class="menuClass('/employer/lk/feedbacks/invations')"
                 @click="router.push('/employer/lk/feedbacks/invations')">Приглашения
            </div>
            <div :class="menuClass('/employer/lk/feedbacks/responses')"
                 @click="router.push('/employer/lk/feedbacks/responses')">Отклики
            </div>
            <div :class="menuClass('/employer/lk/personal')" @click="router.push('/employer/lk/personal')">Личные
              данные
            </div>
            <div :class="menuClass('/employer/lk/company')" @click="router.push('/employer/lk/company')">Данные
              компании
            </div>
          </template>
        </LKCard>
      </div>
      <div class="p-5 md:p-0">
        <slot  name="default">
<!--          Содержимое странички (LK LAyout)-->
        </slot>
      </div>

    </div>
    <div v-else class="flex justify-center items-center h-full">
      <UILoader is-loading="true"></UILoader>
    </div>


  </DefaultLayout>

</template>

<script setup>
import {ref, defineProps, computed} from "vue";
import DefaultLayout from "@/layouts/DefaultLayout";
import LKCard from "@/components/cards/LKCard"
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import UILoader from "@/components/ui/UILoader";

const route = useRoute();
const router = useRouter();

const disableScroll = ref(false);
const activeItem = ref(null);


const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};



const menuClass = (menuItemPath) => {
  return router.currentRoute.value.path === menuItemPath ? 'form-label-active' : 'form-label';
};

</script>

<style>
.form-label-active {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4A4A4A;
  cursor: pointer;
  margin-bottom: 15px;
}

.form-label {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.035em;
  color: black;
  cursor: pointer;
  margin-bottom: 15px;
}
</style>