<template>
    <Popup :title="title" @closePopup="closePopup">
        <div class="flex flex-col">
            <div class="flex flex-row justify-between">
                <div class="text-[1rem] font-[Inter] mb-[1.25rem]">По умолчанию</div><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 9L11.2174 17L6 12.2" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <circle cx="12" cy="12" r="10.5" stroke="black" />
                </svg> 
            </div>
            <div class="flex flex-row justify-between">
                <div class="text-[1rem] font-[Inter] mb-[1.25rem]">Сначала новые</div><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 9L11.2174 17L6 12.2" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <circle cx="12" cy="12" r="10.5" stroke="black" />
                </svg> 
            </div>
            <div class="flex flex-row justify-between">
                <div class="text-[1rem] font-[Inter] mb-[1.25rem]">Сначала старые</div><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 9L11.2174 17L6 12.2" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <circle cx="12" cy="12" r="10.5" stroke="black" />
                </svg> 
            </div>
            <div class="flex flex-row justify-between">
                <div class="text-[1rem] font-[Inter] mb-[1.25rem]">По убыванию зарплат</div><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 9L11.2174 17L6 12.2" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <circle cx="12" cy="12" r="10.5" stroke="black" />
                </svg> 
            </div>
            <div class="flex flex-row justify-between">
                <div class="text-[1rem] font-[Inter] mb-[1.25rem]">По возрастанию зарплат</div><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 9L11.2174 17L6 12.2" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <circle cx="12" cy="12" r="10.5" stroke="black" />
                </svg> 
            </div>
            <div class="flex flex-row justify-between">
                <div class="text-[1rem] font-[Inter] mb-[1.25rem]">По дате изменения</div><svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 9L11.2174 17L6 12.2" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <circle cx="12" cy="12" r="10.5" stroke="black" />
                </svg> 
            </div>
        </div>
    </Popup>
</template>

<script setup>
import Checkbox from '../ui/Checkbox.vue';
import Popup from './../../components/ui/Popup.vue';
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
    title: {
        type: String,
        default: 'Фильтры',
    },
});

// Передача события для закрытия всплывающего окна
const emit = defineEmits(['closePopupSortTest']);
const closePopup = () => {
    emit('closePopupSortTest');
};
</script>

<style scoped></style>