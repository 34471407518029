<template>
  <Sheet
    class="pt-[1.75rem] pl-[2rem] pr-[2rem] pb-[1.75rem] md:pt-[28px] md:pl-[17px] md:pb-[28px] md:pr-[17px]  flex flex-col items-left">
    <div class="font-inter font-medium text-[0.87rem] mb-[0.71rem] md:text-[14px] md:mb-[10px]">
      Сброс пароля
    </div>
    <div
      class="font-inter font-extralight text-[0.71rem] text-[#B7B7B7] text-left mb-[0.71rem] md:text-[10px] md:mb-[10px]">
      Придумайте новый пароль. Пароль должен состоять минимум из 6 символов и не повторять прошлый пароль.
    </div>
    <Input type="type4-login-pass" placeholder="Новый пароль" v-model="inputPassword"
      class="mb-[0.3rem] md:mb-[5px]"></Input>
    <Input type="type4-login-pass" placeholder="Повторите новый пароль" v-model="inputPasswordRepeat"
      class="mb-[0.3rem] md:mb-[5px]"></Input>
    <div v-if="allert !== null"
      class="font-[Inter] text-[0.73rem] md:text-[10px] text-[#FF0000] w-full text-left mt-[0.3rem] md:mt-[4px]">{{
      allert }}</div>
    <div v-else class="w-full mt-[1.03rem] md:mt-[14px]"></div>
    <Button @click="reset" type="login-logincard" class="mb-[0.71rem] md:mb-[10px] mt-[1rem] md:mt-[13px]">Войти
      с новым паролем</Button>
  </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Checkbox from './../../components/ui/Checkbox.vue';
import Button from './../../components/ui/Button.vue';
import Input from './../../components/ui/Input.vue';
import { defineProps, defineEmits, computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const inputPassword = ref('')
const inputPasswordRepeat = ref('')
const allert = ref(null)

const reset = () => {
  if (isValidPassword(inputPassword.value)) {
        allert.value = null
        if (isMatchingPasswords(inputPassword.value, inputPasswordRepeat.value)) {
            inputPassword.value = inputPassword.value.trim()
            inputPasswordRepeat.value = inputPasswordRepeat.value.trim()
            router.push('/login')
        } else {
            allert.value = 'Пароли не совпадают.'
        }
    } else {
        allert.value = 'Пароль должен состоять минимум из 6 символов.'
    }
};


const isValidPassword = (password) => {

  const trimmedPassword = password.trim()
  return trimmedPassword.length > 5
}

const isMatchingPasswords = (password, repeatPassword) => {
  const trimmedPassword = password.trim()
  const trimmedRepeatPassword = repeatPassword.trim()
  console.log(trimmedPassword, trimmedRepeatPassword, trimmedPassword === trimmedRepeatPassword)
  return trimmedPassword === trimmedRepeatPassword
}


</script>

<style scoped></style>