<template>
  <div class="card-slider">
    <div class="card-container pb-[1rem] pt-[1rem]">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
</script>

<style>
.card-slider {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* For Firefox */
}

.card-slider::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* Показать скроллбар на больших экранах */
@media (min-width: 768px) { /* Для md и выше */
  .card-slider {
    overflow-x: auto; /* Включаем скролл при ширине экрана md и выше */
    scrollbar-width: thin; /* Настройка для Firefox */
  }

  .card-slider::-webkit-scrollbar {
    width: 8px; /* Ширина скроллбара */
  }

  .card-slider::-webkit-scrollbar-track {
    background: #f1f1f1; /* Цвет дорожки скроллбара */
  }

  .card-slider::-webkit-scrollbar-thumb {
    background: #888; /* Цвет ползунка */
    border-radius: 4px; /* Закругление углов */
  }

  .card-slider::-webkit-scrollbar-thumb:hover {
    background: #555; /* Цвет ползунка при наведении */
  }
}

.card-container {
  display: inline-flex;
  gap: 1rem;
}
</style>