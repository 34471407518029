<template>
    <div :class="computedClasses">
      <slot></slot>
    </div>
  </template>
  
  <script setup>
  import { computed } from 'vue';
  import { defineProps } from 'vue';
  
  const standardClass = "bg-[#FFFFFF] rounded-[1rem] p-[1rem] custom-shadow md:rounded-[15px] ";
  const props = defineProps({
    class: {
      type: String,
      default: ''
    },
  });
  
  const computedClasses = computed(() => {
    return standardClass + props.class;
  });
  </script>
  
  <style scoped>
  /* Дополнительные стили, если нужны */
  .custom-shadow {
    box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.1);
  }

  </style>