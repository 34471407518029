<template>
  <div class="relative shadow-md overflow-hidden rounded-[1rem] md:rounded-[15px] welcomecard md:!w-[992px]">
    <div class="absolute inset-0 bg-opacity-20 bg-[#3D7CD1] overflow-hidden rounded-[1rem] md:rounded-[15px]"></div>
    <img v-if="isMobile" :src="MobileImage" alt="MobileImage" class="w-full h-ful object-cover bg-[#111111]" />
    <img v-else :src="DesctopImage" alt="DesctopImage" class="w-full h-full object-cover bg-[#111111]" />

    <div
      class="absolute text-[1.44rem] md:text-[38px] text-[#FFFFFF] leading-[1.75rem] top-[15.56rem] md:top-[232px] left-[1.5rem] md:left-[85px] font-[Inter] font-black ">
      ПОСТРОЙ СВОЮ КАРЬЕРУ
    </div>
    
    <div
      class="absolute text-[1.44rem] md:text-[38px] text-[#FFFFFF] leading-[1.75rem] top-[17.94rem] md:top-[274px] left-[1.5rem] md:left-[85px] font-[Inter] font-black text-shadow">
      В СФЕРЕ ВОДОСНАБЖЕНИЯ
    </div>
    
    <div class="absolute top-[20.62rem] md:top-[350px] w-full pl-[1.5rem] pr-[1.5rem] md:pl-[85px] md:pr-[85px]">
      <div class="relative flex flex-col md:flex-row" @keyup.enter="handleEnter">
          <Input class="h-[3.31rem] md:h-[53px]" type="type1" placeholder="Профессия, должность или компания" v-model="profession"></Input>
          <router-link :to="{ path: '/employee/vacancies', query: { profession: profession } }" class="z-9 md:ml-[10px]">
              <Button type="send-welcome-1" class="h-[3.31rem] md:h-[53px] md:w-[145px] mt-[0.62rem] md:mt-0">Найти</Button>
          </router-link>
          
      </div>
    </div> 
   
    <div v-if="!$authStore.isAuthenticated" class="absolute bottom-[1.75rem] md:bottom-[69px] w-full flex flex-row justify-center md:justify-start md:ml-[85px]">
      <router-link  to="/employer">
        <div class="font-[Inter] font-light text-[1rem] md:text-[18px] text-[#FFFFFF] custom-underline cursor-pointer">Я работодатель</div>
      </router-link>
    </div> 
  </div>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Grade from './../../components/ui/Grade.vue';
import Button from './../../components/ui/Button.vue';
import Icon from '../ui/Icon.vue';
import Input from './../../components/ui/Input.vue';
import { defineProps, defineEmits, computed, ref, onMounted, onUnmounted } from 'vue';
import MobileImage from '@/assets/images/background_welcome.webp';
import DesctopImage from '@/assets/images/background_desctop_welcome.webp';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

const route = useRoute();
const router = useRouter();

const profession = ref('')

const props = defineProps({
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});

const handleEnter = () => {
  console.log('HADLE ENTER!!!!')
  router.push(`/employee/vacancies?profession=${profession.value}`)
};

</script>

<style scoped>

@media (min-width: 769px) {
  .welcomecard {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .welcomecard {
    margin-top: 0rem;
  }
}


</style>