<template>
  <LKLayout >
      <div class="wj-container w-full md:w-[685px] md:min-h-[300px] px-[20px] md:px-[60px] py-[20px] md:py-[52px]">

        <div class="flex flex-col justify-between mb-[60px]">
          <div v-if="mode === 'create'" class="title whitespace-nowrap !text-left">Создание резюме  </div>
          <div v-else class="title whitespace-nowrap !text-left">Редактирование резюме</div>
          <div class="flex flex-col md:flex-row md:gap-[50px] mt-[20px]">
            <div class="nav-item" :active="stage === 0 ? true : undefined" @click="stage = 0">Основная информация</div>
            <div class="nav-item" :active="stage === 1 ? true : undefined" @click="stage = 1">Опыт работы</div>
            <div class="nav-item" :active="stage === 2 ? true : undefined" @click="stage = 2">Дополнительно</div>
          </div>
        </div>

        <div v-if="fetched"  class="md:w-[600px]">
          <template v-if="stage === 0">
            <div>
              <label for="form-name" class="form-label">Кем вы хотите работать?</label>
              <UIInput v-model="form.speciality_name" id="form-name" :rules="[isNotEmpty]"
                @valid="isValid => updateValidity('speciality_name', isValid)"></UIInput>
            </div>

            <div class="mt-[20px]">
              <label for="form-name" class="form-label">Фото</label>
              <UIFileUploader v-model="form.avatar"></UIFileUploader>
            </div>

            <div class="mt-[50px]">
              <div class="flex flex-col md:flex-row gap-[24px]">
                <div class="flex-grow">
                  <label for="form-name" class="form-label">Фамилия</label>
                  <input disabled :value="$authStore.me.last_name" id="form-name" class="form-input w-full" />
                </div>
                <div class="flex-grow">
                  <label for="form-name" class="form-label">Имя</label>
                  <input disabled :value="$authStore.me.first_name" id="form-name" class="form-input w-full" />
                </div>
              </div>

            </div>

            <div class="mt-[50px]">
              <label for="form-name" class="form-label">Отчество</label>
              <input disabled :value="$authStore.me.middle_name" id="form-name" class="form-input w-full" />
            </div>


            <div class="mt-[50px]">
              <label for="form-name" class="form-label">Пол</label>
              <div class="flex gap-[70px]">
                <UIRadioButton disabled :model-value="$authStore.me.sex" name="work-expirience" label="Мужской" value="male" @click.prevent.stop></UIRadioButton>
                <UIRadioButton disabled :model-value="$authStore.me.sex" name="work-expirience" label="Женский" value="female" @click.prevent.stop></UIRadioButton>
              </div>
            </div>


            <div class="mt-[50px]">
              <label for="form-name" class="form-label">Город где ищите работу</label>
              <UISelect v-model="form.city_of_residence" :options="cities" class="w-full"></UISelect>
            </div>

            <div class="mt-[50px] flex flex-col md:flex-row gap-[20px]">
              <div class="flex-grow">
                <label for="form-name" class="form-label">Дата рождения</label>

                <UIDatePicker :model-value="$authStore.me.born_date" :disabled="true"></UIDatePicker>
                <!--                <UIDatePicker v-model="$authStore.me.born_date" ></UIDatePicker>-->
              </div>
              <div class="flex-grow">
                <label for="form-name" class="form-label">Полных лет</label>
                <input :value="calculateAge($authStore.me.born_date)" id="form-name" class="form-input w-full" disabled />
              </div>

            </div>


            <div class="mt-[50px]">
              <label for="form-name" class="form-label">Номер телефона</label>
              <input disabled :value="$authStore.me.phone_number" id="form-name" class="form-input w-full" />
            </div>


            <div class="mt-[50px]">
              <label for="form-name" class="form-label">Гражданство</label>
              <UISelect v-model="form.citizenship" class="w-full" :options="[{ title: 'Россия', value: 1 }]">
              </UISelect>
            </div>

            <div class="mt-[50px] flex flex-col md:flex-row gap-[20px]">
              <div class="flex-grow flex flex-col">
                <label for="form-name" class="form-label">Образование</label>
                <UIRadioButton v-model="form.education_type" name="edu-level" label="Среднее" value="secondary">
                </UIRadioButton>
                <UIRadioButton v-model="form.education_type" name="edu-level" label="Средне специальное"
                  value="specialized_secondary"></UIRadioButton>
                <UIRadioButton v-model="form.education_type" name="edu-level" label="Неоконченное высшее"
                  value="unfinished_higher"></UIRadioButton>
                <UIRadioButton v-model="form.education_type" name="edu-level" label="Бакалавр" value="bachelor">
                </UIRadioButton>
                <UIRadioButton v-model="form.education_type" name="edu-level" label="Магистр" value="master">
                </UIRadioButton>
                <UIRadioButton v-model="form.education_type" name="edu-level" label="Кандидат наук"
                  value="candidate_of_sciences"></UIRadioButton>
                <UIRadioButton v-model="form.education_type" name="edu-level" label="Доктор наук"
                  value="doctor_of_sciences"></UIRadioButton>
              </div>
              <div class="flex-grow flex flex-col ">
                <label for="form-name" class="form-label">Готовность к переезду</label>
                <UIRadioButton v-model="form.ready_to_move" name="ready-to-move " label="Готов к переезду"
                  :value="true"></UIRadioButton>
                <UIRadioButton v-model="form.ready_to_move" name="ready-to-move " label="Не готов к переезду"
                  :value="false"></UIRadioButton>
              </div>
            </div>

            <!--       Список учебных заведений     -->
            <div class="mt-[50px] flex flex-col gap-[50px]">
              <div v-for="(education) in form.educations" :key="education">
                <label for="form-name" class="form-label">Сведения об учебном заведении</label>
                <div class="form-sublabel">Название учебного заведения</div>
                <!-- <input v-model="education.institution_name" class="form-input w-full mt-[20px]"/> -->
                <UIInput v-model="education.institution_name"></UIInput>
                <div class="form-sublabel">Специализация</div>
                <UIInput v-model="education.degree_name"></UIInput>
                <div class="form-sublabel mt-[20px]">Год окончания (предполагаемого окончания, если ещё учитесь)</div>
                <UIInput v-model="education.graduation_year"></UIInput>

                <!--                <UIDatePicker v-model="education.graduation_year"></UIDatePicker>-->
              </div>
            </div>


            <div class="mt-[50px] flex gap-[25px]">
              <Button type="login-logincard" class="md:w-[362px]" @click="addNewEducationUnit">Добавить учебное
                заведение +
              </Button>
            </div>


            <div class="mt-[50px] flex flex-col md:flex-row gap-[25px]">
              <Button type="signup" class="md:w-[362px] !text-[14px] whitespace-nowrap" @click="createOrSave('DRAFT')">Сохранить как черновик</Button>
              <Button type="login-logincard" class="md:w-[362px]" @click="stage = 1">Продолжить</Button>
            </div>


          </template>


          <template v-if="stage === 1">
            <div>
              <label for="form-name" class="form-label">Опыт работы</label>
              <UIRadioButton v-model="hasWorkExpirience" name="has-work-expirience" label="Нет опыта работы"
                :value="false"></UIRadioButton>
              <UIRadioButton v-model="hasWorkExpirience" name="has-work-expirience"
                label="Указать предыдущее место работы" :value="true"></UIRadioButton>
            </div>


            <div v-if="hasWorkExpirience" class="flex flex-col gap-[50px]">

              <div class="mt-[50px]" v-for="(experience, index) in form.experiences" :key="experience.id">
                <div>
                  <label for="form-name" class="form-label">Название компании</label>
                  <div class="form-sublabel">Укажите название последнего места работы</div>
                  <!-- <UIInput v-model="education.graduation_year" :rules="[isNotEmptyGraduationYear]"></UIInput> -->
                  <UIInput v-model="experience.company_name" :rules="[isNotEmpty]"
                    @valid="isValid => updateValidity(`experiences${index}company_name`, isValid)"></UIInput>
                </div>

                <div class="mt-[50px]">
                  <label for="form-name" class="form-label">Должность</label>
                  <div class="form-sublabel">Укажите название должности на последнем месте работы.</div>
                  <UIInput v-model="experience.position_name" :rules="[isNotEmpty]"
                    @valid="isValid => updateValidity(`experiences${index}position_name`, isValid)"></UIInput>
                </div>

                <div class="mt-[50px]">
                  <label for="form-name" class="form-label">Обязанности и достижения</label>
                  <div class="form-sublabel">Например, подготавливали исходные геодезические данные и писали отчёты по
                    проделанной работе.
                  </div>
                  <UITextArea v-model="experience.position_description" class="max-h-[300px] min-h-[100px]">
                  </UITextArea>
                </div>

                <div class="mt-[50px] flex flex-col md:flex-row gap-[20px] ">
                  <div class="flex-grow">
                    <label for="form-name" class="form-label">Начало работы</label>
                    <UIDatePicker v-model="experience.start_date"></UIDatePicker>
                  </div>
                  <div class="flex-grow">
                    <label for="form-name" class="form-label">Окончание</label>
                    <UIDatePicker v-model="experience.end_date" :disabled="untilNow[index]"></UIDatePicker>
                  </div>


                </div>
                <Checkbox class="mt-[20px]" large="true" v-model="untilNow[index]" title="По настоящее время">
                </Checkbox>

              </div>


              <div class="mt-[50px] flex gap-[25px]">
                <Button type="login-logincard" class="md:w-[362px]" @click="addNewWorkUnit">Добавить опыт работы +
                </Button>
              </div>
            </div>

            <div class="mt-[50px] flex flex-col md:flex-row gap-[25px]">
              <Button type="signup" class="md:w-[362px] !text-[14px] whitespace-nowrap" @click="createOrSave('DRAFT')">Сохранить как черновик</Button>
              <Button type="login-logincard" class="md:w-[362px]" @click="stage = 2">Продолжить</Button>
            </div>
          </template>


          <template v-if="stage === 2">
            <div>
              <label for="form-name" class="form-label">Желаемый уровнь дохода</label>
              <div class="flex flex-col md:flex-row gap-[25px]">
                <input v-model="form.salary_expectations" id="form-name" class="form-input w-[150px]"
                  placeholder="От" />
                <input id="form-name" class="form-input w-[150px]" placeholder="До" />
                <UISelect :model-value="'rub'" trigger-class="w-full md:w-[150px]"
                  :options="[{ title: '$ (Доллар)', value: 'usd' }, { title: '€ (Евро)', value: 'euro' }, { title: '₽ (Российский рубль)', value: 'rub' }]">
                </UISelect>
              </div>
            </div>

            <!--   Тип занятости    -->
            <div class="mt-[50px]">
              <label for="form-name" class="form-label"> Тип занятости</label>
              <div>
                <UIRadioButton v-model="form.employment_type" name="work-type" label="Полная занятость"
                  value="full_day"></UIRadioButton>
                <UIRadioButton v-model="form.employment_type" name="work-type" label="Частичная занятость"
                  value="not_full_day"></UIRadioButton>
                <UIRadioButton v-model="form.employment_type" name="work-type" label="Проектная работа" value="project">
                </UIRadioButton>
              </div>

              <div>
                <UIRadioButton v-model="form.employment_type" name="work-type" label="Стажировка" value="internship">
                </UIRadioButton>
                <UIRadioButton v-model="form.employment_type" name="work-type" label="Волонтерство"
                  value="volunteering"></UIRadioButton>
              </div>
            </div>

            <div class="mt-[50px]">
              <label for="form-name" class="form-label">Обо мне</label>
              <div class="form-sublabel">Кратко расскажите о своих лучших качествах</div>
              <UITextArea v-model="form.about_me" class="max-h-[300px] min-h-[100px]"></UITextArea>
            </div>

            <div class="mt-[50px]">
              <label for="form-name" class="form-label">Видимость резюме</label>
              <UIRadioButton v-model="form.visible" name="resume-visible" label="Видно всем" :value="true">
              </UIRadioButton>
              <UIRadioButton v-model="form.visible" name="resume-visible" label="Не видно никому" :value="false">
              </UIRadioButton>
            </div>


            <div class="mt-[50px] flex flex-col md:flex-row gap-[25px]">
              <Button type="signup" class="md:w-[362px] !text-[14px] whitespace-nowrap" @click="createOrSave('DRAFT')">Сохранить как черновик</Button>
              <Button v-if="allTrue" type="login-logincard" class="md:w-[362px]" @click="createOrSave('PUBLISHED')">{{ savePublishedButtonLabel }}</Button>
              <div v-else class="error_message">Публикация недоступна для незаполеннной формы</div>
            </div>
          </template>
        </div>


      </div>

  </LKLayout>

</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import LKLayout from "@/layouts/LKLayout";
import {onMounted, reactive, ref, computed, watch, onBeforeMount} from "vue";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UISelect from "@/components/ui/UISelect";
import { useFetch } from "@/composables/useFetch";
import Button from "@/components/ui/Button";
import UITextArea from "@/components/ui/UITextArea";
import UIInput from "@/components/ui/UIInput";
import Checkbox from "@/components/ui/Checkbox";
import UIDatePicker from "@/components/ui/UIDatePicker";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import UILoader from "@/components/ui/UILoader";
import {useToast} from "vue-toast-notification";
import UIFileUploader from "@/components/ui/UIFileUploader";

const route = useRoute();
const router = useRouter();

const resumeId = ref(route.params.id);
const fetched = ref(false)

const fetch = useFetch()
const stage = ref(0)
const testDate = ref(null)
const form = reactive({
  avatar: null,
  speciality_name: null,
  sex: null,
  city_of_residence: null,
  citizenship: null,
  educations: [],
  experiences: [],
  salary_expectations: 0,
  currency: 1,
  employment_type: 'full_day',
  ready_to_move: true,
  about_me: null,
  visible: true,
  education_type: 'secondary',
})

const untilNow = ref([])

watch(() => untilNow.value, (newVal) => {
  newVal.forEach((item, index) => {
    if (item === true) {
      form.experiences[index].end_date = null
    }

  });
}, { deep: true });
// const endDateDisabled = computed(() => {
//       return Object.values(inputs.value).every(value => value === true);
// });

const inputs = ref({
  'speciality_name': false,
});

const updateValidity = (index, isValid) => {
  inputs.value[index] = isValid;
  console.log('inputs.value', inputs.value)
}


const hasWorkExpirience = ref(false)

const cities = ref([])

function calculateAge(birthdate) {
  // Преобразуем строку даты в объект Date
  const birthDate = new Date(birthdate);
  const today = new Date(); // Текущая дата

  // Вычисляем разницу в годах
  let age = today.getFullYear() - birthDate.getFullYear();

  // Проверяем, успел ли день рождения в этом году
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Если день рождения ещё не состоялся в этом году, уменьшаем на 1
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

const isNotEmpty = (string) => {
  if (string === null) {
    return 'Поле не должно быть пустым'
  }
  if (string.length > 0) {
    return null
  } else {
    return 'Поле не должно быть пустым'
  }
}


onBeforeMount(() => {
  const citiesRequest = fetch.anonRequest('/cities/').then((data) => {
    cities.value = data.results.map(item => ({
      title: item,
      value: item,
    }))
  })


  let resumeRequest;

  if (mode.value === 'update') {
    resumeRequest = fetch.authRequest(`/resumes/${resumeId.value}/`).then((data) => {
      form.avatar = data.avatar

      form.speciality_name = data.speciality_name
      form.sex = data.sex
      form.city_of_residence = data.city_of_residence
      form.citizenship = data.citizenship
      form.educations = data.education
      form.experiences = data.experience

      form.salary_expectations = data.salary_expectations
      form.currency = data.currency
      form.employment_type = data.employment_type
      form.ready_to_move = data.ready_to_move
      form.about_me = data.about_me
      form.visible = data.visible
      form.education_type = data.education_type
    })
  }
  Promise.all([citiesRequest, resumeRequest].filter(Boolean)).then(() => {

    if (mode.value === 'update') {
      hasWorkExpirience.value = true
    }
    fetched.value = true;
  });
})

const addNewEducationUnit = () => {
  form.educations.push({
    graduation_year: null,
    institution_name: null,
    degree_name: null,
  })

  const lenEdu = form.educations.length - 1
  // console.log('key!!!!!', lenEdu)
  // const institution_name_key = `educatuons${lenEdu}institution_name`
  // const degree_name_key = `educatuons${lenEdu}degree_name`
  // const graduation_year_key = `educatuons${lenEdu}graduation_year`
  // // console.log('key', key)

  // inputs.value[institution_name_key] = false
  // inputs.value[degree_name_key] = false
  // inputs.value[graduation_year_key] = false
  // console.log('key!!!!!', inputs.value)
}

const allTrue = computed(() => {
  return Object.values(inputs.value).every(value => value === true);
});

const mode = computed(() => {
  if (resumeId.value) {
    return 'update'
  } else {
    return 'create'
  }
});

const savePublishedButtonLabel = computed(() => {
  if (mode.value === 'update') {
    return 'Cохранить как опубликованное'
  } else {
    return 'Опубликовать'
  }
});

const toast = useToast()
const addNewWorkUnit = () => {
  form.experiences.push({
    start_date: null,
    end_date: null,
    company_name: null,
    position_name: null,
    position_description: null,
  })

  untilNow.value.push(false)
}


const createOrSave = (type) => {
  form.status = type
  if (mode.value === 'update') {
    fetch.authRequest(`/resumes/${resumeId.value}/update`, {
      method: 'PUT',
      body: {
        ...form
      }
    }).then(response => {
      toast.success('Сохранено')
      console.log('Сохранено')
    })
  } else {
    fetch.authRequest('/resumes/create', {
      method: 'POST',
      body: {
        ...form
      }
    }).then(response => {
      toast.success('Создано')
      console.log('created')
    })
  }

}

</script>

<style scoped>
.wj-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}


.title {
  /* Создание вакансии */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #4A4A4A;


}

.nav-item {


  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;

  color: #888888;
  cursor: pointer;
}

.nav-item[active] {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-decoration-line: underline;

  color: #2894FF;
}

.form-input[disabled] {
  opacity: 0.5;
}


.form-input {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;

  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 15px;

  height: 42px;
}


.form-label {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4A4A4A;
  margin-bottom: 20px;
}


.form-sublabel {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  leading-trim: both;
  text-edge: cap;
  letter-spacing: 0.04em;

  color: #888888;

  margin-bottom: 20px;


}

.error_message {

  font-family: 'Inter';
  font-style: normal;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 22px;
  letter-spacing: 0.04em;

  color: #DB4545;
}
</style>