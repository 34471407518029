import { createApp } from 'vue'
import App from './App.vue'
import {createPinia} from 'pinia'
import router from './router'

import './assets/tailwind.css'
import './styles/styles.css'
import {useAuthStore} from "@/stores/authStore";

import ToastPlugin from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-bootstrap.css';
import 'vue-toast-notification/dist/theme-default.css';
import {useToast} from 'vue-toast-notification';


const pinia = createPinia()

const app = createApp(App)
  .use(router)
  .use(pinia)

app.use(ToastPlugin);


// Добавляем store в globalProperties, чтобы было доступно через this.$mystore
app.config.globalProperties.$authStore = useAuthStore()
app.config.globalProperties.$toasts = useToast()

app.mount('#app')