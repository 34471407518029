<template>
    <div class="bg-[#F8F8F8]">
        <div class="mb-[3.75rem] bg-[#F8F8F8] md:mb-[80px]">
            <Header @toggleScroll="handleToggleScroll"></Header>
            <TestSortPopup v-if="showPopupSortTest" @closePopupSortTest="togglePopupSortTest" title="Сортировка">
            </TestSortPopup>
            <PopupFilterTest v-if="showPopupFilterTest" @closePopupFilterTest="togglePopupFilterTest" @resetPopupFilterTest="resetPopupFilterTest" v-model="filters" title="Фильтация">
            </PopupFilterTest>
        </div>
        <div class="flex flex-row justify-center">
            <div class="flex flex-col bg-[#F8F8F8]  ml-[1.5rem] mr-[1.5rem] mb-[5.93rem] md:mb-[30px] w-full md:w-[992px]">
                <div

                    class="mt-[1rem] md:mt-[40px] leading-[1.06rem] lg:leading-[30px] max-w-[21.25rem] md:max-w-[700px] mb-[1.25rem] md:mb-[22px]">
                    <div v-if="!$authStore.isAuthenticated" class="text-[0.87rem] text-[#282828] md:text-[16px] font-[Inter] font-light  w-full">
                        <router-link  to="/signup">
                            <span class="md:h-[26px] custom-underline cursor-pointer">Зарегистрируйтесь</span>
                        </router-link>
                        чтобы разблокировать больше возможностей
                    </div>
                </div>
                <ResumeSearch placeholder="Поиск по резюме" v-model="searchTerm" @search="handleSearch"></ResumeSearch>
                <div v-if="isMobile" class="flex flex-row justify-between mt-[2.25rem] mb-[1.25rem]">
                    <div @click="togglePopupSortTest" class="flex flex-row items-center cursor-pointer">
                        <div class="mr-[0.6rem] text-[0.87rem] font-[Inter] font-regular">Сортировка</div>
                        <Icon name="chevron-down"></Icon>
                    </div>
                    <div @click="togglePopupFilterTest" class="flex flex-row items-center cursor-pointer">
                        <div class="mr-[0.6rem] text-[0.87rem] font-[Inter] font-regular">Фильтры</div>
                        <Icon name="filters"></Icon>
                    </div>
                </div>
                <div v-else class="flex flex-row  mt-[36px] mb-[19px]">
                    <div class="flex flex-row items-center cursor-pointer mr-[15px]" v-if="newFirst" @click="toggleNewFirst">
                        <div class="mr-[5px] text-[14px] font-[Inter]">Сначала новые</div>
                        <Icon name="chevron-down"></Icon>
                    </div>
                    <div class="flex flex-row items-center cursor-pointer mr-[15px]" v-else @click="toggleNewFirst">
                        <div class="mr-[5px] text-[14px] font-[Inter]">Сначала старые</div>
                        <Icon name="chevron-up"></Icon>
                    </div>
<!--                    <div class="flex flex-row items-center cursor-pointer">-->
<!--                        <div class="mr-[5px] text-[14px] font-[Inter]">По соответствию</div>-->
<!--                        <Icon name="chevron-down"></Icon>-->
<!--                    </div>-->
                </div>
                <div class="flex flex-row">
                    <FiltersDesktop v-if="!isMobile" v-model="filters"></FiltersDesktop>
                    <div class="flex flex-col md:ml-[30px] w-full md:w-[670px]">
                      <UINoData :data="resumes.length" :loading="loading"></UINoData>
                        <div class="mb-[0.62rem] md:mb-[20px]" v-for="(item, index) in resumes" :key="index">
                            <router-link :to="{ path: `/employer/resumes/${item.id}`}">
                                <ResumeCard :publicationTime=item.created_at :resumeName=item.speciality_name
                                :age=item.full_years :price=item.salary_expectations
                                :currencyName=item.currency_display_symbol :experience=item.work_experience
                                :lastJobPlace="(item.last_job_info !== null) ? item.last_job_info.company_name : null"
                                :lastJobTimeStart="(item.last_job_info !== null) ? item.last_job_info.start_date : null"
                                :lastJobTimeEnd="(item.last_job_info !== null) ? item.last_job_info.end_date : null"
                                v-model="isChecked2">
                            </ResumeCard>
                            </router-link>
                            
                        </div>
                    </div>
                </div>
                
                <div class="mb-[0.61rem] md:mb-[19px]" v-if="resumes.length < itemsPerPage"></div> 
                <div v-if="totalPages > 1" class="flex flex-row justify-start md:mt-[46px]">
                    <Pagination v-model="page" :total="totalPages"></Pagination>
                </div>


            </div>
        </div>

        <Footer></Footer>
    </div>

</template>

<script setup>
import ResumeSearch from './../components/cards/ResumeSearch.vue'
import ResumeCard from './../components/cards/ResumeCard.vue'
import TestSortPopup from './../components/cards/TestSortPopup.vue'
import PopupFilterTest from './../components/cards/TestFilterPopup.vue'
import Header from './../components/ui/Header.vue'
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import Footer from './../components/ui/Footer.vue'
import Pagination from "@/components/ui/Pagination";
import Icon from './../components/ui/Icon.vue';
import FiltersDesktop from './../components/cards/FiltersDesktop.vue'
import { useFetch } from '@/composables/useFetch';
import { useRoute } from 'vue-router';
import UINoData from "@/components/ui/UINoData";

const { anonRequest, authRequest } = useFetch();
const resumes = ref([]);
const loading = ref(false);
const error = ref(null);

const newFirst = ref(true);
const itemsPerPage = ref(5)
const page = ref(1)
const totalCount = ref(1)
const route = useRoute()

const searchTerm = ref(route.query.profession);
const filters = ref({
    jobTypes: [],
    cities: [],
    jobShedules: [],
    jobExperiences: [],
});

const offset = computed(() => {
  return (page.value - 1) * itemsPerPage.value
})

const totalPages = computed(() => {
    return Math.floor((totalCount.value - 1)/itemsPerPage.value) + 1
})

watch(newFirst, (newValue) => {
    fetchResumesDataFiltered();
});

watch(page, (newValue) => {
    fetchResumesDataFiltered();
});

watch(filters, (newValue) => {
    fetchResumesDataFiltered();
}, { deep: true });

const handleSearch = () => {
    fetchResumesDataFiltered();
}

const fetchResumesData = async (queryParams = {}) => {
    console.log('Fetching resumes data...');
    loading.value = true; // Устанавливаем статус загрузки
    error.value = null; // Сбрасываем предыдущую ошибку
    resumes.value = []
    queryParams.limit = itemsPerPage.value
    queryParams.offset = offset.value
    queryParams.status__in = 'PUBLISHED'

    try {
        // Создаем строку запроса вручную
        const queryArray = [];
        for (const key in queryParams) {
            const value = queryParams[key];
            // Проверьте, является ли значение массивом
            if (Array.isArray(value)) {
                // Если массив, объедините значения через запятую
                queryArray.push(`${key}=${value.join(',')}`);
            } else {
                queryArray.push(`${key}=${value}`);
            }
        }
        let queryString = queryArray.join('&'); 
        const url = `/resumes/${queryString ? '?' + queryString : ''}`;

        

        const response = await anonRequest(url, {
            method: 'GET',
        });

        console.log('Request URL:', response); // Логируем конечный URL

        // Если запрос успешен, сохраняем данные
        if (response && !response.error) {
            resumes.value = response.results; // Предполагаем, что API возвращает массив данных
            totalCount.value = response.count
        } else {
            // Если есть ошибка, устанавливаем ошибку
            error.value = response;
        }
    } catch (err) {
        console.error('Запрос завершился ошибкой:', err);
        error.value = err; // Устанавливаем ошибку
    } finally {
        loading.value = false; // Сбрасываем статус загрузки
    }
};

const fetchResumesDataFiltered = () => {
    let jobTypes = []
    filters.value.jobTypes.forEach((element, index) => {
        if (element === true) {
            if (index === 0) {
                jobTypes.push('full_day')
            } else if (index === 1) {
                jobTypes.push('not_full_day')
            }
            else if (index === 2) {
                jobTypes.push('project')
            }
            else if (index === 3) {
                jobTypes.push('intership')
            }
        }

    })
    let jobShedules = []
    filters.value.jobShedules.forEach((element, index) => {
        if (element === true) {
            if (index === 0) {
                jobShedules.push('full_day')
            } else if (index === 1) {
                jobShedules.push('shift_work')
            }
            else if (index === 2) {
                jobShedules.push('remote')
            }
            else if (index === 3) {
                jobShedules.push('flexible')
            }
        }

    })
    let experienceDiapason = []
    filters.value.jobExperiences.forEach((element, index) => {
        if (element === true) {
            experienceDiapason.push(index)
        }

    })
    let queryParams = {}
    if (newFirst.value === true) {
        queryParams.order_by = '-created_at'
    } else {
        queryParams.order_by = 'created_at'
    }
    if (jobTypes.length > 0) {
        queryParams.employment_type__in = jobTypes.join(',')
    }
    if (jobShedules.length > 0) {
        queryParams.employment_schedule__in = jobShedules.join(',')
    }
    if (experienceDiapason.length > 0) {
        queryParams.work_experience_diapason__in = experienceDiapason.join(',')
    }
    if (filters.value.cities.length > 0) {
        const filtersNoFalse = filters.value.cities.filter(item => item !== false)
        queryParams.region__in = filtersNoFalse.join(',')
       
    }
    if (searchTerm.value && searchTerm.value.trim() !== '') {
        queryParams.query = searchTerm.value
    }
    fetchResumesData(queryParams)
}

const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
        document.documentElement.style.overflow = 'auto';
    }
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
    fetchResumesDataFiltered();
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

const isChecked2 = ref(false)


const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
    showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest = () => {
    showPopupFilterTest.value = !showPopupFilterTest.value;
};

watch(showPopupSortTest, (newValue) => {
    if (newValue) {
        disableScroll.value = true
    } else {
        disableScroll.value = false
    }
})

watch(showPopupFilterTest, (newValue) => {
    if (newValue) {
        disableScroll.value = true
    } else {
        disableScroll.value = false
    }
})

const resetPopupFilterTest = () => {
    showPopupFilterTest.value = !showPopupFilterTest.value;
    filters.value.jobTypes = []
    filters.value.cities = []
    filters.value.jobShedules = []
    filters.value.jobExperiences = []
};

const toggleNewFirst = () => {
    newFirst.value = !newFirst.value;
};



</script>

<style></style>