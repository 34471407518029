<template>
  <label class="flex items-center space-x-[0.25rem] cursor-pointer">
    <input type="checkbox" class="hidden" :checked="checked" @change="toggleCheck" />
    <div v-if="isMobile" class="w-[1.5rem] h-[1.5rem]">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        :fill="checked ? '#00C8EF' : 'transparent'"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#00C8EF"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="w-[1.5rem] h-[1.5rem]"
      >
        <path
          d="M11.1033 3.81663C11.4701 3.07346 12.5299 3.07346 12.8967 3.81663L14.8576 7.78909C15.0031 8.08394 15.2843 8.2884 15.6096 8.33595L19.9962 8.97712C20.8161 9.09696 21.1429 10.1048 20.5493 10.683L17.3768 13.773C17.1409 14.0027 17.0333 14.3339 17.0889 14.6584L17.8374 19.0226C17.9775 19.8396 17.12 20.4626 16.3864 20.0767L12.4655 18.0148C12.1741 17.8615 11.8259 17.8615 11.5345 18.0148L7.61363 20.0767C6.88 20.4626 6.02245 19.8396 6.16257 19.0226L6.91109 14.6584C6.96675 14.3339 6.85908 14.0027 6.62321 13.773L3.45068 10.683C2.85708 10.1048 3.18387 9.09696 4.00378 8.97712L8.39037 8.33595C8.71572 8.2884 8.99691 8.08394 9.14245 7.78909L11.1033 3.81663Z"
        />
      </svg>
    </div>
    <div v-else style="width: 24px; height: 24px;">
      <svg width="24" height="24" viewBox="0 0 24 24" :fill="checked ? '#00C8EF' : 'transparent'"
        xmlns="http://www.w3.org/2000/svg" stroke="#00C8EF" stroke-linecap="round" stroke-linejoin="round">
        <path
          d="M11.1033 3.81663C11.4701 3.07346 12.5299 3.07346 12.8967 3.81663L14.8576 7.78909C15.0031 8.08394 15.2843 8.2884 15.6096 8.33595L19.9962 8.97712C20.8161 9.09696 21.1429 10.1048 20.5493 10.683L17.3768 13.773C17.1409 14.0027 17.0333 14.3339 17.0889 14.6584L17.8374 19.0226C17.9775 19.8396 17.12 20.4626 16.3864 20.0767L12.4655 18.0148C12.1741 17.8615 11.8259 17.8615 11.5345 18.0148L7.61363 20.0767C6.88 20.4626 6.02245 19.8396 6.16257 19.0226L6.91109 14.6584C6.96675 14.3339 6.85908 14.0027 6.62321 13.773L3.45068 10.683C2.85708 10.1048 3.18387 9.09696 4.00378 8.97712L8.39037 8.33595C8.71572 8.2884 8.99691 8.08394 9.14245 7.78909L11.1033 3.81663Z" />
      </svg>
    </div>
  </label>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, onUnmounted, ref } from 'vue';

const props = defineProps({
  checked: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:checked']);

function toggleCheck(event) {
  emit('update:checked', event.target.checked);
}

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});
</script>

<style scoped>
/* В данном случае весь стиль находится в шаблоне с использованием TailwindCSS */
</style>