<template>
    <Sheet class="pt-[42px] pl-[26px] w-[285px] rounded-[15px] h-[935px]">
        <div class="flex flex-col">
            <div class="text-[14px] font-[Inter] font-semibold mb-[15px]">Регион</div>
            <CheckboxesList :items=cities v-model="citiesModel">
            </CheckboxesList>

            <div class="text-[14px] font-[Inter] font-semibold mb-[15px] mt-[30px]">Тип занятости</div>
            <CheckboxesList :items=jobTypes v-model="jobTypesModel">
            </CheckboxesList>
            <!-- {{ jobTypesModel }} -->

            <div class="text-[14px] font-[Inter] font-semibold mb-[15px] mt-[30px]">График работы</div>
            <CheckboxesList :items=jobShedules v-model="jobShedulesModel">
            </CheckboxesList>

            <div class="text-[14px] font-[Inter] font-semibold mb-[15px] mt-[30px]">Опыт работы</div>
            <CheckboxesList :items=jobExperiences v-model="jobExperiencesModel">
            </CheckboxesList>
        </div>
    </Sheet>
</template>


<script setup>
import Sheet from './Sheet.vue';
import Checkbox from './../../components/ui/Checkbox.vue';
import CheckboxesList from './../../components/ui/CheckboxesList.vue';
import Button from './../../components/ui/Button.vue';
import { defineProps, defineEmits, computed, ref, watch, onMounted} from 'vue';
import { useFetch } from '@/composables/useFetch';

const { authRequest, anonRequest } = useFetch();

const props = defineProps({
    modelValue: {
        type: Object,
        default: () => {},
    },
});

const jobTypes = ref(['Полная занятость', 'Частичная занятость', 'Проектная работа', 'Стажировка'])
const jobShedules = ref(['Полный день', 'Сменный график', 'Удаленная работа', 'Гибкий график'])
const jobExperiences = ref(['Нет опыта', 'От 1 года до 3 лет', 'От 3 до 6 лет', 'Более 6 лет'])
const jobTypesModel = ref([])
const citiesModel = ref([])
const jobShedulesModel = ref([])
const jobExperiencesModel = ref([])
const filtersModel = ref({
    'jobTypes': [],
    'cities': [],
    'jobShedules': [],
    'jobExperiences': [],
})

onMounted(() => {
    fetchCitiesData();
});

const cities = ref([])
const loading = ref(false);
const error = ref(null);

const fetchCitiesData = async () => {
  console.log('Fetching cities data...');
  loading.value = true; // Устанавливаем статус загрузки
  error.value = null; // Сбрасываем предыдущую ошибку

  try {
    const url = `/regions/`;

    const response = await anonRequest(url, {
      method: 'GET',
    });

    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      cities.value = response.results; // Предполагаем, что API возвращает массив данных
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

const emit = defineEmits(['update:modelValue']);

watch(filtersModel, (newValue) => {
    emit('update:modelValue', newValue);
}, { deep: true })

watch(jobTypesModel, (newValue) => {
    filtersModel.value.jobTypes = newValue
    // console.log(filtersModel.value.jobTypes)
    // console.log('upddate jobTypesModel')
}, { deep: true })

watch(citiesModel, (newValue) => {
    let citiesArray = []
    newValue.forEach((element, index) => {
        if (element === true) {
            citiesArray.push(cities.value[index])
        }
    })
    filtersModel.value.cities = citiesArray
}, { deep: true })

watch(jobShedulesModel, (newValue) => {
    filtersModel.value.jobShedules = newValue
    // console.log('upddate jobShedulesModel')
}, { deep: true })

watch(jobExperiencesModel, (newValue) => {
    filtersModel.value.jobExperiences = newValue
    // console.log('upddate jobExperiencesModel')
}, { deep: true })

</script>

<style scoped></style>