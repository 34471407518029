<template>
  <LKLayout>

    <div class="wj-container md:w-[645px] md:min-h-[300px] px-[15px] py-[15px] md:px-[1rem] md:py-[1rem]">
      <div class="flex justify-between mb-[60px]">
        <div class="title">Личные данные</div>
      </div>
      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Фамилия</label>
        <UIInput v-model="$authStore.me.last_name"></UIInput>
      </div>
      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Имя</label>
        <UIInput v-model="$authStore.me.first_name"></UIInput>
      </div>
      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Отчество</label>
        <UIInput v-model="$authStore.me.middle_name"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Номер телефона</label>
        <UIInput v-model="$authStore.me.phone_number"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Электронная почта</label>
        <UIInput v-model="$authStore.me.email"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Должность</label>
        <UIInput v-model="$authStore.me.job_title"></UIInput>
      </div>

      <div class="mt-[20px]">
        <Button type="login-logincard" class="md:w-[362px]" @click="save">Сохранить</Button>
      </div>

    </div>
  </LKLayout>

</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import LKLayout from "@/layouts/LKLayout";
import {onMounted, reactive, ref, computed, watch} from "vue";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UISelect from "@/components/ui/UISelect";
import {useFetch} from "@/composables/useFetch";
import Button from "@/components/ui/Button";
import UITextArea from "@/components/ui/UITextArea";
import UIInput from "@/components/ui/UIInput";
import Checkbox from "@/components/ui/Checkbox";
import UIDatePicker from "@/components/ui/UIDatePicker";
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import {useAuthStore} from "@/stores/authStore";

const fetch = useFetch()
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore()

const cities = ref([])
const roles = ref([{title: 'Сотрудник', value: 'employee'}, {title: 'Работодатель', value: 'employer'}])
const fetched = ref(false)

onMounted(() => {


  const citiesRequest = fetch.anonRequest('/cities/').then((data) => {
    cities.value = data.results.map(item => ({
      title: item,
      value: item,
    }))
  })

  Promise.all([citiesRequest].filter(Boolean)).then(() => {

    fetched.value = true;
  });
})

const save = () => {
  fetch.authRequest('/me/', {
    method: 'PUT',
    body: {
      ...authStore.me,
    }
  })
    .then(response => {

    })
}

</script>

<style scoped>
.wj-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.form-input {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;

  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 15px;

  height: 42px;


}

.form-label {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4A4A4A;
  margin-bottom: 20px;
}


.form-sublabel {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  leading-trim: both;
  text-edge: cap;
  letter-spacing: 0.04em;

  color: #888888;

  margin-bottom: 20px;


}

.title {
  /* Создание вакансии */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #4A4A4A;


}
</style>