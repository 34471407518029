<template>
    <button :class="computedClasses">
        <slot></slot>
        <div class="absolute right-[1.06rem]">
            <Icon v-if="props.type === 'contacts-locked'" name="lock"></Icon>
        </div>
        <div class="flex items-center justify-center">
            <Icon v-if="props.type === 'telegram'" name="telegram"></Icon>
            <Icon v-if="props.type === 'whatsapp'" name="whatsapp"></Icon>
        </div>
        <div :class="computedClassesShadow"></div>
    </button>
    
</template>

<script setup>
import { computed } from 'vue';
import { defineProps } from 'vue';
import Icon from "@/components/ui/Icon";

// Определяем пропсы компонента
const props = defineProps({
    type: {
        type: String,
        default: 'search'
    },
    class: {
      type: String,
      default: ''
    },
});

const computedClassesShadow = computed(() => {
    if (props.type === 'search') {
        return 'absolute inset-0 bg-opacity-0 bg-black overflow-hidden rounded-[1rem] hover:bg-opacity-20'
    }
    else if (props.type === 'login') {
        return 'absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[1rem] md:rounded-[15px] hover:bg-opacity-20'
    }
    else if (props.type === 'create-resume') {
        return 'absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[1rem] md:rounded-[15px] hover:bg-opacity-20'
    }
    else if (props.type === 'all-vacansys') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-r-[1rem] hover:bg-opacity-20"
    }
    else if (props.type == 'signup') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[0.94rem] md:rounded-[10px] hover:bg-opacity-20"
    }
    // else if (props.type == 'contacts-locked') {
    //     return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[0.94rem] md:rounded-[15px] hover:bg-opacity-20"
    // }
    else if (props.type == 'send-welcome-1') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[0.94rem] md:rounded-[15px] hover:bg-opacity-20"
    }
    else if (props.type == 'send-welcome-2') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[0.94rem] hover:bg-opacity-20"
    }
    else if (props.type === 'contacts-unlocked') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[0.94rem] md:rounded-[15px] hover:bg-opacity-20"
    }
    if (props.type === 'get-contacts') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[0.94rem] md:rounded-[15px] hover:bg-opacity-20"
    }
    else if (props.type === 'further') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[0.31rem] hover:bg-opacity-20"
    }
    else if (props.type === 'create-resume-small') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden hover:bg-opacity-20"
    }
    else if (props.type === 'partnership') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden hover:bg-opacity-20"
    }
    else if (props.type === 'oferta') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden hover:bg-opacity-20"
    }
    else if (props.type === 'search-short') {
        return 'absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[0.94rem] md:rounded-[15px] hover:bg-opacity-20'
    }
    else if (props.type === 'get-pdf') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden hover:bg-opacity-20"
    }
    else if (props.type === 'reset') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden hover:bg-opacity-20"
    }
    else if (props.type === 'fullresume-afterlogin') {
        return "absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[1.25rem] hover:bg-opacity-20"
    }
    else if (props.type === 'login-logincard') {
        return 'absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[0.94rem] md:rounded-[15px] hover:bg-opacity-20'
    }
    else if (props.type === 'signup-logincard') {
        return 'absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[1rem] md:rounded-[15px] hover:bg-opacity-20'
    }
    else if (props.type === 'telegram') {
        return 'absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[1rem] md:rounded-[15px] hover:bg-opacity-20'
    }
    else if (props.type === 'whatsapp') {
        return 'absolute inset-0 bg-black bg-opacity-0 overflow-hidden rounded-[1rem] md:rounded-[15px] hover:bg-opacity-20'
    }
    else {
        return ``
    }
});

// Генерируем классы на основе пропсов и стандартного набора классов
const computedClasses = computed(() => {
    if (props.type === 'search') {
        return 'relative bg-[#00C8EF] text-[#FFFFFF] px-[3.03rem] py-[1.06rem] rounded-[1rem] flex items-center justify-center  font-inter text-base font-normal ' + props.class
    }
    else if (props.type === 'login') {
        return 'relative bg-[#FFFFFF] text-[#060C90] py-[0.74rem] px-[1.25rem] md:py-0 md:px-0  border rounded-[1rem] md:rounded-[15px] flex items-center justify-center font-inter text-[0.75rem] font-normal w-full ' + props.class
    }
    else if (props.type === 'create-resume') {
        return 'relative bg-[#FFFFFF] text-[#454545] py-[0.81rem] px-[2rem] md:py-0 md:px-0 md:rounded-[15px] rounded-[1rem] flex items-center justify-center font-inter font-normal text-[1.12rem] w-full ' + props.class
    }
    else if (props.type === 'all-vacansys') {
        return "relative bg-[#FFFFFF] text-[#3EC9E6] py-[1.06rem] px-[1.19rem] rounded-r-[1rem] flex items-center justify-center font-inter font-medium text-[1rem] border " + props.class
    }
    else if (props.type == 'signup') {
        return "relative bg-[#FFFFFF] text-[#0194DE] py-[0.91rem] px-[8.06rem] md:py-0 md:px-0 rounded-[0.94rem] md:rounded-[15px] md:text-[22px] flex items-center justify-center font-inter font-medium text-[1.45rem] border-[0.1rem] md:border-[1.5px] border-[#0194DE] w-full " + props.class
    }
    else if (props.type == 'contacts-locked') {
        return "relative bg-[#8A8A8A] text-[#BBBBBB] py-[0.91rem] px-[1.06rem] md:py-0 md:px-0 md:rounded-[15px] rounded-[0.94rem] flex items-center justify-center font-inter font-regular text-[1rem] w-full " + props.class
    }
    else if (props.type == 'send-welcome-1') {
        return "relative bg-[#00C8EF] text-[#FFFFFF] py-[1.06rem] px-[1rem] md:py-0 md:px-0 md:rounded-[15px] rounded-[0.94rem] flex items-center justify-center font-inter font-normal text-[1rem] md:text-[16px] w-full " + props.class
    }
    else if (props.type == 'send-welcome-2') {
        return "relative bg-[#FFFFFF] text-[#00C8EF] py-[0.91rem] px-[1.91rem] rounded-[0.94rem] h-[2.71rem] flex items-center justify-center font-inter font-normal text-[1.13rem] border border-[#00C8EF] " + props.class
    }
    else if (props.type === 'contacts-unlocked') {
        return "relative bg-[#00BD35] text-[#FFFFFF] py-[0.91rem] rounded-[0.91rem] md:py-0 md:px-0 md:rounded-[15px] flex items-center justify-center font-inter font-normal md:text-[16px] text-[1rem] w-full " + props.class
    }
    if (props.type === 'get-contacts') {
        return "relative bg-[#FFFFFF] text-[#00C8EF] py-[0.91rem] rounded-[0.94rem] md:py-0 md:px-0 md:rounded-[15px] flex items-center justify-center font-inter font-medium text-[0.86rem] md:text-[16px] border border-[#00C8EF] w-full " + props.class
    }
    else if (props.type === 'further') {
        return "relative bg-[#FFFFFF] text-[#B7B7B7] py-[0.56rem] px-[1.19rem] rounded-[0.31rem] flex items-center justify-center font-inter font-normal text-[0.88rem] border border-[#B7B7B7] " + props.class
    }
    else if (props.type === 'create-resume-small') {
        return "relative text-[#FFFFFF] text-[0.85rem] font-inter font-normal custom-underline " + props.class
    }
    else if (props.type === 'partnership') {
        return "relative text-[#FFFFFF] text-[0.85rem] font-inter font-normal " + props.class
    }
    else if (props.type === 'oferta') {
        return "relative text-[#B7B7B7] text-[0.75rem] font-inter font-extralight " + props.class
    }
    if (props.type === 'search-short') {
        return 'relative bg-[#00C8EF] text-[#FFFFFF] md:py-0 md:px-0 md:rounded-[15px] md:text-[16px] px-[1rem] py-[0.65rem] rounded-[1rem] flex items-center justify-center  font-inter text-base font-normal ' + props.class
    }
    else if (props.type === 'get-pdf') {
        return "relative text-[#868686] text-[0.87rem] md:text-[14px] font-inter font-light custom-underline " + props.class
    }
    else if (props.type === 'reset') {
        return "relative text-[#0194DE] text-[0.75rem] font-inter font-normal " + props.class
    }
    else if (props.type === 'fullresume-afterlogin') {
        return "relative bg-[#3D7CD1] text-[#FFFFFF] py-[0.91rem] px-[0.87rem] rounded-[1.25rem] flex items-center justify-center font-inter leading-[0.92rem] font-light text-[0.86rem] w-full " + props.class
    }
    else if (props.type === 'login-logincard') {
        return 'relative bg-[#2894FF] text-[#FFFFFF] h-[2.7rem] md:h-[42px] rounded-[1rem] md:rounded-[15px] flex items-center justify-center font-inter text-[1rem] md:text-[14px] font-normal w-full ' + props.class
    }
    else if (props.type === 'signup-logincard') {
        return 'relative bg-[#FFFFFFF] text-[#2894FF] h-[2.7rem] md:h-[42px] rounded-[1rem] md:rounded-[15px] flex items-center justify-center font-inter text-[1rem] md:text-[14px] font-normal border-[0.1rem] md:border-[1.5px] border-[#2894FF] w-full ' + props.class
    }
    else if (props.type === 'whatsapp') {
        return 'relative bg-[#CFD8DC] h-[2.7rem] md:h-[42px] rounded-[1rem] md:rounded-[15px] flex items-center justify-center w-full ' + props.class
    }
    else if (props.type === 'telegram') {
        return 'relative bg-[#CFD8DC] h-[2.7rem] md:h-[42px] rounded-[1rem] md:rounded-[15px] flex items-center justify-center w-full ' + props.class
    }
    else {
        return ``
    }
});
</script>

<style scoped>
/* Можете добавить стили, если это необходимо */
/* .font-normal {
    font-weight: 400;
  } */
  .hover-darken:hover {
  background-color: rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s;
}
</style>