<template>
    <Sheet class="!p-[2.56rem_1.62rem_1.62rem_1.62rem] !bg-[#3D7CD1] md:!p-[45px_61px_29px_61px] md:!rounded-[20px] flex flex-col items-center md:items-start">
        <span class="text-[#FFFFFF] text-[1.37rem] leading-[1.68rem] md:text-[36px] md:leading-[37px] font-[Inter] font-bold mb-[0.93rem] md:mb-[35px]">Начните искать работу мечты</span>
        <div class="flex flex-row w-full" @keyup.enter="handleEnter">
            <Input v-model="profession" class="mb-[0.93rem] !h-[3.31rem] md:!h-[51px] md:mb-[12px] md:!w-[671px] md:mr-[24px]" type="type1" placeholder="Профессия, должность или компания"></Input>
            <router-link v-if="!isMobile" :to="{ path: '/employee/vacancies', query: { profession: profession } }">
                <Button type="send-welcome-1"  class="!w-[169px] !h-[51px] md:mb-[12px]">Найти</Button>
            </router-link>
            
        </div>
        <router-link :to="{name: 'CreateResumePage'}">
            <div class="text-[#FFFFFF] text-[0.87rem] leading-[1.06rem] md:text-[14px] md:leading-[19px] font-[Inter] font-light custom-underline cursor-pointer">Создать резюме</div>
        </router-link>
        
    </Sheet>
</template>
 

<script setup>
import Sheet from './Sheet.vue';
import Input from './../../components/ui/Input.vue';
import { defineProps, defineEmits, computed, onMounted, onUnmounted, ref } from 'vue';
import Button from './../../components/ui/Button.vue';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

const route = useRoute();
const router = useRouter();

const isMobile = ref(window.innerWidth <= 768);
const profession = ref('')

const handleEnter = () => {
  console.log('HADLE ENTER!!!!')
  router.push(`/employee/vacancies?profession=${profession.value}`)
};

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});
</script>

<style scoped>
</style>

