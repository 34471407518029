<template>
  <Sheet
    class="pt-[1.75rem] pl-[2rem] pr-[2rem] pb-[1.75rem] md:pt-[28px] md:pl-[17px] md:pb-[28px] md:pr-[17px]  flex flex-col items-left">
    <div class="font-inter font-medium text-[0.87rem] mb-[0.71rem] md:text-[14px] md:mb-[10px]">
      Восстановление пароля
    </div>
    <div v-if="success === true">
      <div
        class="pt-[0.5rem] pb-[0.5rem] pr-[1rem] pl-[1rem] md:pt-[7px] md:pb-[7px] md:pl-[14px] md:pr-[14px] mb-[1.21rem] md:mb-[17px] rounded-[0.7rem] md:rounded-[9px] bg-[#E0FFE4] border border-[#80BA87]">
        <div class="font-inter font-extralight text-[0.71rem] text-[#80BA87] text-left md:text-[10px]">
          На указанный адрес электронной почты отправлено письмо с ссылкой для восстановления пароля. Пожалуйста,
          перейдите по указанной в письме ссылке.
        </div>
      </div>
      <router-link to="/login" class="flex flex-row items-center cursor-pointer">
        <Icon name="chevron-left-blue-small"></Icon>
        <span
          class="text-[0.85rem] md:text-[12px] text-[#2894FF] font-[Inter] font-medium ml-[0.7rem] md:ml-[10px]">Назад
          ко входу</span>
      </router-link>
    </div>
    <div v-else>
      <div
        class="font-inter font-extralight text-[0.71rem] text-[#B7B7B7] text-left mb-[0.71rem] md:text-[10px] md:mb-[10px]">
        Введите адрес электронной почты, который был использован во время регистрации. На него придет письмо с
        инструкцией
        по сбросу пароля.
      </div>
      <Input type="type4-login" placeholder="Электронная почта" class="mb-[0.3rem] md:mb-[5px]"
        v-model="inputEmail"></Input>
      <div v-if="allert !== null"
        class="font-[Inter] text-[0.73rem] md:text-[10px] text-[#FF0000] w-full text-left mt-[0.3rem] md:mt-[4px]">{{
      allert }}</div>
      <div v-else class="w-full mt-[1.03rem] md:mt-[14px]"></div>
      <Button @click="recover" type="login-logincard"
        class="mb-[0.71rem] md:mb-[10px] mt-[1rem] md:mt-[13px]">Восстановить</Button>
    </div>

  </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import Button from './../../components/ui/Button.vue';
import Input from './../../components/ui/Input.vue';
import { defineProps, defineEmits, computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useFetch } from '@/composables/useFetch';

const { router } = useRouter();

const { anonRequest, authRequest } = useFetch();

const inputEmail = ref('')
const success = ref(false);

const allert = ref(null)

const recover = () => {
  if (isValidEmail(inputEmail.value)) {
    inputEmail.value = inputEmail.value.trim()
    fetchEmailCheck().then(result => {
      if (result === false) {
        allert.value = 'Данный email не зарегистрирован в системе.'
      } else {
        console.log('else block!!!!!!!')
        allert.value = null
        success.value = true
      }
    })
  } else {
    allert.value = 'Неверно введен адрес. Пожалуйста, попробуйте снова'
  }
};

const fetchEmailCheck = async () => {
  const url = `/email_check/`;
  const responseBody = {
    "email": inputEmail.value,
  }

  const response = await anonRequest(url, {
    method: 'POST',
    body: responseBody
  });
  return response

};


const isValidEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  console.log('enail is', email)
  return emailPattern.test(email);
}


</script>

<style scoped></style>