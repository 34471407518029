<template>
  <div class="tabs">
    <div class="tab-headers bg-white select-none">
      <div class="flex flex row">
        <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="['tab-header', { active: isActiveTab(index) }]"
        @click="activateTab(index)"
        >
        {{ tab.label }}
        </div>
      </div>
      <router-link to="/employee/vacancies">
        <div v-if="!isMobile" class="tab-header-last">
        {{ props.lastLink }}
        </div>
      </router-link>
      
    </div>
    <div v-if="(modelValue != null) && (modelValue >= 0) && (modelValue < tabs.length) && (tabs[modelValue].name)" class="tab-content">
      <slot :name="tabs[modelValue].name"></slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { defineProps, defineEmits, onMounted, onUnmounted, ref } from 'vue';

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
    default: () => [],
  },
  modelValue: {
    type: Number,
    required: true,
    default: 0,
  },
  lastLink :{
    type: Text,
    required: false,
    default: '',
  }
});

const emit = defineEmits(['update:modelValue']);

const isActiveTab = (index) => {
  return props.modelValue === index;
};

const activateTab = (index) => {
  emit('update:modelValue', index);
};
</script>

<style>
.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 769px) {
  .tab-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
    scrollbar-width: none; /* For Firefox */
    margin-bottom: 0px;
    height: 53px;
    border: 0px solid transparent; /* бесцветная граница */
    border-radius: 15px; /* радиус скругления */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05), 0 -5px 15px rgba(0, 0, 0, 0.05);
    /* height: 53px; */
    overflow: visible;
    z-index: 5;
    padding-left: 19.5px;
  }
}

@media (max-width: 768px) {
  .tab-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
    scrollbar-width: none; /* For Firefox */
  }
}


.tab-headers::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}


@media (max-width: 768px) {
  .tab-header {
    padding: 1rem 2.2rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    color: #3EC9E6;
    cursor: pointer;
    flex-shrink: 0;
  }
}

@media (min-width: 769px) {
  .tab-header {
    margin-right: 57px;
    font-size: 16px;
    padding-top: 15px;
    color: #4A4A4A;
    cursor: pointer;
    flex-shrink: 0;
  }
}

@media (max-width: 768px) {
  .tab-header.active {
    color: #4A4A4A;
    border-bottom: 3px solid #3EC9E6;
  }
}

@media (min-width: 769px) {
  .tab-header.active {
    color: #4A4A4A;

    border-bottom: 5px solid #3EC9E6;
  }
}

.tab-header-last {
  font-size: 16px;
    padding-top: 15px;
    padding-bottom: 17px;
    padding-right: 19px;
    flex-shrink: 0;
    color: #3EC9E6;
    font-size: medium;
    cursor: pointer;
    flex-shrink: 0;
  }

.tab-content {
  /* display: flex;
  flex-direction: row; */
}
</style>
