<template>
  <div :class=standardClass class="max-h-full h-min">
    <div v-if="isMobile"
      class="flex flex-row bg-[#0126A9] h-[3.75rem] pr-[1.5rem] pl-[1.5rem] justify-between items-center">
      <router-link to="/">
        <img src="./../../assets/images/logo.webp" class="w-[6.56rem] h-[2.68rem] object-cover" />
      </router-link>
      <button @click="toggleMenu" class="flex items-center justify-center w-[1.5rem] h-[1.5rem] p-0">
        <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 12H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3 6H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M3 18H21" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    </div>
    <div v-else class="flex flex-row bg-[#0126A9] h-[80px] justify-center items-center">
      <div class="flex flex-row justify-between items-center w-[990px] mr-[15px]">
        <router-link to="/">
          <img src="./../../assets/images/logo.webp" class="w-[105px] h-[43px] object-cover" />
        </router-link>

        <div class="flex flex-row">
          <router-link to="/employee">
            <div class="text-[14px] text-[#FFFFFF] font-[Inter] font-normal mr-[20px] cursor-pointer">
              Соискателям
            </div>
          </router-link>

          <router-link to="/employer">
            <div class="text-[14px] text-[#FFFFFF] font-[Inter] font-normal mr-[20px] cursor-pointer">
              Работодателям
            </div>
          </router-link>

                    <div class="text-[14px] text-[#FFFFFF] font-[Inter] font-normal mr-[20px] cursor-pointer ">
                      Студентам
                    </div>
                    <div class="text-[14px] text-[#FFFFFF] font-[Inter] font-normal cursor-pointer">
                      Обучающая платформа
                    </div>
        </div>

        <div v-if="$authStore.isAuthenticated" class="flex flex-row gap-[10px]">
          <div v-if="$authStore.role === 'employee'">
            <router-link :to="{ name: 'EmployeePersonalPage' }">
              <Button type="login"
                class="!h-[26px] !px-5  w-max whitespace-nowrap !text-[12px] !font-[Inter] !font-normal">
                Личный кабинет
              </Button>
            </router-link>
          </div>
          <div v-if="$authStore.role === 'employer'">
            <router-link :to="{ name: 'EmployerVacanciesPage' }">
              <Button type="login"
                class="!h-[26px] !px-5  w-max whitespace-nowrap !text-[12px] !font-[Inter] !font-normal">
                Личный кабинет
              </Button>
            </router-link>
          </div>

        </div>
        <div v-else class="flex flex-row">


          <router-link to="/login" class="h-[80px] flex flex-col justify-center">
            <Button type="login" class="!h-[26px] !w-[76px] !text-[12px] !font-[Inter] !font-normal">
              Войти
            </Button>
          </router-link>
        </div>
      </div>


    </div>
    <div v-if="menu" class="flex flex-row">
      <div
        class="flex flex-col justify-between bg-[#0126A9] w-[25.7rem] h-[100vh] pt-[8.31rem] pb-[2.5rem] pl-[1.5rem] pr-[1.12rem]">
        <div class="flex flex-col">
          <router-link to="/employee" class="text-[0.87rem] text-[#FFFFFF] font-[Inter] font-normal mb-[2.5rem]">
            Соискателям
          </router-link>
          <router-link to="/employer"
            class="text-[0.87rem] text-[#FFFFFF] font-[Inter] font-normal mb-[2.5rem]">Работодателям
          </router-link>
<!--          <span class="text-[0.87rem] text-[#FFFFFF] font-[Inter] font-normal mb-[2.5rem]">Студентам</span>-->
<!--          <span class="text-[0.87rem] text-[#FFFFFF] font-[Inter] font-normal mb-[13rem]">Обучающая-->
<!--            платформа-->
<!--          </span>-->
        </div>
        <div v-if="$authStore.isAuthenticated"
          class="absolute bottom-[2.5rem] flex flex-col gap-[10px] max-w-[7.19rem]">
          <div v-if="$authStore.role === 'employee'">
            <router-link :to="{ name: 'EmployeePersonalPage' }">
              <Button type="login"
                class="!h-[26px] !px-5  w-max whitespace-nowrap !text-[12px] !font-[Inter] !font-normal">
                Личный кабинет
              </Button>
            </router-link>
          </div>
          <div v-if="$authStore.role === 'employer'">
            <router-link :to="{ name: 'EmployerVacanciesPage' }">
              <Button type="login"
                class="!h-[26px] !px-5  w-max whitespace-nowrap !text-[12px] !font-[Inter] !font-normal">
                Личный кабинет
              </Button>
            </router-link>
          </div>
        </div>
        <div v-else class="max-w-[7.19rem] mb-[2.5rem]">
          <Button type="login" @click="router.push('/login')">Войти</Button>
        </div>


      </div>
      <div @click="toggleMenu" class="w-full h-[100vh]" style="backdrop-filter: blur(10px);">
      </div>
    </div>


  </div>

</template>

<script setup>
import { computed } from 'vue';
import { defineProps, defineEmits, ref, watch, onUnmounted, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import Button from "@/components/ui/Button";
import { useAuthStore } from "@/stores/authStore";

const authStore = useAuthStore()

const router = useRouter()
const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});

const standardClass = "absolute top-0 w-full z-10 mb-15 ";
const props = defineProps({
  class: {
    type: String,
    default: ''
  },
});

const computedClasses = computed(() => {
  return standardClass + props.class;
});

const menu = ref(false);
const emit = defineEmits(['toggleScroll']);
const toggleMenu = () => {
  // emit('toggleScroll')
  menu.value = !menu.value;
  if (menu.value) {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
  }
  else {
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto';
  }

};

</script>

<style scoped></style>