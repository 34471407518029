import {createRouter, createWebHistory} from 'vue-router';
import Test from "@/pages/Test"
import Cards from "@/pages/Cards"
import Test2 from "@/pages/Test2"
import IndexPage from "@/pages/IndexPage"
import EmployerMainPage from "@/pages/EmployerMainPage"
import EmployerResumesPage from "@/pages/EmployerResumesPage"
import EmployerResumePage from "@/pages/EmployerResumePage"
import EmployeeMainPage from "@/pages/EmployeeMainPage"
import EmployeeVacanciesPage from "@/pages/EmployeeVacanciesPage"
import EmployeeVacancyPage from "@/pages/EmployeeVacancyPage"
import LoginPage from "@/pages/LoginPage"
import RecoverPassowordPage from "@/pages/RecoverPasswordPage"
import RegistrationPage from "@/pages/RegistrationPage"
import ResetPasswordPage from "@/pages/ResetPasswordPage"
import CreateVacancyPage from "@/pages/employer/CreateVacancyPage.vue";
import CreateResumePage from "@/pages/employee/CreateResumePage.vue";
import EmployeePersonalPage from '@/pages/employee/EmployeePersonalPage.vue';
import EmployeeResumesPage from '@/pages/employee/EmployeeResumesPage.vue';
import EmployerVacanciesPage from '@/pages/employer/EmployerVacanciesPage.vue';
import ModerationVacanciesPage from '@/pages/ModerationVacanciesPage.vue';
import EmployerPersonalPage from '@/pages/employer/EmployerPersonalPage.vue';
import EmployerCompanyPage from '@/pages/employer/EmployerCompanyPage.vue';
import EmployerDoInvationPage from '@/pages/employer/EmployerDoInvationPage.vue';
import InvationsPage from '@/pages/InvationsPage.vue';
import InvationPage from '@/pages/InvationPage.vue';
import { useAuthStore } from '@/stores/authStore';
import EmployeeDoResponsePage from '@/pages/employee/EmployeeDoResponsePage.vue';
import ResponsesPage from '@/pages/ResponsesPage.vue';
import ResponsePage from '@/pages/ResponsePage.vue';
const routes = [
    {
        path: '/',
        component: IndexPage,
        meta: { title: 'Биржа водных вакансий' }
    },
    {
        path: '/employee/lk/resumes/create',
        name: 'CreateResumePage',
        component: CreateResumePage,
        meta: { title: 'Создание резюме' }
    },
    
    {
        path: '/employee/lk/resumes/:id(\\d+)',
        name: 'UpdateResumePage',
        component: CreateResumePage,
        meta: { title: 'Редактирование резюме' }
    },
    {
        path: '/employee/lk/personal',
        name: 'EmployeePersonalPage',
        component: EmployeePersonalPage,
        meta: { title: 'Личный кабинет работника' }
    },
    {
        path: '/employee/lk/resumes',
        name: 'EmployeeResumesPage',
        component: EmployeeResumesPage,
        meta: { title: 'Список резюме работника' }
    },
    {
        path: '/employee/lk/feedbacks/invations',
        name: 'EmployeeFeedbackInvationsPage',
        component: InvationsPage,
        meta: { title: 'Страница с приглашениями для работника' }
    },
    {
        path: '/employer/lk/feedbacks/invations',
        name: 'EmployerFeedbackInvationsPage',
        component: InvationsPage,
        meta: { title: 'Страница с приглашениями для работодателя' }
    },
    {
        path: '/employee/lk/feedbacks/responses',
        name: 'EmployeeResponsesPage',
        component: ResponsesPage,
        meta: { title: 'Страница с откликами для работника' }
    },
    {
        path: '/employer/lk/feedbacks/responses',
        name: 'EmployerResponsesPage',
        component: ResponsesPage,
        meta: { title: 'Страница с откликами для работодателя' }
    },
    {
        path: '/employer/lk/feedbacks/responses/:id(\\d+)',
        name: 'ResponsePageEmployer',
        component: ResponsePage,
        meta: { title: 'ResponsePageEmployer' }
    },
    {
        path: '/employee/lk/feedbacks/responses/:id(\\d+)',
        name: 'ResponsePageEmployee',
        component: ResponsePage,
        meta: { title: 'ResponsePageEmployee' }
    },
    {
        path: '/employer/lk/personal',
        name: 'EmployerPersonalPage',
        component: EmployerPersonalPage,
        meta: { title: 'Персональные данные работодателя' }
    },
    {
        path: '/employer/lk/company',
        name: 'EmployerCompanyPage',
        component: EmployerCompanyPage,
        meta: { title: 'Данные компании работодателя' }
    },
    {
        path: '/employer/lk/vacancies',
        name: 'EmployerVacanciesPage',
        component: EmployerVacanciesPage,
        meta: { title: 'Список вакансий работодателя' }
    },
    {
        path: '/employer/lk/vacancies/create',
        name: 'CreateVacancyPage',
        component: CreateVacancyPage,
        meta: { title: 'Создание вакансии' }
    },
    {
        path: '/employer/lk/vacancies/:id(\\d+)',
        name: 'UpdateVacancyPage',
        component: CreateVacancyPage,
        meta: { title: 'Редактирование вакансии' }
    },
    {
        path: '/employer/do_invation',
        name: 'EmployerDoInvationPage',
        component: EmployerDoInvationPage,
        meta: { title: 'Приглашения' }
    },
    {
        path: '/employee/do_response',
        name: 'EmployeeDoResponsePage',
        component: EmployeeDoResponsePage,
        meta: { title: 'Приглашения' }
    },
    
    {
        path: '/employer/lk/feedbacks/invations/:id(\\d+)',
        name: 'InvationPageEmployer',
        component: InvationPage,
        meta: { title: 'Приглашения' }
    },
    {
        path: '/employee/lk/feedbacks/invations/:id(\\d+)',
        name: 'InvationPageEmployee',
        component: InvationPage,
        meta: { title: 'Приглашения' }
    },
    {
        path: '/employee',
        component: EmployeeMainPage,
        meta: { title: 'Соискателям' }
    },
    {
        path: '/employee/vacancies',
        component: EmployeeVacanciesPage,
        meta: { title: 'Вакансии' }
    },
    {
        path: '/employee/vacancies/:id(\\d+)',
        component: EmployeeVacancyPage,
        meta: { title: 'Вакансия' },
        props: true
    },
    {
        path: '/employer',
        component: EmployerMainPage,
        meta: { title: 'Работодателям' }
    },
    {
        path: '/employer/resumes',
        component: EmployerResumesPage,
        meta: { title: 'Резюме' }
    },
    {
        path: '/employer/resumes/:id(\\d+)',
        component: EmployerResumePage,
        meta: { title: 'Резюме' },
        props: true
    },
    {
        path: '/moderation/vacancies',
        name: 'ModerationVacanciesPage',
        component: ModerationVacanciesPage,
        meta: { title: 'Модерация вакансий' }
    },
    {
        path: '/ui',
        component: Test,
        meta: { title: 'Компоненты' }
    },
    {
        path: '/cards',
        component: Cards,
        meta: { title: 'Карточки' }
    },
    {
        path: '/tt',
        component: Test2,
        meta: { title: 'Карточки' }
    },
    {
        path: '/login',
        component: LoginPage,
        meta: { title: 'Вход' }
    },
    {
        path: '/recover',
        component: RecoverPassowordPage,
        meta: { title: 'Восстановление пароля'}
    },
    {
        path: '/signup',
        component: RegistrationPage,
        meta: { title: 'Регистрация'}
    },
    {
        path: '/reset_password',
        component: ResetPasswordPage,
        meta: { title: 'Установка нового пароля'}
    }

]
const router = createRouter({
    history: createWebHistory(),
    routes,
})


// Устанавливаем заголовок при каждом переходе
router.afterEach((to) => {
    document.title = to.meta.title || 'Умолчание';
});


router.beforeEach((to, from) => {
    const authStore = useAuthStore();
    authStore.checkIsAuthenticated(); // Вызов метода при смене маршрута
});

export default router