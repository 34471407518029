<template>
    <div @click="closePopup" class="z-10 absolute top-0 left-0 bg-[#0126A9] h-[1000px] w-screen opacity-20">

    </div>
    <div :class="computedClasses">
        <div class="flex flex-row justify-between align center h-[1.5rem] mb-[1.93rem]">
            <button class="flex items-center justify-center p-0" @click="closePopup">
                <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6 6L18 18" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
            <span class="text-[1.12rem] text-[#000000] mb-[0.87rem] font-[Inter] font-bold">{{ title }}</span>
            <Button type="reset" @click="resetFilters">Сбросить</Button>
        </div>
        <div class="overflow-y-auto max-h-[70vh]">
            <slot></slot>
        </div>
        
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { defineProps, defineEmits } from 'vue';
import Button from "@/components/ui/Button";

const standardClass = "fixed bottom-0 bg-[#FFFFFF] rounded-t-[1rem] p-[1rem] z-10 w-screen max-h-[80vh]";
const props = defineProps({
    class: {
        type: String,
        default: ''
    },
    reset: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        default: ''
    },
});

const computedClasses = computed(() => {
    return standardClass + props.class;
});
const emit = defineEmits(['closePopup', 'resetFilters']);
const resetFilters = () => {
  emit('resetFilters'); // Эмитим событие сброса фильтров
};
const closePopup = () => {
  emit('closePopup');
};

</script>

<style scoped></style>