<!-- RadioButton.vue -->
<template>
  <label class="radio-label my-[10px] select-none">
<!--    {{ localValue }} {{ value }}-->
    <input
      :checked="localValue === value"
      type="radio"
      :value="value"
      :name="name"
      @change="updateValue(value)"
    >

    <span class="radio-custom"></span>
    {{ label }}
  </label>
</template>

<script setup>
import {ref, defineEmits, defineProps} from 'vue';

const props = defineProps({
  value: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  modelValue: {
    type: String,
    required: true
  },
});

const emit = defineEmits(['update:modelValue']);

const localValue = ref(props.modelValue);

const updateValue = (value) => {
  emit('update:modelValue', value);
};
</script>

<style>
.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.radio-label[disabled] {
  opacity: 0.5;
  cursor: default;
}

.radio-label input {
  display: none;
}

.radio-custom {
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 1px solid #ECECEC;
  display: inline-block;
  margin-right: 8px;
}

.radio-label input:checked + .radio-custom {
  border: 9px solid #2894FF;
  box-shadow: 0 0 0 4px white;
}
</style>