<template>
    <div class="flex flex-col bg-[#F8F8F8]">
        <div class="mb-[3.75rem] md:mb-[80px]">
            <Header @toggleScroll="handleToggleScroll"></Header>
        </div>
        <CompanySignUpCardFull v-if="!$authStore.isAuthenticated"></CompanySignUpCardFull>
        <div class="flex flex-row justify-center">
          <WelcomeGetCandidateCard></WelcomeGetCandidateCard>
        </div>
        
        <Footer></Footer>
    </div>
</template>

<script setup>
import Sheet from './../components/cards/Sheet.vue';
import VacancyCard from './../components/cards/VacancyCard.vue';
import PreSignupCard from './../components/cards/PreSignupCard.vue'
import CompanySignupCard from './../components/cards/CompanySignupCard.vue'
import ResumeSearch from './../components/cards/ResumeSearch.vue'
import ResumeCard from './../components/cards/ResumeCard.vue'
import WelcomeGetJobCard from './../components/cards/WelcomeGetJobCard.vue'
import VacancyCardPartOpen from './../components/cards/VacancyCardPartOpen.vue'
import SimularResumeCard from './../components/cards/SimularResumeCard.vue'
import CompanyCard from './../components/cards/CompanyCard.vue'
import ResumeFullCard from './../components/cards/ResumeFullCard.vue'
import Popup from './../components/ui/Popup.vue'
import TestPopup from './../components/cards/TestPopup.vue'
import TestSortPopup from './../components/cards/TestSortPopup.vue'
import PopupFilterTest from './../components/cards/TestFilterPopup.vue'
import Header from './../components/ui/Header.vue'
import {ref, reactive, watch, onMounted, onUnmounted} from 'vue';
import Tabs from "@/components/ui/Tabs";
import CardsSlider from "@/components/ui/CardsSlider";
import Footer from './../components/ui/Footer.vue'
import ResumeNoLoginCard from './../components/cards/ResumeNoLoginCard.vue'
import WelcomeCard from './../components/cards/WelcomeCard.vue'
import CompanySignUpCardFull from './../components/cards/CompanySignUpCardFull.vue';
import WelcomeGetCandidateCard from './../components/cards/WelcomeGetCandidateCard.vue'

const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
});

const count = ref(0);
const isChecked = ref(false)
const isChecked2 = ref(false)
const isChecked3 = ref(false)
const gradeCompany = ref(3.9)
const experiences = ref([
  {
    time: '2018 - по н.в.',
    company: 'ООО Тепловые Сети',
    jobTitle: 'Старший инженер-геодезист',
    description: 'Производство работ по СНиП 111-4-80. Составление исполнительных схем, предоставление отчетов начальству. Работа в среде AutoCad, Word, Exсel. Работа с тахеометром, теодолитом, нивелиром.'
  },
  {
    time: '2016 - 2018',
    company: 'ООО МосСтрой',
    jobTitle: 'Главный инженер-геодезист',
    description: 'Работа в среде AutoCad, Word, Exсel. Работа с тахеометром, теодолитом, нивелиром.'
  },
  {
    time: '2002 - 2016',
    company: 'ООО МосСтрой',
    jobTitle: 'Инженер-геодезист',
    description: 'Производство работ по СНиП 111-4-80. Составление исполнительных схем, предоставление отчетов начальству. '
  },
]);
const educations = ref([
  {
    timeEnd: '2001',
    university: 'Российский Гидрометеорологический Университет',
    educationTitle: 'Инженер-эколог, магистр'
  },
  {
    timeEnd: '1998',
    university: 'Российский Государственный Авиационный Университет',
    educationTitle: 'Инженер-ппрограммист, бакалавр'
  },
]);

const showPopup = ref(false);

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
  showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest= () => {
  showPopupFilterTest.value = !showPopupFilterTest.value;
};

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});


const activeTab = ref(0);

</script>

<style>
</style>