<template>
  <LKLayout >
    <div class="wj-container md:w-[685px] md:min-h-[300px] px-[15px] py-[15px] md:px-[1rem] md:py-[1rem]">
      <div class="flex justify-between mb-[60px]">
        <div class="title">Мои приглашения</div>
      </div>
      <Tabs :tabs="tabs" v-model="activeTab">
        <template #tab1>
          <div class="mt-5"></div>
          <UINoData :data="invations.length" :loading="!fetched"></UINoData>

          <div class="inner-container" v-for="(item, index) in invations" :key="index">
            <InvationCard :item=item></InvationCard>
            <div class="mt-[20px]">
              <Button type="login-logincard" class="md:w-[362px]"
                      @click="router.push(`/${firstPart}/lk/feedbacks/invations/${item.id}?status=NEW`)">Посмотреть
              </Button>
            </div>
          </div>

        </template>

        <template #tab2>
          <div class="mt-5"></div>
          <UINoData :data="invations.length" :loading="!fetched"></UINoData>
          <div class="inner-container " v-for="(item, index) in invations" :key="index">
            <InvationCard :item=item></InvationCard>
            <div class="mt-[20px]">
              <Button type="login-logincard" class="md:w-[362px]"
                      @click="router.push(`/${firstPart}/lk/feedbacks/invations/${item.id}?status=ACCEPTED`)">Посмотреть
              </Button>
            </div>
          </div>
        </template>

        <template #tab3>
          <div class="mt-5"></div>
          <UINoData :data="invations.length" :loading="!fetched"></UINoData>
          <div class="inner-container" v-for="(item, index) in invations" :key="index">
            <InvationCard :item=item></InvationCard>
            <div class="mt-[20px]">
              <Button type="login-logincard" class="md:w-[362px]"
                      @click="router.push(`/${firstPart}/lk/feedbacks/invations/${item.id}?status=REJECTED`)">Посмотреть
              </Button>
            </div>
          </div>
        </template>

        <template #tab4>
          <div class="mt-5"></div>
          <UINoData :data="invations.length" :loading="!fetched"></UINoData>
          <div class="inner-container" v-for="(item, index) in invations" :key="index">
            <InvationCard :item=item></InvationCard>
            <div class="mt-[20px]">
              <Button type="login-logincard" class="md:w-[362px]"
                      @click="router.push(`/${firstPart}/lk/feedbacks/invations/${item.id}?status=ARCHIVE`)">Посмотреть
              </Button>
            </div>
          </div>
        </template>
      </Tabs>

      <div v-if="totalPages > 1" class="flex flex-row justify-start md:mt-[46px]">
        <Pagination v-model="page" :total="totalPages"></Pagination>
      </div>
    </div>
  </LKLayout>

</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import {onMounted, reactive, ref, computed, watch, onBeforeMount} from "vue";
import {useFetch} from "@/composables/useFetch";
import ResumeCard from "@/components/cards/ResumeCard.vue";
import InvationCard from "@/components/cards/InvationCard.vue";
import Tabs from "@/components/ui/Tabs";
import Pagination from "@/components/ui/Pagination";
import Button from "@/components/ui/Button";
import VacancyCardListitem from "@/components/cards/VacancyCardListitem"
import {
  formatPrice,
  formatDateTime,
  formatDateTimeVacancyCreated,
  formatexperienceForVacancy
} from '@/composables/utils';
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";

const fetch = useFetch()
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore()

const firstPart = route.path.split('/')[1];

const fetched = ref(false)

const tabs = [
  {name: "tab1", label: "Новые"},
  {name: "tab2", label: "Принятые"},
  {name: "tab3", label: "Отклоненные"},
  {name: "tab4", label: "Архив"},
];


import LKLayout from "@/layouts/LKLayout";
import {useAuthStore} from "@/stores/authStore";
import UINoData from "@/components/ui/UINoData";

const invations = ref([]);
const loading = ref(false);
const error = ref(null);
const itemsPerPage = ref(5)
const page = ref(1)
const totalCount = ref(1)

const activeTab = ref(0);

const offset = computed(() => {
  return (page.value - 1) * itemsPerPage.value
})

const totalPages = computed(() => {
  return Math.floor((totalCount.value - 1) / itemsPerPage.value) + 1
})


const getInvations = () => {
  invations.value = []
  fetched.value = false
  if (activeTab.value === 0) {
    page.value = 1
    fetchInvationsData({
      status: 'NEW'
    });
  } else if (activeTab.value === 1) {
    page.value = 1
    fetchInvationsData({
      status: 'ACCEPTED'
    });
  } else if (activeTab.value === 2) {
    page.value = 1
    fetchInvationsData({
      status: 'REJECTED'
    });
  } else if (activeTab.value === 3) {
    page.value = 1
    fetchInvationsData({
      status: 'ARCHIVE'
    });
  }
}

const fetchInvationsData = async (queryParams = {}) => {
  queryParams.limit = itemsPerPage.value
  queryParams.offset = offset.value

  const queryArray = [];
  for (const key in queryParams) {
    const value = queryParams[key];
    // Проверьте, является ли значение массивом
    if (Array.isArray(value)) {
      // Если массив, объедините значения через запятую
      queryArray.push(`${key}=${value.join(',')}`);
    } else {
      queryArray.push(`${key}=${value}`);
    }
  }
  let queryString = queryArray.join('&');
  queryString
  const url = `/invations/${queryString ? '?' + queryString : ''}`;
  const response = await fetch.authRequest(url, {
    method: 'GET',
  }).then((data) => {
    invations.value = data.results
    fetched.value = true
  })


};

onBeforeMount(() => {
  fetchInvationsData({status: 'NEW'}).then((data) => {
    fetched.value = true
  })
})

watch(activeTab, (newValue) => {
  getInvations()
});

watch(page, (newValue) => {
  getInvations()
});

</script>

<style scoped>
.wj-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.inner-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
}

.title {
  /* Создание вакансии */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #4A4A4A;


}

.form-label {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4A4A4A;
  margin-bottom: 20px;
}

.form-sublabel {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  leading-trim: both;
  text-edge: cap;
  letter-spacing: 0.04em;

  color: #888888;

  margin-bottom: 20px;


}
</style>