<template>
    <div :class=standardClass>
        <div class="flex flex-row justify-center h-full">
            <div
                class="flex flex-col bg-[#0126A9] pt-[2.93rem] pb-[3rem] md:pt-[30px] md:pb-[32px] justify-start md:justify-between md:items-center footer w-full"
            >

                <div class="flex flex-col md:flex-row md:justify-between md:w-[992px]">

                    <div class="flex flex-col md:flex-row">
                        <div class="flex flex-col md:mr-[137px]">
                            <router-link to="/employee">
                                <div
                                    class="text-[0.87rem] md:text-[14px] md:mb-[12px] text-[#FFFFFF] font-[Inter] font-normal mb-[0.93rem] cursor-pointer">
                                    Соискателям</div>
                            </router-link>

                            <router-link to="/employer">
                                <div
                                    class="text-[0.87rem] md:text-[14px] md:mb-[12px] text-[#FFFFFF] font-[Inter] font-normal mb-[0.93rem] cursor-pointer">
                                    Работодателям</div>
                            </router-link>

<!--                            <div-->
<!--                                class="text-[0.87rem] md:text-[14px] md:mb-[12px] text-[#FFFFFF] font-[Inter] font-normal mb-[0.93rem] cursor-pointer">-->
<!--                                Студентам-->
<!--                            </div>-->
<!--                            <div-->
<!--                                class="text-[0.87rem] md:text-[14px] md:mb-0 text-[#FFFFFF] font-[Inter] font-normal mb-[3.31rem] cursor-pointer">-->
<!--                                Обучающая платформа-->
<!--                            </div>-->
                        </div>

                        <div class="flex flex-col">
<!--                            <div-->
<!--                                class="text-[0.87rem] md:text-[14px] md:mb-[12px] text-[#FFFFFF] font-[Inter] font-normal mb-[0.93rem] cursor-pointer">-->
<!--                                Партнерская-->
<!--                                программа</div>-->
<!--                            <div-->
<!--                                class="text-[0.87rem] text-[#FFFFFF] md:text-[14px] md:mb-[12px] font-[Inter] font-normal mb-[0.93rem] cursor-pointer">-->
<!--                                О-->
<!--                                компании-->
<!--                            </div>-->
                            <router-link to="/employee/vacancies">
                                <div
                                    class="text-[0.87rem] text-[#FFFFFF] md:text-[14px] md:mb-0 font-[Inter] font-normal mb-[3.31rem] cursor-pointer">
                                    Все
                                    вакансии
                                </div>
                            </router-link>

                        </div>
                    </div>


                    <div v-if="!isMobile && $authStore.me" class="flex flex-col">
                        <router-link v-if="$authStore.me.role === 'employee'" :to="{name: 'CreateResumePage'}">
                            <Button type="send-welcome-1" class="!h-[54px] !w-[243px] !mb-[15px]">Создать резюме</Button>
                        </router-link>
                        <router-link v-if="$authStore.me.role === 'employer'" :to="{name: 'CreateVacancyPage'}">
                            <Button type="get-contacts" class="!h-[54px] !w-[243px] !text-[#454545] !border-none">Разместить
                                вакансию</Button>
                        </router-link>
                        
                    </div>
                </div>







                <div class="flex flex-col md:flex-row  md:w-[992px] md:items-end">
                    <router-link to="/">
                        <img v-if="!isMobile" src="./../../assets/images/logo.webp"
                        class="w-[105px] h-[43px] object-cover md:mr-[107px]">
                    </router-link>
                    
                    <div
                        class="text-[0.75rem] text-[#B7B7B7] font-[Inter] font-extralight mb-[0.75rem] md:mb-0 md:text-[12px]  cursor-pointer">
                        Юридическая информация</div>
                    <div
                        class="text-[0.75rem] text-[#B7B7B7] font-[Inter] font-extralight mb-[0.75rem] md:mb-0 md:text-[12px] md:ml-[100px] cursor-pointer">
                        Договор
                        оферты</div>
                    <div
                        class="text-[0.75rem] text-[#B7B7B7] font-[Inter] font-extralight mb-[3.68rem] md:mb-0 md:text-[12px] md:ml-[100px] cursor-pointer">
                        Политика
                        конфиденциальности</div>
                </div>

                <router-link to="/" v-if="isMobile">
                    <img src="./../../assets/images/logo.webp" class="w-[6.56rem] h-[2.68rem] object-cover">
                </router-link>
                
            </div>
        </div>

    </div>

</template>

<script setup>
import { computed } from 'vue';
import { defineProps, defineEmits, ref, onMounted, onUnmounted } from 'vue';
import Button from "@/components/ui/Button";

const standardClass = "w-full md:h-[300px] ";
const props = defineProps({
    class: {
        type: String,
        default: ''
    },
});

const computedClasses = computed(() => {
    return standardClass + props.class;
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});


</script>

<style scoped>
@media (min-width: 1800px) {
    .footer {
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media (max-width: 768px) {
    .footer {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
}
</style>