<template>
  <div>
    <input class="calendar-input" type="text" @click="toggleCalendar" :value="formattedDate" @change="updateDate"
           :disabled="disabled"/>
    <div v-if="isCalendarVisible" class="calendar px-[24px] py-[16px]">
      <div class="calendar-header flex justify-between w-full">
        <div>{{ monthNames[currentMonth] }} {{ currentYear }}</div>
        <div class="flex items-center ">
          <button class="p-2" @click="prevMonth">
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.82539 1.175L5.65039 0L0.650391 5L5.65039 10L6.82539 8.825L3.00872 5L6.82539 1.175Z"
                    fill="black" fill-opacity="0.6"/>
            </svg>
          </button>
          <button class="p-2" @click="nextMonth">
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.0002 0L0.825195 1.175L4.64186 5L0.825195 8.825L2.0002 10L7.0002 5L2.0002 0Z" fill="black"
                    fill-opacity="0.6"/>
            </svg>
          </button>
        </div>
      </div>
      <div class="weekdays w-full flex">
        <div class="weekday flex-grow" v-for="(day, index) in weekDays" :key="index">{{ day }}</div>
      </div>
      <div class="calendar-days w-full ">
        <div v-for="n in daysBefore" :key="`empty-${n}`" class="empty-day"></div>
        <div v-for="day in daysInMonth" :key="day" @click="selectDate(day)" class="calendar-day">
          {{ day }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, onMounted, nextTick} from 'vue';
import {defineProps, defineEmits} from 'vue';
import {useToast} from "vue-toast-notification";

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  disabled: {
    default: false,
  }
});

const emit = defineEmits(['update:modelValue']);

const isCalendarVisible = ref(false);
const currentDate = ref(new Date());
const currentMonth = ref(currentDate.value.getMonth());
const currentYear = ref(currentDate.value.getFullYear());

const toast = useToast()

const monthNames = [
  'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

const weekDays = ['П', 'В', 'С', 'Ч', 'П', 'С', 'В'];

const daysInMonth = computed(() => new Date(currentYear.value, currentMonth.value + 1, 0).getDate());

const firstDayOfMonth = computed(() => new Date(currentYear.value, currentMonth.value, 1).getDay() || 7);

const daysBefore = computed(() => {
  return (firstDayOfMonth.value + 6) % 7;
});

const toggleCalendar = () => {
  if (!props.disabled) {
    isCalendarVisible.value = !isCalendarVisible.value;

  }
};

const prevMonth = () => {
  currentMonth.value = (currentMonth.value - 1 + 12) % 12;
  currentYear.value -= currentMonth.value === 11 ? 1 : 0;
};

const nextMonth = () => {
  currentMonth.value = (currentMonth.value + 1) % 12;
  currentYear.value += currentMonth.value === 0 ? 1 : 0;
};

const selectDate = (day) => {
  console.log('DT: ', currentYear.value, currentMonth.value, day+1)

  const selectedDate = new Date(currentYear.value, currentMonth.value, day+1);
  console.log('DT: ', selectedDate)
  emit('update:modelValue', selectedDate.toISOString().split('T')[0]);
  isCalendarVisible.value = false;
};

const formattedDate = computed(() => {
  return props.modelValue ? new Date(props.modelValue).toLocaleDateString('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }) : '';
});
function parseDateString(dateString) {
  // Разделяем строку по точкам
  const parts = dateString.split(".");

  // Получаем день, месяц и год
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Месяцы в JavaScript начинаются с 0
  const year = parseInt(parts[2], 10);

  // Создаем объект Date
  return new Date(year, month, day);
}

function isValidDate(dateString) {
  // Регулярное выражение для проверки формата "ДД.ММ.ГГГГ"
  const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/;

  // Проверка соответствия формату
  if (!regex.test(dateString)) {
    return false;
  }

  // Разделяем дату на компоненты
  const [day, month, year] = dateString.split('.').map(Number);

  // Создаем объект даты
  const date = new Date(year, month - 1, day);

  // Проверяем, совпадают ли данные с переданными
  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}


onMounted(() => {
  const dt = new Date(props.modelValue)
  currentDate.value = dt;
  currentMonth.value = dt.getMonth()
  currentYear.value = dt.getFullYear()
})

const updateDate = (event) => {
  if (!isValidDate(event.target.value)) {
    toast.warning('Некорректная дата')
    selectDate(currentDate.value.getDay())
    nextTick(() => {
      emit('update:modelValue', currentDate.value.toISOString().split('T')[0]);

    })
    return
  }
  let selectedDate = parseDateString(event.target.value)

  selectedDate.setDate(selectedDate.getDate() + 1); // Добавляем 1 к месяцу
  currentDate.value = selectedDate;
  currentMonth.value = selectedDate.getMonth()
  currentYear.value = selectedDate.getFullYear()
  emit('update:modelValue', selectedDate.toISOString().split('T')[0]);
  isCalendarVisible.value = false;



};
</script>

<style>
.calendar {
  position: absolute;
  border: 1px solid #ccc;
  background: white;
  width: 200px;

  /* Desktop Date Birth */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: relative;
  width: 256px;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 4px;

}

.calendar-header {
  display: flex;
  justify-content: space-between;
  padding: 5px;


  /* Subtitle 2 */
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.1px;

  /* 00. On Surface / Medium Emphasis */
  color: rgba(0, 0, 0, 0.6);


}

.weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: bold;
}

.weekday {
  text-align: center;
  padding: 5px 0;


  /* Caption */
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  /* 00. On Surface / Medium Emphasis */
  color: rgba(0, 0, 0, 0.6);


}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.empty-day {
  padding: 10px;
}

.calendar-day {
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.calendar-day:hover {


  border: 2px solid #2894FF;
  border-radius: 5px;


}

.calendar-input {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;

  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 15px;

  height: 42px;
}

.calendar-input[disabled] {
  opacity: 0.5;
}
</style>
