import {defineStore} from 'pinia';
import {useFetch} from '@/composables/useFetch';

import {jwtDecode} from 'jwt-decode';

export const useVacanciesStore = defineStore('vacanciesStore', {
  state: () => ({
    item: null, // текущая вакансия
    items: [],
  }),
  actions: {
    setStatus(vacancyId, status) {
      const fetch = useFetch()
      return fetch.authRequest(`/vacancies/${vacancyId}/`, {
        method: 'PUT',
        body: {
          status,
        }
      })
    },
    clone(vacancyId) {
      const fetch = useFetch()
      return fetch.authRequest(`/vacancies/${vacancyId}/clone`, {
        method: 'POST',
      })
    }
  },
});