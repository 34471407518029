<template>
    <Popup :title="title" @closePopup="closePopup">
        <div class="flex flex-col">
          <div class="flex flex-row">
            <Checkbox></Checkbox><div class="ml-2">город</div>
          </div>
          <div class="flex flex-row">
            <Checkbox></Checkbox><div class="ml-2">город</div>
          </div> 
          <div class="flex flex-row">
            <Checkbox></Checkbox><div class="ml-2">город</div>
          </div>
          <div class="flex flex-row">
            <Checkbox></Checkbox><div class="ml-2">город</div>
          </div> 
          <div class="flex flex-row">
            <Checkbox></Checkbox><div class="ml-2">город</div>
          </div> 
        </div>

    </Popup>
</template>

<script setup>
import Checkbox from '../ui/Checkbox.vue';
import Popup from './../../components/ui/Popup.vue';
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: 'Фильтры',
  },
});

// Передача события для закрытия всплывающего окна
const emit = defineEmits(['closePopup']);
const closePopup = () => {
  emit('closePopup');
};
</script>

<style scoped></style>