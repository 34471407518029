<template>
    <Sheet class="!pt-[1.75rem] pl-[1.68rem] pb-[1.75rem] md:!pt-[28px] md:pl-[28px] md:pb-[28px] md:pr-[84px] cursor-pointer">
        <div class="flex justify-start md:leading-[16px] leading-[0.93rem] mb-[0.93rem] md:mb-[15px]">
            <span class="font-inter font-normal text-[0.75rem] md:text-[12px] text-[#00BD35]">{{ formatDateTimeResumeCreated(publicationTime) }}</span>
        </div>
        <div class="mb-[0.62rem] leading-[1.18rem] md:leading-[20px] md:mb-[10px]">
            <span class="font-inter font-bold text-[1rem] md:text-[16px] text-[#2894FF]">{{ vacancyName }}</span>
        </div>
        <div class="mb-[0.25rem] leading-[1.18rem] md:leading-[20px] md:mb-0">
            <span class="font-inter font-regular text-[1rem] md:text-[16px] text-black">{{ formatAge(age) }}</span>
        </div>
        <div class="mb-[0.62rem] leading-[1.18rem] md:leading-[20px] md:mb-[6px]">
            <span class="font-inter font-bold text-[1rem] md:text-[16px] text-black">{{ formatPrice(price) }}{{ currencyName }}</span>
        </div>
        <div class="leading-[1.18rem] md:leading-[20px]">
            <span class="font-inter font-normal text-[1rem] md:text-[16px] text-[#888888] mr-[3rem] md:mr-[48px]">Опыт работы</span>
            <span class="font-inter font-normal text-[1rem] md:text-[16px] text-[#535353]">{{ formatAge(experience) }}</span>
        </div>
        
    </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Button from './../../components/ui/Button.vue';
import { defineProps, defineEmits, computed } from 'vue';
import { formatPrice, formatDateTime, formatDateTimeResumeCreated, formatAge, formatDate, humanizeEmploymentType, humanizeEmploymentShedule, getDateYear } from '@/composables/utils';

const props = defineProps({
    vacancyName: {
        type: String,
        default: 'Текст',
    },
    price: {
        type: Number,
        default: null,
    },
    currencyName: {
        type: String,
        default: 'Текст',
    },
    age: {
        type: String,
        default: 'Текст',
    },
    publicationTime: {
        type: String,
        default: 'Текст',
    },
    experience: {
        type: String,
        default: 'Текст',
    },
    modelValue: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);
const checkedStar = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    },
});
</script>

<style scoped></style>