<template>
    <Header @toggleScroll="handleToggleScroll"></Header>
    <div class="flex flex-col bg-[#F8F8F8] main pr-[1.71rem] pl-[1.71rem] pt-[7.75rem] md:pt-[140px]">
        <div class="flex flex-row items-center justify-center">
            <div class="w-full md:w-[314px]">
                <div class="mb-[1rem] md:mb-[13px]">
                    <ResetPasswordCard></ResetPasswordCard>
                </div>
                <div class="mb-[6rem] md:mb-[84px]">
                    <GetSupportCard v-model="showPopup"></GetSupportCard>
                </div>
                
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script setup>
import Header from './../components/ui/Header.vue'
import { ref, reactive, watch, onUnmounted, onMounted, computed } from 'vue';
import Footer from './../components/ui/Footer.vue'
import ResetPasswordCard from './../components/cards/ResetPasswordCard.vue'
import GetSupportCard from './../components/cards/GetSupportCard.vue'

const disableScroll = ref(false);

const showPopup = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});


</script>

<style></style>