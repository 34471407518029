<template>
  <router-view v-if="loaded"></router-view>
</template>

<script setup>
import {useAuthStore} from "@/stores/authStore";
import {onBeforeMount, ref} from "vue";

const authStore = useAuthStore()

const loaded = ref(false)

onBeforeMount(async () => {
  if (authStore.checkIsAuthenticated()) {
    await Promise.all([authStore.getMe()])
    loaded.value = true
  }
  else {
    loaded.value = true
  }
})

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  font-family: 'Inter', sans-serif;
}


.nav-item {


  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;

  color: #888888;
  cursor: pointer;
}

.nav-item[active] {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  text-decoration-line: underline;

  color: #2894FF;
}
</style>

