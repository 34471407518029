<template>
  <Header @toggleScroll="handleToggleScroll"></Header>
  <div class="flex flex-col bg-[#F8F8F8] main justify-center items-center">
    <div class="flex flex-row items-center">
      <WelcomeCard></WelcomeCard>
    </div>
    <div v-if="!isMobile" class="mb-[33px]"></div>
    <DoubleSideBlock v-if="!isMobile"></DoubleSideBlock>

    <div v-if="!isMobile" class="flex flex-row justify-center mt-[39px] mb-[26px]">
      <div class="font-[Inter] font-bold text-[24px] lg:w-[992px] text-[#4A4A4A]">
        Вакансии дня
      </div>
    </div>

    <div class="flex flex-row justify-center w-screen lg:!w-[992px]">
      <Tabs lastLink="Все вакансии" :tabs="tabsComputed" v-model="activeTab">
        <template #tab1>
          <div
            class="!bg-[#F8F8F8] pr-[1.5rem] pl-[1.5rem] pt-[1.81rem] pb-[1.68rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px]">
            <div class="mb-[1.81rem] md:mb-0 flex flex-col md:flex-row justify-between">
              <div class="mb-[1rem] " v-for="(item, index) in vacancies" :key="index">
                <VacancyCard :vacancyId=item.id :vacancyName=item.name :priceFrom=item.price_from :priceTo=item.price_to
                  :currencyName=item.currency_display_name :city=item.city :publicationTime=item.created_at
                  v-model="vacanciesIdsWithFavorite[item.id]"></VacancyCard>
              </div>
            </div>
            <router-link to="/employee/vacancies">
              <div v-if="isMobile" class="text-[1rem] font-[Inter] font-normal custom-underline cursor-pointer">
                Перейти
                к
                списку вакансий</div>
            </router-link>

          </div>

        </template>
        <template #tab2>
          <div
            class="!bg-[#F8F8F8] pr-[1.5rem] pl-[1.5rem] pt-[1.81rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px] pb-[1.68rem]">
            <div class="mb-[1.81rem] md:mb-0 flex flex-col md:flex-row justify-between">
<!--              <div class="mb-[1rem] mr-[1.5rem]" v-for="(item, index) in vacancies" :key="index">-->
              <div class="mb-[1rem] " v-for="(item, index) in vacancies" :key="index">
                <VacancyCard :vacancyId=item.id :vacancyName=item.name :priceFrom=item.price_from :priceTo=item.price_to
                  :currencyName=item.currency_display_name :city=item.city :publicationTime=item.created_at
                  v-model="vacanciesIdsWithFavorite[item.id]"></VacancyCard>
              </div>
            </div>
            <router-link to="/employee/vacancies">
              <div v-if="isMobile" class="text-[1rem] font-[Inter] font-normal custom-underline">Перейти к списку
                вакансий
              </div>
            </router-link>

          </div>
        </template>
        <template #tab3>
          <div
            class="!bg-[#F8F8F8] pr-[1.5rem] pl-[1.5rem] pt-[1.81rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px] pb-[1.68rem]">
            <div class="mb-[1.81rem] md:mb-0 flex flex-col md:flex-row justify-between">
              <div class="mb-[1rem] " v-for="(item, index) in vacancies" :key="index">
                <VacancyCard :vacancyId=item.id :vacancyName=item.name :priceFrom=item.price_from :priceTo=item.price_to
                  :currencyName=item.currency_display_name :city=item.city :publicationTime=item.created_at
                  v-model="vacanciesIdsWithFavorite[item.id]"></VacancyCard>
              </div>
            </div>
            <div v-if="isMobile" class="text-[1rem] font-[Inter] font-normal custom-underline">Перейти к списку
              вакансий
            </div>
          </div>
        </template>
        <template #tab4>
          <div
            class="!bg-[#F8F8F8] pr-[1.5rem] pl-[1.5rem] pt-[1.81rem] md:pr-0 md:pl-0 md:pt-[26px] md:pb-[50px] pb-[1.68rem]">
            <div class="mb-[1.81rem] md:mb-0 flex flex-col md:flex-row justify-between">
              <div class="mb-[1rem] " v-for="(item, index) in vacancies" :key="index">
                <VacancyCard :vacancyId=item.id :vacancyName=item.name :priceFrom=item.price_from :priceTo=item.price_to
                  :currencyName=item.currency_display_name :city=item.city :publicationTime=item.created_at
                  v-model="vacanciesIdsWithFavorite[item.id]"></VacancyCard>
              </div>
            </div>
            <div v-if="isMobile" class="text-[1rem] font-[Inter] font-normal custom-underline">Перейти к списку
              вакансий
            </div>
          </div>
        </template>
      </Tabs>

    </div>



  </div>

  <div class="mb-[1.81rem]" v-if="isMobile">
    <DoubleSideBlock></DoubleSideBlock>
  </div>
  <div v-if="!$authStore.isAuthenticated" class="bg-[#3D7CD1] pr-[1.5rem] pl-[1.5rem] pb-[2.93rem] pt-[2.93rem] md:pr-0 md:pl-0">
    <div v-if="!isMobile" class="w-full flex flex-row justify-center">
      <PreSignupCard title="Что необходимо, для начала работы с биржей?"
        subtitle="Зарегистрируйтесь как компания или сотрудник, чтобы пользоваться всеми возможностями">
      </PreSignupCard>
    </div>
    <PreSignupCard v-else title="Что необходимо, для начала работы с биржей?"
      subtitle="Зарегистрируйтесь как компания или сотрудник, чтобы пользоваться всеми возможностями">
    </PreSignupCard>

  </div>
  <Footer></Footer>
</template>

<script setup>
import VacancyCard from './../components/cards/VacancyCard.vue';
import PreSignupCard from './../components/cards/PreSignupCard.vue'
import Header from './../components/ui/Header.vue'
import { ref, watch, onUnmounted, onMounted, computed } from 'vue';
import Tabs from "@/components/ui/Tabs";
import Footer from './../components/ui/Footer.vue'
import WelcomeCard from './../components/cards/WelcomeCard.vue'
import DoubleSideBlock from "@/components/cards/DoubleSideBlock";
import { useFetch } from '@/composables/useFetch';
import { useAuthStore } from '@/stores/authStore';
const authStore = useAuthStore();

const { authRequest, anonRequest } = useFetch();

const vacancies = ref([]);
const vacanciesIdsWithFavorite = ref({})
const vacanciesIdsWithFavoriteInitiate = ref(false)
const loading = ref(false);
const error = ref(null);

const fetchVacanciesData = async (queryParams = {}) => {
  console.log('Fetching vacancies data...');
  loading.value = true; // Устанавливаем статус загрузки
  error.value = null; // Сбрасываем предыдущую ошибку
  queryParams.status__in = 'PUBLISHED'

  try {
    // Создаем строку запроса вручную
    const queryArray = [];
    for (const key in queryParams) {
      const value = queryParams[key];
      // Проверьте, является ли значение массивом
      if (Array.isArray(value)) {
        // Если массив, объедините значения через запятую
        queryArray.push(`${key}=${value.join(',')}`);
      } else {
        queryArray.push(`${key}=${value}`);
      }
    }
    const queryString = queryArray.join('&'); // Объединяем параметры
    const url = `/vacancies/${queryString ? '?' + queryString : ''}`;

    console.log('Request URL:', url); // Логируем конечный URL

    const response = await anonRequest(url, {
      method: 'GET',
    });

    // Если запрос успешен, сохраняем данные
    if (response && !response.error) {
      vacancies.value = response.results; // Предполагаем, что API возвращает массив данных
    } else {
      // Если есть ошибка, устанавливаем ошибку
      error.value = response;
    }
  } catch (err) {
    console.error('Запрос завершился ошибкой:', err);
    error.value = err; // Устанавливаем ошибку
  } finally {
    loading.value = false; // Сбрасываем статус загрузки
  }
};

const fetchMe = async () => {
  console.log('fetchME')
  const url = `/me/`;

  const response = await authRequest(url, {
    method: 'GET',
  });

  response.favorites_vacancies.forEach(number => {
    vacanciesIdsWithFavorite.value[number] = true;
  });

}


const fetchSetVacancies= async (vacanciesIdsToSet = []) => {
  console.log('fetchSetVacancies')
  const url = `/vacancies/set_favorite/`;
  const responseBody = {
    "vacancies_ids": vacanciesIdsToSet
  }
  const response = await authRequest(url, {
    method: 'POST',
    body: responseBody
  });

  return response

}


const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

watch(vacanciesIdsWithFavorite, (newValue, oldValue) => {
  // console.log('смена', newValue, oldValue)
  let vacanciesIdsToSet = []
  // if (vacanciesIdsWithFavoriteInitiate.value === true) {
    Object.keys(newValue).forEach(key => {
      if (newValue[key] === true) {
        vacanciesIdsToSet.push(Number(key))
      }
    });
    fetchSetVacancies(vacanciesIdsToSet);
    console.log('vacanciesIdsToSet', vacanciesIdsToSet)
  // } else {
  //   vacanciesIdsWithFavoriteInitiate.value = true
  // }

}, { deep: true });

watch(disableScroll, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
  fetchVacanciesData({
    limit: '3'
  }).then(result => {
    if (authStore.isAuthenticated) {
      fetchMe();
    }
    
  })
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});


const showPopup = ref(false);

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const showPopupSortTest = ref(false);

const togglePopupSortTest = () => {
  showPopupSortTest.value = !showPopupSortTest.value;
};

const showPopupFilterTest = ref(false);

const togglePopupFilterTest = () => {
  showPopupFilterTest.value = !showPopupFilterTest.value;
};

const tabs = [
  { name: "tab1", label: "Новые вакансии" },
  { name: "tab2", label: "Подработка" },
];

const tabsFull = [
  { name: "tab1", label: "Новые вакансии" },
  { name: "tab2", label: "Подработка" },
  { name: "tab3", label: "Без опыта" },
  { name: "tab4", label: "Отклик без резюме" },
];

const tabsComputed = computed(() => {
  if (isMobile.value === true) {
    return tabs
  } else {
    return tabsFull
  }
})

const activeTab = ref(0);

watch(activeTab, (newValue) => {
  if (newValue === 0) {
    fetchVacanciesData({
      limit: '3'
    });
  } else if (newValue === 1) {
    fetchVacanciesData({
      employment_type__in: ['not_full_day', 'project'],
      limit: '3'
    });
  } else if (newValue === 2) {
    fetchVacanciesData({
      experience__in: 'no_experience',
      limit: '3'
    });
  } else if (newValue === 3) {
    fetchVacanciesData({
      no_resume: 'true',
      limit: '3'
    });
  }
});

</script>

<style>
@media (min-width: 769px) {
  .main {
    padding-right: 5.53rem;
    padding-left: 5.53rem;
  }
}

@media (max-width: 768px) {
  .main {
    padding-right: 0rem;
    padding-left: 0rem;
  }
}
</style>