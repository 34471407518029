<template>
    <div class="flex flex-row justify-center">
        <Sheet
            class="pt-[2.18rem] pl-[1.5rem] pb-[2.37rem] pr-[1.5rem] md:pl-[87px] md:pt-[22xpx] md:pr-[65px] md:pb-[35px] md:!w-[993px]">
            <div class="flex mb-[2.65rem] md:mb-[35px] flex-row md:flex-row-reverse md:mb-[50px]">
                <div class="flex flex-col md:w-[278px] md:h-[336px] md:ml-[45px]">
                    <div class="w-[10.31rem] h-[12.46rem] md:w-[278px] md:h-[336px] md:mb-[12px] flex-shrink-0">

                        <div class="w-full h-full md:w-[278px] md:h-[336px] object-cover">
                            <img v-if="imageSrcId" :src=imageSrc alt="Аватар" class="w-full h-full" />
                            <img v-else src="./../../assets/images/UserEmpty.webp" alt="Аватар пустой"
                                class="w-full h-full" />
                        </div>

                    </div>
                    <div v-if="!isMobile">
                        <Button type="get-contacts" class="!w-[278px] !h-[51px] mb-[13px]"
                            @click="router.push(`/employer/do_invation?resume_id=${id}`)">Отправить
                            приглашение</Button>
                        <!--                      <div>Контакты будут доступны в приглашении</div>-->
                        <!--                        <Button type="contacts-unlocked" class="!w-[278px] !h-[51px] mb-[13px]">Контакты</Button>-->
                        <div class="flex justify-center"><Button type="get-pdf" @click="downloadResume">Скачать
                                пдф</Button>
                        </div>
                    </div>

                </div>
                <div class="flex flex-col ml-[1rem] md:ml-0 md:!w-[45vw] lg:!w-[519px]">
                    <span
                        class="text-[0.75rem] text-[#00BD35] mb-[0.55rem] md:text-[12px] md:mb-[12px] font-[Inter] font-normal">{{
                                formatDateTimeResumeCreated(publicationTime) }}</span> <!--mb-[1.68rem] -->
                    <!-- тут поедет если длинное имя -->
                    <span
                        class="text-[1.12rem] text-[#000000] mb-[1.08rem] md:text-[36px] md:mb-0 font-[Inter] font-bold">
                        {{ employeeFirstName }} {{ employeeLastName }}</span>
                    <div v-if="isMobile">
                        <div class="mb-[0.33rem]"><Button @click="router.push(`/employer/do_invation?resume_id=${id}`)"
                                type="get-contacts">Пригласить</Button></div>
                        <div><Button type="contacts-unlocked">Контакты</Button></div>
                    </div>

                    <div v-else class="flex flex-col">
                        <div class="flex flex-row mb-[34px]">
                            <span v-if="city" class="text-[18px] font-[Inter] font-semibold">{{ city }}</span>
                            <span v-else class="text-[18px] font-[Inter] font-semibold">Город не указан</span>
                            <span v-if="props.readyToMove" class="text-[18px] font-[Inter] font-light"> , готов к
                                периезду</span>
                            <span v-else class="text-[18px] font-[Inter] font-light"> , не готов к периезду</span>
                        </div>
                        <div class="flex flex-row justify-between">
                            <div class="text-[#888888] text-[18px] font-[Inter] font-light">Гражданство</div>
                            <div v-if="citizenship" class="text-[18px] font-[Inter] font-medium w-[150px]">{{
                                citizenship }}</div>
                            <div v-else class="text-[18px] font-[Inter] font-medium w-[150px]">не указано</div>
                        </div>
                        <div class="flex flex-row justify-between">
                            <div class="text-[#888888] text-[18px] font-[Inter] font-light">Полных лет</div>
                            <div class="text-[18px] font-[Inter] font-medium w-[150px]">{{ formatAge(age) }}</div>
                        </div>
                        <div class="flex flex-row justify-between mb-[56px]">
                            <div class="text-[#888888] text-[18px] font-[Inter] font-light">Зарплатные ожидания</div>
                            <div v-if="price" class="text-[18px] font-[Inter] font-medium w-[150px]">{{
                                formatPrice(price) }}{{
                                currencyName }}</div>
                            <div v-else class="text-[18px] font-[Inter] font-medium w-[150px]">не указано</div>
                        </div>
                        <hr class="mb-[55px]">
                        <span class="text-[34px] text-[#000000] font-[Inter] font-bold">{{ specialityName }}</span>
                        <span class="text-[18px] mb-[25px] text-[#000000] font-[Inter] font-normal">
                            {{ humanizeEmploymentType(employmentType) }}, {{
                                humanizeEmploymentShedule(employmentShedule) }}</span>
                        <div class="flex flex-row justify-between">
                            <span class="text-[18px] text-[#888888] font-[Inter] font-light">Трудовой стаж</span>
                            <span v-if="experience" class="text-[18px] font-[Inter] font-semibold w-[150px]">{{
                                formatAge(experience)
                            }}</span>
                            <span v-else class="text-[18px] font-[Inter] font-semibold w-[150px]">не указано</span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="flex flex-col">
                <div v-if="isMobile" class="flex flex-col">
                    <span class="text-[1.12rem] text-[#000000] mb-[0.42rem] font-[Inter] font-bold">{{ specialityName
                        }}</span>
                    <span class="text-[1rem] text-[#000000] mb-[0.42rem] font-[Inter] font-normal">
                        {{ humanizeEmploymentType(employmentType) }}, {{ humanizeEmploymentShedule(employmentShedule) }}
                    </span>
                    <div class="flex flex-row mb-[2.06rem]">
                        <span v-if="city" class="text-[1rem] text-[#000000] font-[Inter] font-semibold">{{ city
                            }}</span>
                        <span class="text-[1rem] text-[#000000] font-[Inter] font-semibold">Город не указан</span>
                        <span v-if="props.readyToMove" class="text-[1rem] text-[#000000] font-[Inter] font-light">,
                            готов к переезду</span>
                        <span v-else class="text-[1rem] text-[#000000] font-[Inter] font-light">, не готов к
                            переезду</span>
                    </div>
                    <div class="flex flex-row mb-[0.81rem] justify-between">
                        <span class="text-[1rem] text-[#888888] font-[Inter] font-light">Трудовой стаж</span>
                        <span class="text-[1rem] font-[Inter] font-semibold">{{ formatAge(experience) }}</span>
                    </div>
                    <div class="flex flex-row mb-[0.81rem] justify-between">
                        <span class="text-[1rem] text-[#888888] font-[Inter] font-light">Полных лет</span>
                        <span class="text-[1rem] font-[Inter] font-light">{{ formatAge(age) }}</span>
                    </div>
                    <div class="flex flex-row mb-[3.31rem] justify-between">
                        <span class="text-[1rem] text-[#888888] font-[Inter] font-light">Зарплатные ожидания </span>
                        <span v-if="price" class="text-[1rem] font-[Inter] font-semibold">{{ formatPrice(price) }}{{ currencyName
                            }}</span>
                        <span v-else class="text-[1rem] font-[Inter] font-semibold">не указано</span>
                    </div>
                </div>

                <span
                    class="text-[1rem] md:text-[22px] text-[#000000] font-[Inter] font-semibold mb-[2.81rem] md:mb-[46px]">Опыт
                    работы</span>
                <div class="flex flex-col mb-[1.87rem] md:mb-[36px]" v-for="(experienceUnit, index) in experienceUnits"
                    :key="index">
                    <div class="flex flex-row justify-between md:justify-start mb-[1.87rem] md:mb-[12px]">
                        <div
                            class="text-[1rem] text-[#000000] font-[Inter] font-bold w-[3.8rem] h-[2.37rem] md:text-[22px] md:w-[82px] md:h-[54px] md:mr-[80px]">
                            {{ getDateYear(experienceUnit.start_date) }} - {{ getDateYear(experienceUnit.end_date) }}:
                        </div>
                        <div class="flex flex-col">
                            <div
                                class="text-[1rem] text-[#000000] font-[Inter] font-semibold text-right md:text-left md:text-[22px]">
                                {{
                                experienceUnit.company_name }}</div>
                            <div
                                class="text-[1rem] text-[#000000] font-[Inter] font-normal text-right md:text-left md:text-[22px] md:mb-[12px]">
                                {{
                                experienceUnit.position_name }}</div>
                            <div v-if="!isMobile"
                                class="text-[#000000] font-[Inter] font-light text-[18px] max-w-[678px]">{{
                                experienceUnit.position_description }}</div>
                        </div>
                    </div>
                    <div v-if="isMobile" class="text-[1rem] text-[#000000] font-[Inter] font-light">{{
                                experienceUnit.position_description }}</div>

                </div>
                
                <span
                    class="text-[1rem] md:text-[22px] text-[#000000] font-[Inter] font-semibold mb-[2.03rem] md:mb-[21px] md:mt-[50px]">Обо
                    мне</span>
                <span
                    class="text-[1rem] md:text-[18px]  text-[#B7B7B7] font-[Inter] font-light mb-[1.75rem] md:mb-[36px]">{{
                                about }}</span>
                <span
                    class="text-[1rem] md:text-[22px] text-[#000000] font-[Inter] font-semibold mb-[0.8rem] md:mb-[20px]">Образование</span>

                <div class="flex flex-col mb-[2.21rem]" v-for="(educationUnit, index) in educationUnits" :key="index">
                    <div class="flex flex-col md:flex-row">
                        <div
                            class="leading-[1.3rem] text-[1rem] md:text-[18px] md:leading-[21px] text-[#000000] font-[Inter] font-semibold mb-[1.06rem] md:mb-0 md:mr-[130px]">
                            {{
                                educationUnit.graduation_year }}</div>
                        <div class="flex flex-col">
                            <span
                                class="leading-[1.3rem] md:leading-[21px] text-[1rem] md:text-[18px] text-[#000000] font-[Inter] font-semibold">{{
                                educationUnit.institution_name }}</span>
                            <span
                                class="leading-[1.25rem] md:leading-[21px] text-[1rem] md:text-[18px] text-[#1E1E1E] font-[Inter] font-light">{{
                                educationUnit.degree_name }}</span>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col md:flex-row-reverse md:justify-start">
                    <Button type="get-contacts" class="mb-[0.44rem]  md:mb-0 md:w-[278px] md:h-[51px]">Отправить
                        приглашение</Button>
                    <!--                    <Button type="contacts-unlocked"-->
                    <!--                        class="mb-[1.87rem] md:mb-0 md:mr-[36px] md:w-[278px] md:h-[51px]">Контакты</Button>-->
                    <div v-if="isMobile" class="flex justify-center"><Button type="get-pdf">Скачать пдф</Button></div>
                </div>


            </div>

        </Sheet>

    </div>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Grade from './../../components/ui/Grade.vue';
import Button from './../../components/ui/Button.vue';
import { defineProps, defineEmits, computed, onMounted, onUnmounted, ref } from 'vue';
import { formatPrice, formatDateTime, formatDateTimeResumeCreated, formatAge, formatDate, humanizeEmploymentType, humanizeEmploymentShedule, getDateYear, transliterate } from '@/composables/utils';
import { useFetch } from '@/composables/useFetch';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
const route = useRoute();
const router = useRouter();
const { baseURL, anonRequest } = useFetch();

const downloadResume = async () => {
    try {
        // Выполнение запроса на бэкенд
        const response = await anonRequest(`/resumes/${props.id}/generate_pdf`, {
            method: 'GET',
            responseType: 'blob' // Важно установить тип ответа
        });

        // Создаем URL для скачиваемого файла
        const url = window.URL.createObjectURL(new Blob([response]));

        // Создаем ссылку для скачивания
        const a = document.createElement('a');
        a.href = url;
        const fullName = `${props.employeeFirstName}${props.employeeLastName}`
        a.download = `resume_${transliterate(fullName)}.pdf`; // Указываем имя файла для скачивания
        document.body.appendChild(a);
        a.click();

        // Убираем ссылку после скачивания
        a.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Ошибка при скачивании файла:', error);
    }
};

const props = defineProps({
    id: {
        type: Number,
        default: null,
    },
    imageSrcId: {
        type: Number,
        default: null,
    },
    publicationTime: {
        type: String,
        default: 'Текст',
    },
    employeeFirstName: {
        type: String,
        default: 'Текст',
    },
    employeeLastName: {
        type: String,
        default: 'Текст',
    },
    specialityName: {
        type: String,
        default: 'Текст',
    },
    employmentType: {
        type: String,
        default: 'Текст',
    },
    employmentShedule: {
        type: String,
        default: 'Текст',
    },
    city: {
        type: String,
        default: 'Текст',
    },
    readyToMove: {
        type: Boolean,
        default: false,
    },
    citizenship: {
        type: String,
        default: 'Текст',
    },
    experience: {
        type: String,
        default: 'Текст',
    },
    age: {
        type: String,
        default: 'Текст',
    },
    price: {
        type: String,
        default: 'Текст',
    },
    currencyName: {
        type: String,
        default: 'Текст',
    },
    about: {
        type: String,
        default: 'Кандидат пока не заполнил этот раздел',
    },
    experienceUnits: {
        type: Array,
        default: () => [],
        validator: (val) => {
            return Array.isArray(val) && val.every(item =>
                typeof item.timeFrom === 'string' && typeof item.timeTo === 'string' && typeof item.company === 'string' && typeof item.jobTitle === 'string' && typeof item.description === 'string');
        }
    },
    educationUnits: {
        type: Array,
        default: () => [],
        validator: (val) => {
            return Array.isArray(val) && val.every(item =>
                typeof item.timeEnd === 'string' && typeof item.university === 'string' && typeof item.educationTitle === 'string');
        }
    },
});

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

const imageSrc = computed(() => {
    return `${baseURL}/files/${props.imageSrcId}/download`
});

</script>

<style scoped></style>