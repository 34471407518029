<template>
  <LKLayout>

    <div class="wj-container md:w-[645px] md:min-h-[300px] px-[15px] py-[15px] md:px-[1rem] md:py-[1rem]">
      <div class="flex justify-between mb-[60px]">
        <div class="title">Данные компании</div>
      </div>
      <!-- <div class="mt-[20px]">
          <label for="form-name" class="form-label">Название</label>
          <UIInput v-model="me.last_name"></UIInput>
      </div> -->
      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Логотип</label>
        <UIFileUploader v-model="form.avatar"></UIFileUploader>
      </div>
      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Название компании</label>
        <UIInput v-model="form.company_name"></UIInput>
      </div>
      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Контактое лицо</label>
        <UIInput v-model="form.contact_person"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Номер телефона</label>
        <UIInput v-model="form.phone"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Электронная почта</label>
        <UIInput v-model="form.email"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">ИНН</label>
        <UIInput v-model="form.tin"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Расчетный счет</label>
        <UIInput v-model="form.current_account"></UIInput>
      </div>


      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Почтовый адрес</label>
        <UIInput v-model="form.post_address"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Корреспондентский счет</label>
        <UIInput v-model="form.correspondent_account"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">КПП</label>
        <UIInput v-model="form.kpp"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">БИК</label>
        <UIInput v-model="form.bik"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Название банка</label>
        <UIInput v-model="form.bank_name"></UIInput>
      </div>

      <div class="mt-[20px]">
        <label for="form-name" class="form-label">Тип</label>
        <UISelect v-model="form.type" :options="types" class="w-full"></UISelect>
      </div>

      <div v-if="form.type === 'individual_entity'">
        <div class="mt-[40px]">
          <label for="form-name" class="form-label">Название</label>
          <UIInput v-model="form.name"></UIInput>
        </div>
      </div>
      <div v-else>
        <div class="mt-[40px]">
          <label for="form-name" class="form-label">Юридический адрес</label>
          <UIInput v-model="form.legal_address"></UIInput>
        </div>
        <div class="mt-[20px]">
          <label for="form-name" class="form-label">ОГРН</label>
          <UIInput v-model="form.ogrn"></UIInput>
        </div>
      </div>


      <div class="mt-[20px]">
        <Button type="login-logincard" class="md:w-[362px]" @click="save">Сохранить</Button>
      </div>

    </div>
  </LKLayout>

</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout";
import LKLayout from "@/layouts/LKLayout";
import {onMounted, reactive, ref, computed, watch, onBeforeMount} from "vue";
import UIRadioButton from "@/components/ui/UIRadioButton";
import UISelect from "@/components/ui/UISelect";
import {useFetch} from "@/composables/useFetch";
import Button from "@/components/ui/Button";
import UITextArea from "@/components/ui/UITextArea";
import UIInput from "@/components/ui/UIInput";
import Checkbox from "@/components/ui/Checkbox";
import UIDatePicker from "@/components/ui/UIDatePicker";
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import {useAuthStore} from "@/stores/authStore";
import {useToast} from "vue-toast-notification";
import UIFileUploader from "@/components/ui/UIFileUploader";

const fetch = useFetch()
const route = useRoute();
const router = useRouter();
const authStore = useAuthStore()
const toast = useToast()


const cities = ref([])
const types = ref([{title: 'Физическое лицо', value: 'individual_entity'}, {
  title: 'Юридическое лицо',
  value: 'legal_entity'
}])
const fetched = ref(false)

const form = reactive({
  avatar: null,

  name: null,
  company_name: null,
  contact_person: null,
  phone: null,
  email: null,
  tin: null,
  type: null,
  post_address: null,
  // рассчетный счет
  current_account: null,
  correspondent_account: null,
  // юридический адрес
  legal_address: null,
  ogrn: null,
  // КПП
  kpp: null,
  bik: null,
  bank_name: null,
  short_description: null,
  full_description: null,
  city: null,
})

onBeforeMount(() => {

  const companyRequest = fetch.authRequest(`/employers/${authStore.me.employer_profile_id}/`).then((data) => {
    form.avatar = data.avatar

    form.name = data.name
    form.company_name = data.company_name
    form.contact_person = data.contact_person
    form.phone = data.phone
    form.email = data.email
    form.tin = data.tin
    form.post_address = data.post_address
    // рассчетный счет
    form.current_account = data.current_account
    form.type = data.type
    form.correspondent_account = data.correspondent_account
    // юридический адрес
    form.legal_address = data.legal_address
    form.short_description = data.short_description
    // КПП
    form.kpp = data.kpp
    form.bik = data.bik
    form.bank_name = data.bank_name
    form.ogrn = data.ogrn
  })

  const citiesRequest = fetch.anonRequest('/cities/').then((data) => {
    cities.value = data.results.map(item => ({
      title: item,
      value: item,
    }))
  })

  Promise.all([citiesRequest].filter(Boolean)).then(() => {

    fetched.value = true;
  });

})

const save = () => {
  fetch.authRequest(`/employers/${authStore.me.employer_profile_id}/`, {
    method: 'PUT',
    body: {
      ...form
    }
  })
    .then(response => {
      toast.success('Сохранено')
    })
}

</script>

<style scoped>
.wj-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.form-input {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;

  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 15px;

  height: 42px;


}

.form-label {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4A4A4A;
  margin-bottom: 20px;
}


.form-sublabel {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  leading-trim: both;
  text-edge: cap;
  letter-spacing: 0.04em;

  color: #888888;

  margin-bottom: 20px;


}

.title {
  /* Создание вакансии */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #4A4A4A;


}
</style>