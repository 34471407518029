<template>
  <Sheet class="!p-[0.5rem_0.95rem_0.5rem_0.95rem] md:!p-[17px_28px_17px_28px] !rounded-[0.5rem] md:!rounded-[15px]">
    <div class="flex justify-between mb-[0.56rem] md:mb-[15xpx]">
      <slot name="date-time">
        <span
        class="text-[#00BD35] text-[0.5rem] md:text-[12px] leading-[0.5rem] md:leading-[12px] font-[Inter] font-normal">{{
          formatDateTimeResumeCreated(publicationTime) }}</span>
      </slot>
      
    </div>
    <div class="flex justify-between mb-[0.5rem] md:mb-[10px]">
      <span
        class="text-[#2894FF] text-[0.75rem] md:text-[16px] leading-[0.87rem] md:leading-[16px] font-[Inter] font-bold">{{
          resumeName }}</span>
    </div>
    <slot name="mainInfo">
      <div class="flex justify-start mb-[0.43rem] md:flex-col md:mb-[11px]">
        <span
          class="text-[0.5rem] lg:text-[1rem] leading-[0.62rem] font-[Inter] font-regular mr-[0.87rem] md:mb-[5px]">{{
          formatAge(age) }}</span>
        <span v-if="price"
          class="text-[0.5rem] lg:text-[1rem] leading-[0.62rem] md:leading-[16px] font-[Inter] font-bold">{{
          formatPrice(price) }}{{ currencyName }}</span>
        <div v-else
          class="mb-[0.5rem] md:mb-[16px]"></div>
      </div>
      <div class="flex justify-between">
        <div class="flex flex-col">
          <span class="text-[0.5rem] text-[#888888] md:text-[16px] font-[Inter] font-regular">Опыт работы</span>
          <span class="text-[0.5rem] text-[#888888] md:text-[16px] font-[Inter] font-regular">Последнее место
            работы</span>
        </div>
        <div class="flex flex-col">
          <span v-if="experience > 1 && experience !== null"
            class="text-[0.5rem] text-[#535353] md:text-[16px] font-[Inter] font-regular">{{ formatAge(experience)
            }}</span>
          <span v-else-if="experience === 0 && experience !== null"
            class="text-[0.5rem] text-[#535353] md:text-[16px] font-[Inter] font-regular">менее 1 года</span>
          <span v-else class="text-[0.5rem] text-[#535353] md:text-[16px] font-[Inter] font-regular">не указано</span>
          <span v-if="lastJobPlace" class="text-[0.5rem] text-[#000000] md:text-[16px] font-[Inter] font-bold">{{
          lastJobPlace }}</span>
          <span v-else class="text-[0.5rem] md:text-[16px] text-[#535353] font-[Inter] font-regular">не указано</span>
        </div>
        <div class="flex flex-col justify-end">
          <span v-if="lastJobTimeEnd" class="text-[0.5rem] md:text-[16px] text-[#535353] font-[Inter] font-regular">{{
          formatDate(lastJobTimeStart) }} - {{ formatDate(lastJobTimeEnd) }}</span>
        </div>
      </div>
    </slot>

      <slot name="actions">
        
      </slot>
  </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';

import Button from './../../components/ui/Button.vue';
import { defineProps, defineEmits, computed, ref, onMounted } from 'vue';
import { formatPrice, formatDateTime, formatDateTimeResumeCreated, formatAge, formatDate } from '@/composables/utils';
import { useFetch } from '@/composables/useFetch';
const { baseURL } = useFetch();


const props = defineProps({
  id: {
    type: Number,
    default: null,
  },
  resumeName: {
    type: String,
    default: 'Текст',
  },
  age: {
    type: String,
    default: '100 лет',
  },
  price: {
    type: String,
    default: '1руб',
  },
  currencyName: {
    type: String,
    default: null,
  },
  experience: {
    type: String,
    default: null,
  },
  lastJobPlace: {
    type: String,
    default: null,
  },
  lastJobTimeStart: {
    type: String,
    default: null,
  },
  lastJobTimeEnd: {
    type: String,
    default: null,
  },
  publicationTime: {
    type: String,
    default: 'Текст',
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
});


</script>

<style scoped></style>