<template>
  <div class="flex items-center justify-center py-[0.5rem] md:py-[8px]">
    <!--
    <button
      @click="prevPage"
      :disabled="currentPage === 1"
      class="bg-[#060C90] text-white px-[0.5rem] py-[0.25rem] rounded-[0.5rem] mx-[0.25rem]"
    >
      Назад
    </button>
    -->
    <button
      @click="gotoPage(1)"
      :class="[
        'button',
        currentPage === 1 ? 'button-active' : ''
      ]"
    >
      1
    </button>

    <span v-if="startPage > 2" class="mx-[0.25rem] md:mx-[4px]">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
          stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
          d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
          stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
              stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>

    <button
      v-for="page in pagesToShow"
      :key="page"
      @click="gotoPage(page)"
      :class="[
        'button',
        currentPage === page ? 'button-active' : ''
      ]"
    >
      {{ page }}
    </button>

    <span v-if="endPage < totalPages - 1" class="mx-[0.25rem] md:mx-[4px]">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
          stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
          d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
          stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
          d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
          stroke="#888888" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>

    <button
      @click="gotoPage(totalPages)"
      :class="[
        'button',
        currentPage === totalPages ? 'button-active' : ''
      ]"
    >
      {{ totalPages }}
    </button>

    <button
      @click="nextPage"
      :disabled="currentPage === totalPages"
      class="button px-[0.5rem]"
      style="width: unset"
    >
      Далее
    </button>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from "vue";

const emit = defineEmits([
  'update:modelValue'
])

const props = defineProps({
  total: {
    type: Number,
    default: 10,
  },
  modelValue: {
    type: Number,
    default: 1,
  },
});

const currentPage = ref(props.modelValue);

watch(currentPage, (value) => {
  emit('update:modelValue', value)
})

const totalPages = computed(() => props.total);
const halfWindow = Math.floor(3 / 2); // изменяем размер окна до 3

const startPage = computed(() => {
  let start = currentPage.value - halfWindow;
  if (start < 2) {
    start = 2;
  }
  return start;
});

const endPage = computed(() => {
  let end = currentPage.value + halfWindow;
  if (end > totalPages.value - 1) {
    end = totalPages.value - 1;
  }
  return end;
});

const pagesToShow = computed(() => {
  const pages = [];
  for (let i = startPage.value; i <= endPage.value && i < totalPages.value; i++) {
    pages.push(i);
  }
  return pages;
});

const gotoPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

watch(
  () => props.modelValue,
  (newCurrent) => {
    currentPage.value = newCurrent;
  }
);
</script>

<style scoped>
button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.button {
  border-radius: 0.5rem;
  border: 1px solid #B7B7B7;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.15rem;
  margin-left: 0.15rem;
  color: #B7B7B7;
}

.button-active {
  border-color: #2894FF;
  background-color: #2894FF;
  color: white;
}

@media (min-width: 768px) {
  .button {
    border-radius: 5px;
    width: 35px;
    height: 35px;
    font-size: 14px; /* Пропорционально измените шрифт */
  }
}
</style>