<template>
    <div class="pr-[1.5rem] pl-[1.5rem] md:w-[992px] md:pl-0 md:pr-0">

        <div v-if="isMobile">
            <div class="mb-[0.62rem]">
                <Button type="fullresume-afterlogin">Полное резюме будет доступно после регистрации</Button>
            </div>
            <router-link to="/signup">
                <Button type="get-contacts">Регистарция</Button>
            </router-link>

        </div>
        <div v-else
            class="relative bg-[#3D7CD1] rounded-[20px] pl-[83px] pt-[33px] pb-[33px] text-[20px] text-[#FFFFFF] font-[Inter] font-light mb-[29px]">
            Полное резюме будет доступно после регистрации
            <router-link to="/signup" class="absolute top-[21px] right-[85px]">
                <Button type="get-contacts" class="!w-[230px] !h-[54px] text-[18px]">Регистарция</Button>
            </router-link>


        </div>

        <router-link to="/employer/resumes"
            class="flex flex-row justify-start items-center mb-[3.12rem] leading-[1.5rem] md:leading-[26px] md:mb-[29px] mt-[1.31rem]">
            <Icon name="chevron-left"></Icon>
            <div class="text-[0.87rem] text-[#000000] md:text-[18px] font-[Inter] mt-[0.1rem] cursor-pointer">Вернуться
                к списку</div>
        </router-link>


        <div
            class="md:bg-[#FFFFFF] md:rounded-[20px] md:border-transparent md:pt-[49px] md:pl-[85px] md:pr-[85px] md:pb-[43px]">
            <div class="flex flex-row">
                <div class="flex flex-col md:w-[430px] w-full">
                    <div
                        class="text-[0.75rem] md:text-[12px] md:mb-[15px] text-[#00BD35] mb-[1.43rem] font-[Inter] font-normal max-w-[20.31rem]">
                        {{ formatDateTimeResumeCreated(publicationTime) }}</div>
                    <div
                        class="text-[1.12rem] text-[#000000] mb-[1.31rem] md:text-[34px] md:leading-[34px] md:mb-0 font-[Inter] font-bold">
                        {{ specialityName }}</div>
                    <div class="flex flex-row mb-[1.68rem] md:mb-[40px]">
                        <div class="text-[0.87rem] md:text-[22px] text-[#000000] font-[Inter] font-semibold"> {{ city
                            }},</div>
                        <div class="mx-[0.13rem]"></div>
                        <div v-if="props.readyToMove" class="text-[0.87rem] md:text-[22px] text-[#000000] font-[Inter] font-light">готов к переезду</div>
                        <div v-else class="text-[0.87rem] md:text-[22px] text-[#000000] font-[Inter] font-light">не готов к переезду</div>
                    </div>

                    <div class="flex flex-row mb-[0.81rem] md:mb-[8px] justify-between">
                        <span class="text-[1rem] md:text-[22px] text-[#888888] font-[Inter] font-light">Полных
                            лет</span>
                        <span class="text-[1rem] md:text-[22px] font-[Inter] font-light">{{ formatAge(age) }}</span>
                    </div>

                    <div class="flex flex-row mb-[2.43rem] md:mb-[75px] justify-between">
                        <span class="text-[1rem] md:text-[22px] text-[#888888] font-[Inter] font-light">Зарплатные
                            ожидания </span>
                        <span class="text-[1rem] md:text-[22px] font-[Inter] font-semibold">{{ formatPrice(price) }}{{ currencyName }}</span>
                    </div>

                    <div
                        class="text-[1rem] md:text-[22px] mb-[2.43rem] md:mb-[46px] text-[#000000] font-[Inter] font-semibold">
                        Последнее место
                        работы</div>

                    <div v-if="experienceUnits.length > 0" class="flex flex-row justify-between mb-[4.18rem] md:mb-[90px]">
                        <div
                            class="text-[1rem] md:text-[22px] text-[#000000] font-[Inter] font-bold w-[3.8rem] h-[2.37rem] md:w-[82px] md:h-[54px]">
                            {{ getDateYear(lastJobTimeStart) }} - {{ getDateYear(lastJobTimeEnd) }}:
                        </div>
                        <div class="flex flex-col md:w-[340px] md:h-[54px]">
                            <div
                                class="text-[1rem] md:text-[22px] text-[#000000] font-[Inter] font-semibold text-right">
                                {{ lastJobCompanyName }}
                            </div>
                            <div class="text-[1rem] md:text-[22px] text-[#000000] font-[Inter] font-normal text-right">
                                {{lastJobSpecialityName }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!isMobile" class="flex flex-col flex-grow">
                    <div class="flex flex-row justify-end">
                        <div class="flex flex-col w-[287px]">
                            <img src="./../../assets/images/UserEmpty.webp" alt="Описание картинки"
                                class="h-[301px] object-cover mb-[12px]" />
                            <div class="mb-[13px]"><Button type="send-welcome-1" class="h-[51px]">Отправить
                                    приглашение</Button></div>
                            <div><Button type="contacts-locked" class="h-[51px] !text-[18px]">Контакты</Button></div>
                        </div>

                    </div>

                </div>

            </div>


            <div v-if="isMobile" class="mb-[0.88rem]"><Button type="send-welcome-1">Отправить приглашение</Button></div>
            <div v-if="isMobile" class="mb-[1.55rem]"><Button type="contacts-locked">Контакты</Button></div>
            <div class="flex flex-row justify-center md:justify-start">
                <router-link to="/employer/resumes/2">
                    <div
                    class="font-inter font-normal md:font-light md:text-[18px] text-[0.87rem] text-[#00C8EF] custom-underline cursor-pointer">
                    Перейти к
                    просмотру полного резюме</div>
                </router-link>
               
            </div>
        </div>




    </div>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Grade from './../../components/ui/Grade.vue';
import Button from './../../components/ui/Button.vue';
import Icon from '../ui/Icon.vue';
import { defineProps, defineEmits, computed, onMounted, onUnmounted, ref } from 'vue';
import { formatPrice, formatDateTime, formatDateTimeResumeCreated, formatAge, formatDate, humanizeEmploymentType, humanizeEmploymentShedule, getDateYear } from '@/composables/utils';

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

const props = defineProps({
    publicationTime: {
        type: String,
        default: 'Резюме добавлено 20 мая в 12:34',
    },
    specialityName: {
        type: String,
        default: 'Инженер-геодезист',
    },
    city: {
        type: String,
        default: 'Москва',
    },
    readyToMove: {
        type: String,
        default: 'не готов к переезду',
    },
    age: {
        type: String,
        default: '48 лет',
    },
    price: {
        type: String,
        default: '100 000Р',
    },
    currencyName: {
        type: String,
        default: 'Текст',
    },
    experienceUnits: {
        type: Array,
        default: () => [],
        validator: (val) => {
            return Array.isArray(val) && val.every(item =>
                typeof item.timeFrom === 'string' && typeof item.timeTo === 'string' && typeof item.company === 'string' && typeof item.jobTitle === 'string' && typeof item.description === 'string');
        }
    },
});



const lastJobTimeStart = computed(() => {
    if (props.experienceUnits[0]) {
        return props.experienceUnits[0].start_date
    } else return null   
});

const lastJobTimeEnd = computed(() => {
    if (props.experienceUnits[0]) {
        return props.experienceUnits[0].end_date
    } else return null   
});

const lastJobCompanyName = computed(() => {
    if (props.experienceUnits[0]) {
        return props.experienceUnits[0].company_name
    } else return null   
});

const lastJobSpecialityName = computed(() => {
    if (props.experienceUnits[0]) {
        return props.experienceUnits[0].position_name
    } else return null   
});

</script>

<style scoped></style>