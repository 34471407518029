<template>
    <div class="relative h-screen">
      <div class="bg-green-200 h-full">
        <p>Контент под кнопкой</p>
      </div>
  
      <button class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 p-[0.34rem] bg-gray-200 text-black rounded-full hover:bg-gray-300">
        <svg
          width="1.5rem"
          height="1.5rem"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
  
      <div class="bg-blue-200 h-full">
        <p>Еще один контент под кнопкой</p>
      </div>
    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  /* Дополнительный стиль, если нужно */
  </style>