<template>
    <Header @toggleScroll="handleToggleScroll"></Header>
    <div class="flex flex-col bg-[#F8F8F8] main pr-[1.71rem] pl-[1.71rem] pt-[7.75rem] md:pt-[140px]">
        <div class="flex flex-row items-center justify-center">
            <div class="w-full md:w-[314px]">
                <div class="mb-[1rem] md:mb-[13px]">
                    <LoginCard></LoginCard>
                </div>
                <div class="mb-[1rem] md:mb-[13px]">
                    <LoginNoLoginCard></LoginNoLoginCard>
                </div>
                <div class="mb-[6rem] md:mb-[84px]">
                    <GetSupportCard v-model="showPopup"></GetSupportCard>
                </div>
                
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script setup>
import Sheet from './../components/cards/Sheet.vue';
import VacancyCard from './../components/cards/VacancyCard.vue';
import PreSignupCard from './../components/cards/PreSignupCard.vue'
import CompanySignupCard from './../components/cards/CompanySignupCard.vue'
import ResumeSearch from './../components/cards/ResumeSearch.vue'
import ResumeCard from './../components/cards/ResumeCard.vue'
import WelcomeGetJobCard from './../components/cards/WelcomeGetJobCard.vue'
import VacancyCardPartOpen from './../components/cards/VacancyCardPartOpen.vue'
import SimularResumeCard from './../components/cards/SimularResumeCard.vue'
import CompanyCard from './../components/cards/CompanyCard.vue'
import ResumeFullCard from './../components/cards/ResumeFullCard.vue'
import Popup from './../components/ui/Popup.vue'
import TestPopup from './../components/cards/TestPopup.vue'
import TestSortPopup from './../components/cards/TestSortPopup.vue'
import PopupFilterTest from './../components/cards/TestFilterPopup.vue'
import Header from './../components/ui/Header.vue'
import { ref, reactive, watch, onUnmounted, onMounted, computed } from 'vue';
import Tabs from "@/components/ui/Tabs";
import CardsSlider from "@/components/ui/CardsSlider";
import Footer from './../components/ui/Footer.vue'
import ResumeNoLoginCard from './../components/cards/ResumeNoLoginCard.vue'
import WelcomeCard from './../components/cards/WelcomeCard.vue'
import CompanySignUpCardFull from './../components/cards/CompanySignUpCardFull.vue';
import WelcomeGetCandidateCard from './../components/cards/WelcomeGetCandidateCard.vue'
import DoubleSideBlock from "@/components/cards/DoubleSideBlock";
import Button from "@/components/ui/Button";
import LoginCard from './../components/cards/LoginCard.vue'
import LoginNoLoginCard from './../components/cards/LoginNoLoginCard.vue'
import GetSupportCard from './../components/cards/GetSupportCard.vue'

const disableScroll = ref(false);

const handleToggleScroll = () => {
    disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
    if (newValue) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = '';
    }
});

const showPopup = ref(false);

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});


</script>

<style></style>