<template>
  <div v-if="isMobile" class="relative  bg-[#F8F8F8]">
    <div class="mb-[1rem] w-[95vw] rounded-r-[1rem] top-[1.2rem] left-0 bg-[#8A9FBF] p-[1.5rem]">
      <h2 class="text-white font-bold text-[1.5rem] leading-[1.5]  mb-[1rem]">Соискателю</h2>
      <p class="text-white text-[1rem] leading-[1.5]  ">
        Широкий выбор вакансий в сфере водоснабжения.<br />
        Сделайте шаг к успешной карьере вместе с нами.
      </p>
      <router-link :to="{name: 'CreateResumePage'}">
        <Button type="create-resume" class="mt-[0.6rem] w-full" style="border-color: transparent">Создать резюме</Button>
      </router-link>
    </div>

    <div class="ml-[5vw] mt-[1rem] w-[95vw] rounded-l-[1rem] bg-[#8A9FBF] p-[1.5rem] flex flex-col items-end ">
      <h2 class="text-white font-bold text-[1.5rem] leading-[1.5]  pb-[1.5rem] ">Работодателю</h2>
      <p class="text-white text-[1rem] leading-[1.5] text-right">
        Разместив вакансию у нас, вы быстро найдете подходящих специалистов,
        сэкономите время и упростите процесс найма.
      </p>
      <router-link :to="{name: 'CreateVacancyPage'}">
        <Button type="create-resume" class="mt-[0.6rem] w-full" style="border-color: transparent">Разместить
          вакансию</Button>
      </router-link>
      

    </div>
  </div>
  <div v-else class="bg-[#F8F8F8] doubleside">
    <div class="flex flex-row  w-full rounded-[1rem] md:rounded-[15px] bg-[#8A9FBF]">
      <div class="flex flex-col w-[496px] items-center">
        <div class="mt-[42px] font-[Inter] font-bold text-[24px] text-white">Cоискателю</div>
        <div class="mt-[32px] font-[Inter] font-normal text-[16px] text-white text-center w-[314px] h-[88px]">
          Широкий выбор вакансий
          в сфере водоснабжения.<br>
          Сделайте шаг к успешной
          карьере вместе с нами.
        </div>
        <router-link :to="{name: 'CreateResumePage'}">
          <Button type="create-resume" class="!w-[314px] !h-[54px] !text-[18px] !mt-[40px] !mb-[45px]"
          style="border-color: transparent">Создать резюме</Button>
        </router-link>
        
      </div>

      <div class="flex flex-col w-[496px] items-center">
        <div class="mt-[42px] font-[Inter] font-bold text-[24px] text-white">Работодателю</div>
        <div class="mt-[32px] font-[Inter] font-normal text-[16px] text-white text-center w-[314px] h-[88px]">
          Разместив вакансию у нас,
          вы быстро найдете подходящих специалистов, сэкономите время
          и упростите процесс найма.
        </div>
        <router-link :to="{name: 'CreateVacancyPage'}">
          <Button type="create-resume" class="!w-[314px] !h-[54px] !text-[18px] !mt-[40px] !mb-[45px]"
          style="border-color: transparent">Разместить вакансию</Button>
        </router-link>
        
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from './../ui/Button.vue';  // Импортируем компонент Button
import { defineProps, defineEmits, computed, ref, onMounted, onUnmounted } from 'vue';

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});

</script>

<style scoped>
@media (min-width: 1800px) {
  .doubleside {
    height: 325px;
    width: 992px;
  }
}
</style>