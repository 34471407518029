<template>
  <LKLayout >
    <div v-if="fetched" class="wj-container md:w-[685px] md:min-h-[300px] px-[15px] py-[15px] md:px-[1rem] md:py-[1rem]">
      <InvationCardFull :invation=invation :type=firstPart :showEmployeeContacts=showEmployeeContactsFunc()>
      </InvationCardFull>
      <div v-if="firstPart === 'employee'">
        <div class="mt-[50px] flex gap-[25px]" v-if="status === 'NEW' ">
          <Button type="login-logincard" class="md:w-[362px]" @click="setStatus('ACCEPTED')">Принять</Button>
          <Button type="login-logincard" class="md:w-[362px]"
                  @click="setStatus('REJECTED')">Отклонить
          </Button>
          <Button type="login-logincard" class="md:w-[362px]" @click="setStatus('ARCHIVE')">В
            архив
          </Button>
        </div>
        <div class="mt-[50px] flex gap-[25px]" v-else-if="(status === 'REJECTED') || (status === 'ACCEPTED')">
          <Button type="login-logincard" class="md:w-[362px]" @click="setStatus('ARCHIVE')">В
            архив
          </Button>
        </div>

      </div>
      <div v-else>
        <div class="mt-[50px] flex gap-[25px]" v-if="status !== 'ARCHIVE' ">
          <Button type="login-logincard" class="md:w-[362px]" @click="setStatus('ARCHIVE')">В
            архив
          </Button>
        </div>
      </div>

    </div>
  </LKLayout>

</template>

<script setup>
import LKLayout from "@/layouts/LKLayout";
import {onMounted, reactive, ref, computed, watch, onBeforeMount} from "vue";
import {useFetch} from "@/composables/useFetch";
import Button from "@/components/ui/Button";
import VacancyCardListitem from "@/components/cards/VacancyCardListitem"
import ResumeCard from "@/components/cards/ResumeCard.vue";
import {
  formatPrice,
  formatDateTime,
  formatDateTimeVacancyCreated,
  formatexperienceForVacancy
} from '@/composables/utils';
import CompanyCard from './../components/cards/CompanyCard.vue'
import InvationCardFull from './../components/cards/InvationCardFull.vue'
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import {useToast} from "vue-toast-notification";

const fetch = useFetch()
const route = useRoute();
const router = useRouter();
const toast = useToast()

const invationId = route.params.id
const status = route.query.status
const firstPart = route.path.split('/')[1];

const invation = reactive({
  vacancy: null,
  resume: null,
  created_at: null,
  updated_at: null,
  text: null,
  employer: null
})

const fetched = ref(false)

onBeforeMount(() => {
  fetch.anonRequest(`/invations/${invationId}/`).then((data) => {
    invation.vacancy = data.vacancy,
      invation.resume = data.resume,
      invation.created_at = data.created_at,
      invation.updated_at = data.updated_at,
      invation.text = data.text,
      invation.employer = data.employer
  }).then(data => {
    fetched.value = true
  })


})

const setStatus = (status) => {
  fetch.authRequest(`/invations/${invationId}/`, {
    method: 'PATCH',
    body: {
      status: status
    }
  })
    .then(response => {
      // console.log('data', response)
      if (status === 'ARCHIVE'){
        toast.success('Перемещено в архив')
      }
      if (status === 'ACCEPTED'){
        toast.success('Перемещено в принятое')
      }
      if (status === 'REJECTED'){
        toast.success('Перемещено в отклоненное')
      }
      router.push(`/${firstPart}/lk/feedbacks/invations`)
    })
}

const showEmployeeContactsFunc = () => {
  return status === 'ACCEPTED'
}
</script>

<style scoped>
.wj-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.form-input {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;

  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 15px;

  height: 42px;


}

.form-label {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4A4A4A;
  margin-bottom: 20px;
}


.form-sublabel {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  leading-trim: both;
  text-edge: cap;
  letter-spacing: 0.04em;

  color: #888888;

  margin-bottom: 20px;


}

.title {
  /* Создание вакансии */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #4A4A4A;


}

.inner-container {
  /* Rectangle 1001541 */


  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
}

.contacts {

  font-family: 'Inter';
  font-style: semi-bold;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: left;

  color: black;
}
</style>