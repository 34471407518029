<template>
  <Sheet :class="customCardClass">
    <div v-if="!isMobile" class="flex flex-row w-full h-full">
      <div class="relative flex flex-col ml-[85px]">
        <div class="text-[42px] mt-[82px] w-[399px] h-[144px] leading-[50px] font-inter font-bold text-[#6C6C6C] mb-[35px]">
          {{ title }}
        </div>
        <div class="text-[22px] w-[399px] h-[93px] font-inter text-[#909090] mb-[48px]">
          {{ subtitle }}
        </div>
        <router-link to="/signup">
          <Button type="signup" class="w-[399px] h-[60px] ">Регистрация</Button>
        </router-link>
        
        
      </div>
      <img src="./../../assets/images/Rectangle_15.webp" alt="Card Image" class="mt-[20px] h-[495px] w-[495px]" />
    </div>
    <div v-else>
      <div class="text-[1.25rem] font-inter font-bold text-[#6C6C6C] mb-[0.75rem]">
        {{ title }}
      </div>
      <div class="text-[1.12rem] font-inter text-[#909090] mb-[0rem]">
        {{ subtitle }}
      </div>
      <img src="./../../assets/images/Rectangle_15.webp" alt="Card Image" class="w-full mt-[0rem] mb-[0.75rem]" />
      <router-link to="/signup">
        <Button type="signup">Регистрация</Button>
      </router-link>
      
    </div>

  </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import Button from './../../components/ui/Button.vue';
import { defineProps, ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  // imageSrc: {
  //   type: String,
  //   required: true,
  // },
});

const customCardClass = 'p-[2.19rem_1.75rem_2.19rem_1.75rem] md:p-[0px_0px_0px_0px] !bg-[#F8F8F8] md:h-[520px] md:w-[992px]';

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});
</script>

<style scoped>
/* Дополнительные стили, если нужны */
</style>