<template>
  <div class="flex flex-col">
    <div class="m-5 bg-[#FFFFFF]">
      <span>Кнопки</span>
      <Button type="search">Найти</Button>
      <!-- <Button type="login">Войти</Button>
      <Button type="create-resume">Создать резюме</Button>
      <Button type="all-vacansys">Все вакансии</Button>
      <Button type="signup">Регистрация</Button>
      <Button type="contacts-locked">Контакты</Button>
      <Button type="contacts-unlocked">Контакты</Button>
      <Button type="send-welcome-1">Отправить приглашение</Button>
      <Button type="send-welcome-2">Отправить приглашение</Button>
      <Button type="further">Далее</Button>
      <Button type="create-resume-small">Создать резюме</Button>
      <Button type="partnership">Партнерская программа</Button>
      <Button type="oferta">Договор оферты</Button>
      <Input type="type1" placeholder="Профессия, должность или компания"></Input>
      <Input type="type2" placeholder="Имя"></Input>
      <Checkbox v-model:checked="isChecked"></Checkbox>
      <CheckboxStar v-model:checked="isCheckedStar"></CheckboxStar>
      <Grade v-model="count"></Grade> -->
    </div>
  </div>
</template>

<script setup>
import Button from './../components/ui/Button.vue';  // Импортируем компонент Button
import Input from './../components/ui/Input.vue';
import Checkbox from './../components/ui/Checkbox.vue';
import CheckboxStar from './../components/ui/CheckboxStar.vue';
import Grade from './../components/ui/Grade.vue';
import { ref, reactive } from 'vue';
const count = ref(0);

const isChecked = ref(true);
const isCheckedStar = ref(true);
// export default {
//     data() {
//       return {
//         grade: 0
//       };
//     },
// }
</script>

<style scoped>
/* Определяем пользовательский класс внутри секции style */
/* .custom-class {
  @apply flex flex-wrap gap-x-2 border-b border-black w-full py-2 px-4;
} */
</style>
