<template>
  <div class="relative w-full  h-[3.4rem] md:h-[50px]">
    <input :class="computedClasses" :placeholder="props.placeholder" v-model="inputValue" :type="computedType" >
    <div v-if="props.type === 'type4-login-pass'" class="absolute top-[0.6rem] right-[0.6rem]" @click="toggleHidden">
      <Icon v-if="hidden === true" name="eyeNovision"></Icon>
      <Icon v-else name="eye"></Icon>
    </div>

    <!-- <button v-if="props.type === 'type4-login-pass'" @click="toggleMask"
      class="absolute inset-y-0 right-0 bg-[#3D7CD1] text-white rounded-r-[0.5rem]">
      {{ isMasked ? 'Показать' : 'Скрыть' }}
    </button> -->
  </div>

</template>

<script setup>
import { computed } from 'vue';
import { defineProps, onMounted, ref, watch, defineEmits } from 'vue';
import Icon from "@/components/ui/Icon";


const toggleHidden = () => {
  hidden.value = !hidden.value
}

// Определяем пропсы компонента
const props = defineProps({
  type: {
    type: String,
    default: 'type1'
  },
  placeholder: {
    type: String,
    default: 'Введите что нибудь'
  },
  class: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  },
  hiddenDefault: {
    default: true,
  }
  // hidden: {
  //   type: Boolean,
  //   default: false
  // }
});

const emit = defineEmits(['update:modelValue', 'search']);
const inputValue = ref(props.modelValue);


const hidden = ref(props.hiddenDefault);

watch(inputValue, (newValue) => {
  emit('update:modelValue', newValue);
});

// Генерируем классы на основе пропсов и стандартного набора классов
const computedClasses = computed(() => {
  if (props.type === 'type1') {
    // return "absolute bg-[#FFFFFF] py-[1.06rem] px-[1rem] rounded-[0.94rem] md:px-[25px] md:py-[17px] md:rounded-[15px] text-left text-main placeholder-placeholder-color font-inter font-normal md:text-[16px] text-[1rem] border w-full outline outline-[1px] outline-[#888888] " + props.class
    return "absolute bg-[#FFFFFF] py-[1.06rem] px-[1rem] rounded-[0.94rem] md:px-[25px] md:py-[17px] md:rounded-[15px] text-left text-main placeholder-placeholder-color font-inter font-normal md:text-[16px] text-[1rem] border w-full  " + props.class
  }
  else if (props.type === 'type2') {
    return "absolute bg-[#F4F4F4] py-[1.06rem] px-[1.44rem] h-[2.91rem] md:h-[54px] md:px-[25px] md:py-[17px] md:rounded-[15px] md:text-[16px] rounded-[0.94rem] text-left text-main placeholder-placeholder-color font-inter font-normal text-[0.86rem] border w-full outline-none " + props.class
  }
  else if (props.type === 'type3-findsmall') {
    // return "absolute bg-[#FFFFFF] py-[1.06rem] px-[1rem] md:h-[54px] md:px-[25px] md:py-[17px]  md:rounded-[15px] md:text-[16px] rounded-[0.94rem] text-left text-main placeholder-placeholder-color font-inter font-normal text-[1rem] w-full outline outline-[1px] outline-[#888888] outline-offset-[-1px] " + props.class
    return "absolute bg-[#FFFFFF] py-[1.06rem] px-[1rem] md:h-[54px] md:px-[25px] md:py-[17px]  md:rounded-[15px] md:text-[16px] rounded-[0.94rem] text-left text-main placeholder-placeholder-color font-inter font-normal text-[1rem] w-full  " + props.class
  }
  else if (props.type === 'type4-login') {
    return "absolute bg-[#FFFFFF] px-[1rem] md:px-[15px] h-[2.7rem] md:h-[42px] font-extralight rounded-[1rem] md:rounded-[15px] text-[1rem] md:text-[12px] outline outline-[1px] outline-[#B7B7B7] outline-offset-[-1px] w-full " + props.class
  }
  else if (props.type === 'type4-login-pass') {
    return "absolute bg-[#FFFFFF] px-[1rem] md:px-[15px] h-[2.7rem] md:h-[42px] font-extralight rounded-[1rem] md:rounded-[15px] text-[1rem] md:text-[12px] outline outline-[1px] outline-[#B7B7B7] outline-offset-[-1px] w-full " + props.class
  }

  else {
    return ``
  }
});

const computedType = computed(() => {
  if (props.type === 'type4-login-pass') {
    if (hidden.value === true) {
      return 'password'
    } else {
      return 'text'
    }
  } else {
    return 'text'
  }
})

</script>

<style scoped>
/* Можете добавить стили, если это необходимо */
/* .font-normal {
    font-weight: 400;
  } */
.custom-inner-border {
  position: relative;
}

.custom-inner-border::before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 1px solid #888888;
  border-radius: calc(0.94rem - 1px);
  /* Adjusting border radius */
  pointer-events: none;
  /* Disable pointer events to make sure this pseudo-element doesn't interfere with button interactions */
}


@media (min-width: 769px) {
  .placeholder-placeholder-color::placeholder {
    color: #BDBDBD;
    /* Цвет плейсхолдера */
    text-align: left;
  }
}

@media (max-width: 768px) {
  .placeholder-placeholder-color::placeholder {
    color: #BDBDBD;
    /* Цвет плейсхолдера */
    text-align: left;
  }
}




/* Класс для ввода текста по левому краю */
input:not(:placeholder-shown) {
  text-align: left;
}

.text-main {
  color: #5B5B5B;
  /* Основной цвет текста */
}
</style>
