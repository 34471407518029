<template>
  <Sheet v-if="$authStore.me" class="w-[270px] ">
    <div class="title mb-[20px]">Личный кабинет</div>
    <Button type="login-logincard" class="!text-[14px] !h-[38px]" @click="mainButtonClickMethod">{{ buttonLabelComputed }}</Button>
    <div class="flex flex-col mt-[30px] mb-[45px]">
      <slot name="menu-items"></slot>
    </div>
    <div class="fio">{{ fioStringComputed }}</div>
    <div class="email mb-[19px]">{{ $authStore.me.email }}</div>
    <Button type="signup" class="!text-[14px] !h-[38px]" @click="$authStore.logout()">Выход</Button>
  </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Button from './../../components/ui/Button.vue';
import { defineProps, defineEmits, computed } from 'vue';
import { formatPrice, formatDateTime } from '@/composables/utils';
import { useAuthStore } from '@/stores/authStore';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore()


const buttonLabelComputed = computed(() => {
  if (authStore.me.role === 'employee') {
    return 'Создать резюме'
  } else {
    return 'Создать вакансию'
  }
})

const fioStringComputed = computed(() => {
  return `${authStore.me.last_name} ${authStore.me.first_name} ${authStore.me.middle_name}`
})

const mainButtonClickMethod = () => {
  if (authStore.me.role === 'employee') {
    router.push('/employee/lk/resumes/create')
  } else {
    router.push('/employer/lk/vacancies/create')
  }
};
</script>

<style scoped>
.title {
  /* Создание вакансии */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #4A4A4A;
}

.fio {

  font-family: 'Inter';
  font-style: bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: left;

  color: black;
}

.email {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  text-align: left;

  color: black;
}
</style>