<template>
    <Sheet class="!pt-[1.25rem] pl-[1.25rem] pb-[1.68rem] pr-[2.81rem] md:!p-[35px_61px_25px_61px] md:!rounded-[20px]">
        <div class="mb-[1.75rem] md:mb-[20px]">
            <img v-if="imageSrcId" :src=imageSrc alt="Логотип компании" class="w-[9.06rem] h-[2.25rem] md:h-[48px] md:w-[192px]" />
        </div>
        <div class="flex justify-start leading-[1.25rem] md:leading-[20px] mb-[0.93rem] md:mb-[24px]">
            <span class="font-inter font-medium text-[1rem] md:text-[16px] text-black">{{ companyName }} </span>
        </div>
<!--        <div class="flex justify-start items-center leading-[1.06rem] md:leading-[16px] mb-[0.93rem] md:mb-[24px]">-->
<!--            <span class="font-inter mt-[0.2rem] md:mt-[2px] font-bold text-[0.87rem] md:text-[14px]">{{ grade }}</span>-->
<!--            <span class="font-inter mt-[0.2rem] md:mt-[2px] mr-[0.2rem] font-bold text-[0.87rem] md:text-[14px] text-black">/5</span>-->
<!--            <Grade v-model="checkedStarCard" disabled=true></Grade>-->
<!--            <span class="font-inter mt-[0.2rem] md:mt-[2px] ml-[0.25rem] font-light text-[0.75rem] md:text-[12px] text-[#2894FF] custom-underline cursor-pointer">{{reportsCount}} отзывов</span>-->
<!--        </div>-->
        <div class="flex justify-start items-center leading-[1.18rem] md:leading-[18px]">
            <span @click="$router.push(`/employee/vacancies?profession=${companyName}`)" class="font-inter font-normal text-[0.87rem] md:text-[16px] text-[#2894FF] custom-underline cursor-pointer">Все вакансии компании</span>
            
            
        </div>
    </Sheet>
</template>

<script setup>
import Sheet from './Sheet.vue';
import CheckboxStar from './../../components/ui/CheckboxStar.vue';
import Button from './../../components/ui/Button.vue';
import Grade from './../../components/ui/Grade.vue';
import { defineProps, defineEmits, computed } from 'vue';
import { useFetch } from '@/composables/useFetch';
const { baseURL } = useFetch();

const props = defineProps({
    companyName: {
        type: String,
        default: 'Текст',
    },
    companyShortDescription: {
        type: String,
        default: 'Текст',
    },
    imageSrcId: {
        type: Number,
        default: null,
    },
    grade: {
        type: Number,
        default: 0,
    },
    reportsCount: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits(['update:modelValue']);
const checkedStarCard = computed({
    get() {
        return props.grade;
    },
});
const imageSrc = computed(() => {
    return `${baseURL}/files/${props.imageSrcId}/download`
});
</script>

<style scoped></style>