<!-- NewCardComponent.vue -->
<template>
    <div v-if="isMobile" class="flex flex-col bg-[#F8F8F8] pl-[1.5rem] pr-[1.5rem] pt-[2.25rem]">
        <div class="text-left text-[1.5rem] text-[#3D7CD1] leading-[1.78rem] mb-[0.81rem] font-[Inter] font-bold">
            Ваш надежный партнер для комплексных решений по найму персонала
        </div>
        <div class="text-left text-[1rem] text-[#7F7F7F] leading-[1.18rem] mb-[1.5rem] font-[Inter] font-light">
            УСПЕШНЫЙ ПОИСК, БЫСТРЫЙ НАЙМ
        </div>
        <CompanySignupCard title="Начните нанимать таланты уже сегодня"></CompanySignupCard>
        <div
            class="text-center text-[1rem] text-[#7F7F7F] leading-[1.18rem] mb-[1.62rem] mt-[1rem] font-[Inter] font-light">
            Уже зарегистрировали свою компанию?
        </div>
        <router-link to="/login">
            <div class="text-center font-inter font-light text-[1rem] text-[#00C8EF] mb-[2.43rem] underline cursor-pointer">
                Войти в личный кабинет</div>
        </router-link>
        
    </div>
    <div v-else class="flex flex-row justify-center bg-[#F8F8F8] pt-[67px]">
        <div class="flex flex-row bg-[#F8F8F8] w-[993px]">
            <div class="flex flex-col w-[568px] mr-[24px]">
                <div class="text-[16px] text-[#7F7F7F] mb-[15px] font-[Inter] font-light">
                    УСПЕШНЫЙ ПОИСК, БЫСТРЫЙ НАЙМ
                </div>
                <div class="text-left text-[34px] leading-[45px] text-[#3D7CD1] mb-[35px] font-[Inter] font-bold">
                    Ваш надежный партнер для комплексных решений по найму персонала
                </div>
                <img src="./../../assets/images/EmpoloyePageDesktop.webp" alt="EmpoloyePageDesktop"
                    class="w-[569px] h-[284px]" />
            </div>
            <div class="flex flex-col">
                <CompanySignupCard title="Начните нанимать таланты уже сегодня"></CompanySignupCard>
                <div
                    class="text-center text-[1rem] text-[#7F7F7F] leading-[1.18rem] mb-[1.62rem] mt-[1rem] font-[Inter] font-light md:text-[18px]
                    md:leading-[21px] md:mt-[17px] md:mb-[3px]">
                    Уже зарегистрировали свою компанию?
                </div>
                <router-link to="/login">
                    <div
                    class="text-center font-inter font-light text-[1rem] text-[#00C8EF] mb-[2.43rem] underline cursor-pointer md:text-[18px] md:mb-[49px]">
                    Войти в личный кабинет</div>
                </router-link>
                
            </div>

        </div>
    </div>

</template>

<script setup>
import CompanySignupCard from './CompanySignupCard.vue';
import Button from './../../components/ui/Button.vue';  // Импортируем компонент Button
import Input from './../../components/ui/Input.vue';
import { defineProps, onMounted, onUnmounted, ref } from 'vue';

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});

const props = defineProps({
    title: {
        type: String,
        default: ''
    },
});
</script>

<style scoped></style>